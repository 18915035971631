import React, { Component } from 'react';

// Components
import { twoDecimal } from "@utils/format";


import './table.css';

class Page extends Component {
    componentDidMount() {
        if (typeof this.props.handleDidMount == 'function') {
            this.props.handleDidMount();
        }
    }

    render () {
        const { table, date, posto, category, status } = this.props;

        return (
            <table style={{ width: '100%' }}>
                <tr>
                    <th colSpan="5">
                        <table style={{ width: '100%', borderBottom: '1px solid black' }}>
                            <tr>
                                <th>Data</th>
                                <th>Estado</th>
                                <th>Posto</th>
                                <th>Familia</th>
                            </tr>
                            <tr>
                                <td>{date}</td>
                                <td>{status.label}</td>
                                <td>({posto.value}) {posto.label}</td>
                                <td>({category.value}) {category.label}</td>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th colSpan="4">&nbsp;</th>
                    <th style={{ border: "1px solid black", borderBottom: 0 }}>Total</th>
                </tr>
                <tr>
                    <td colSpan="4">&nbsp;</td>
                    <td style={{ border: "1px solid black", borderTop: 0 }}>
                    {twoDecimal(table.rows.reduce((acc, row) => acc + parseFloat(row.total || 0), 0))}
                    </td>
                </tr>
                <tr>
                    {table.columns.filter(({ accessor }) => accessor !== 'inventory').map(({ Header }, index) => (
                        <th style={{
                            textAlign: [0,1].includes(index) ? 'left' : 'center',
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ Header }</th>
                    ))}
                </tr>
                {table.rows.map((row) => (
                    <tr>
                        <td style={{
                            textAlign: 'left',
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.idproduct }</td>
                        <td style={{
                            textAlign: 'left',
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.nome }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.stock }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.costprice }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.total }</td>
                    </tr>
                ))}
            </table>
        );
    }
}

export default Page;