import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { MdAddCircleOutline, MdVisibility } from 'react-icons/md';
import Menu from '@components/Menu';
import ReactTable from '@components/ReactTable';
import UserContext from '@components/context/userContext';
import { TimestampToDate } from '@utils/date';
import { threeDecimal } from '@utils/format';

const InvoiceList = () => {
  const [canCreate, setCanCreate] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();
  const profile = useContext(UserContext);

  const doctypes = [
    { value: 'facturas', label: 'Faturas' },
    { value: 'vendas_dinheiro', label: 'Faturas Recibos' },
    { value: 'receipts', label: 'Recibos' }
  ];

  const columns = [
    {
      Header: 'Data',
      accessor: 'date',
    },
    {
      Header: 'Cliente',
      accessor: 'customer_name',
    },
    {
      Header: 'Nº Documento',
      accessor: 'doc',
    },
    {
      Header: 'Tipo Doc',
      accessor: 'doctype',
    },
    {
      Header: 'Total',
      accessor: 'total',
    },
    {
      Header: 'Anulada',
      accessor: 'deleted',
    },
    {
      Header: '',
      accessor: 'view',
    },
  ];

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const responseSeriesFT = await axios.get(
          `https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresa}&doctype=FT_epos`
        );

        if (responseSeriesFT.data === 'NOK') {
          setCanCreate(false);
        } else {
          const responseSeriesFR = await axios.get(
            `https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresa}&doctype=FR_epos`
          );
  
          if (responseSeriesFR.data === 'NOK') {
            setCanCreate(false);
          } else {
            setCanCreate(responseSeriesFR.data.length && responseSeriesFT.data.length);
          }
        }


        const response = await axios.get(
          `https://eposgestofinal.pt/api/facturas/get.php?idempresa=${profile.idEmpresa}&start_date=1735689600`
        );

        if (response.data === 'NOK') {
          setInvoices([]);
          return;
        }

        const enrichedInvoices = response.data.map(invoice => ({
          ...invoice,
          date: TimestampToDate(invoice.date_time),
          doctype: (doctypes.find(dt => dt.value === invoice.type) || {}).label,
          total: threeDecimal(invoice.total),
          deleted: invoice.valid ? '' : 'TRUE',
          view: (
            <MdVisibility
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => history.push(`/sales/${invoice.type}/${invoice.serie}/${invoice.doc}`)}
            />
          ),
        }));

        setInvoices(enrichedInvoices);
      } catch (err) {
        console.error('Error fetching invoices:', err);
        setError('Erro ao carregar faturas');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [profile.idEmpresa, history]);

  return (
    <div>
      <Menu />
      <div className="fullContainer" style={{ maxWidth: '100%' }}>
        <div className="col-xs-12">
          <ol
            style={{
              textAlign: 'left',
              marginTop: '75px',
            }}
            className="breadcrumb"
          >
            <li>
              <a href="http://eposgestofinal.pt/index.php">Portal</a>
            </li>
            <li className="active">Faturação</li>
          </ol>
        </div>
      </div>
      <div className="fullContainer" style={{ maxWidth: '100%' }}>
        <div className="col-sm-12" style={{ textAlign: 'center' }}>
          <div className="panel panel-default">
            <div
              className="panel-heading"
              style={{
                fontWeight: 'bold',
                fontSize: '15pt',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '15px'
              }}
            >
              <div style={{ width: '100%', textAlign: 'left' }}>Faturação</div>
              <div>
                <table
                  style={{ cursor: 'pointer' }}
                  onClick={() => canCreate ? history.push('/sales/new') : alert('Não foram detectadas séries no ePOS.')}
                >
                  <tbody>
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: '20px' }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 'xx-small' }}>Criar documento</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {error ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              <ReactTable
                columns={columns}
                data={invoices}
                loading={loading}
                sortable={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceList;