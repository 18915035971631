import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";
import ImageUploading from "react-images-uploading";

// Components
import Menu from "@components/Menu";

/* Import Components */
import Input from "@components/Input";
import Button from "@components/Button";
import AutoComplete from "@components/AutoComplete";
import HorizontalTabs from "@components/HorizontalTabs";
import ReactTable from "@components/ReactTable";

import UserContext from "@components/context/userContext";

const employeeTypes = [
  { value: "frontoffice", label: "Lojista" },
  { value: "backoffice", label: "Escritório" },
];

const maxNumber = 1;

const dataURLtoFile = (dataurl, fileName) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

class Form extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    this.state = {
      images: [],
      tabIndex: "details",
      auditTickets: {
        columns: [
          {
            Header: "Senha",
            accessor: "idticket",
          },
          {
            Header: "Qr",
            accessor: "qr",
          },
          {
            Header: "Data",
            accessor: "date",
          },
          {
            Header: "Estado",
            accessor: "visualStatus",
          },
        ],
        rows: [],
        loading: true,
      },
      loaded: false,
      name: "",
      us: "",
      type: { value: "frontoffice", label: "Lojista" },
      pa: "",
      idempresa: this.props.cookies.get("member_idempresa"),
      idemployee: type === "edit" && params.idemployee,
      edit: type === "edit",
      search: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.filterData = this.filterData.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      if (this.props.type === "edit") {
        axios
          .get(
            `https://eposgestofinal.pt/api/employees/getById.php?idempresa=${profile.idEmpresa}&idemployee=${this.state.idemployee}`
          )
          .then((response) => {
            const employee = response.data !== "NOK" ? response.data[0] : {};

            this.setState({
              ...employee,
              type: employeeTypes.find(
                (employeeType) => employeeType.value === employee.type
              ),
              avatar: employee.avatar,
              us: employee.username,
              loaded: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `https://eposgestofinal.pt/api/employees/tickets/getById.php?idempresa=${profile.idEmpresa}&idemployee=${this.state.idemployee}`
          )
          .then((response) => {
            const audits = response.data !== "NOK" ? response.data : [];

            this.setState({
              auditTickets: {
                ...this.state.auditTickets,
                rows: audits.map((audit) => ({
                  ...audit,
                  visualStatus:
                    audit.status === "SUCCESS" ? (
                      <span className="badge badge-success">Resgatada</span>
                    ) : audit.status === "REPEAT" ? (
                      <span className="badge badge-warning">Repetida</span>
                    ) : (
                      <span className="badge badge-danger">Inválida</span>
                    ),
                })),
                loading: false,
              },
              loaded: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({ loaded: true });
      }
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    e.preventDefault();

    axios
      .post("https://eposgestofinal.pt/api/employees/set.php", {
        name: this.state.name,
        username: this.state.us,
        type: this.state.type.value,
        password: this.state.pa,
        idempresa: profile.idEmpresa,
        idemployee: this.state.edit ? this.state.idemployee : undefined,
      })
      .then((response) => {
        history.push("/employees");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push("/employees");
  }

  filterData(searchTerm) {
    this.setState({ search: searchTerm });
  }

  onImageChange(imageList, addUpdateIndex) {
    const profile = this.context;
    const formData = new FormData();
    formData.append(
      "photo",
      dataURLtoFile(imageList[0]["data_url"], imageList[0].file.name)
    );
    formData.append("idempresa", profile.idEmpresa);
    formData.append("idemployee", this.state.idemployee);

    axios
      .post("https://eposgestofinal.pt/api/employees/setPhotos.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        this.setState({ loaded: false });
        this.loadFunction();
      });
  }

  render() {
    const profile = this.context;
    const { location, history } = this.props;
    const {
      auditTickets,
      edit,
      loaded,
      name,
      us,
      type,
      pa,
      tabIndex,
      search,
      avatar,
      idemployee,
      images,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

    const auditTicketsRows = auditTickets.rows.length
      ? auditTickets.rows.filter((tickets) =>
          filterSearch.every((eachSearch) => {
            return (
              eachSearch === "" ||
              (tickets.idticket &&
                tickets.idticket.includes(eachSearch.toLowerCase())) ||
              (tickets.qr &&
                tickets.qr.toLowerCase().includes(eachSearch.toLowerCase())) ||
              (tickets.date && tickets.date.includes(eachSearch.toLowerCase()))
            );
          })
        )
      : [];

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/employees">Utilizadores</a>
              </li>
              <li className="active">
                {edit ? <span>{name}</span> : <span>Novo Utilizador</span>}
              </li>
            </ol>
          </div>
        </div>
        {loaded && (
          <HorizontalTabs
            tabIndex={2}
            tabs={{
              details: {
                label: "Detalhes",
                page: (
                  <div className="fullContainer" style={{ maxWidth: "100%" }}>
                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15pt",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: '15px'
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            Detalhes
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            width: "100%",
                          }}
                          className="container-fluid"
                          autocomplete="off"
                        >
                          <table className="table table-bordered table-hover table-sortable">
                            <thead>
                              <tr>
                                {idemployee && (
                                  <th className="text-center">Avatar</th>
                                )}
                                <th className="text-center">Nome</th>
                                <th className="text-center">Tipo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {idemployee && (
                                  <td>
                                    {avatar ? (
                                      <img
                                        width="100px"
                                        src={`https://eposgestofinal.pt/${avatar}`}
                                      />
                                    ) : (
                                      <img
                                        width="50px"
                                        src="https://eposgestofinal.pt/site/images/profile.jpg"
                                      />
                                    )}
                                    <ImageUploading
                                      multiple
                                      value={images}
                                      onChange={this.onImageChange}
                                      maxNumber={maxNumber}
                                      dataURLKey="data_url"
                                    >
                                      {({
                                        onImageUpload,
                                        isDragging,
                                        dragProps,
                                      }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                          <button
                                            type="button"
                                            id="browse"
                                            class="btn btn-primary"
                                            style={
                                              isDragging
                                                ? { color: "red" }
                                                : undefined
                                            }
                                            onClick={onImageUpload}
                                            {...dragProps}
                                          >
                                            {" "}
                                            <i class="glyphicon glyphicon-upload"></i>
                                            &nbsp;Alterar logotipo
                                          </button>
                                        </div>
                                      )}
                                    </ImageUploading>
                                  </td>
                                )}
                                <td>
                                  <Input
                                    inputType={"text"}
                                    name={"name"}
                                    value={name}
                                    handleChange={this.handleChange}
                                  />
                                </td>
                                <td>
                                  <AutoComplete
                                    name={"type"}
                                    value={type}
                                    handleChange={this.handleChange}
                                    options={employeeTypes}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="table table-bordered table-hover table-sortable">
                            <thead>
                              <tr>
                                <th className="text-center" colSpan="2">
                                  Username
                                </th>
                                <th className="text-center">Password</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ borderRight: 0 }}>
                                  <Input
                                    inputType={"text"}
                                    autocomplete="off"
                                    name={"us"}
                                    value={us}
                                    handleChange={this.handleChange}
                                  />
                                </td>
                                <td
                                  style={{
                                    borderLeft: 0,
                                    verticalAlign: "middle",
                                  }}
                                >
                                  @{profile["member_name"].toLowerCase()}
                                </td>
                                <td>
                                  <Input
                                    inputType={"text"}
                                    autocomplete="off"
                                    name={"pa"}
                                    value={pa}
                                    handleChange={this.handleChange}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* About you */}
                          <Button
                            action={this.handleFormSubmit}
                            type={"primary"}
                            title={"Submeter"}
                            style={buttonStyle}
                          />
                          {/*Submit */}
                          <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                          />
                          {/* Clear the form */}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              ticketsAudit: {
                label: "Auditorias",
                page: (
                  <div className="fullContainer" style={{ maxWidth: "100%" }}>
                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15pt",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: '15px'
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            Auditoria (Senhas)
                          </div>
                        </div>
                        <table className="table table-bordered table-hover table-sortable">
                          <thead>
                            <tr>
                              <th className="text-center">Pesquisa</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "250px" }}>
                                <Input
                                  inputType={"text"}
                                  name={"search"}
                                  value={search}
                                  placeholder="pesquisa..."
                                  handleChange={(e) =>
                                    this.filterData(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ReactTable
                          columns={auditTickets.columns}
                          sortable={true}
                          loading={auditTickets.loading}
                          data={auditTicketsRows}
                        />
                      </div>
                    </div>
                  </div>
                ),
              },
            }}
          />
        )}
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default withCookies(Form);
