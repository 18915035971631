import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

import Card from "./card";

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Button from "@components/Button";
import Checkbox from "@components/CheckBox";
import AutoComplete from "@components/AutoComplete";
import { MdClear, MdAddCircleOutline, MdCreditCard, MdOutlineAutorenew, MdDownload, MdEmail } from "react-icons/md";
import Switch from "@components/Switch";
import Modal from '@components/Modal';

import DateRangePicker from "@components/DateRangePicker";

import PrintDocuments from './print-documents'

import { FaEdit, FaTrash } from "react-icons/fa";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { toPng } from 'html-to-image';
import download from 'downloadjs';

import ReactTable from "@components/ReactTable";

import { threeDecimal, valueWithIva, twoDecimal, numberWithCommas } from '@utils/format.js'

import UserContext from '@components/context/userContext';

import "./form.css";

import { EventBus as eventBus } from "@components/EventBus";
import { switchClasses } from "@mui/material";

const extraZero = (value) => (value < 10 ? `0${value}` : value);

const TimestampToDatetime = (timestamp) => {
    if (!timestamp) {
        return '';
    }
    const datum = new Date(timestamp * 1000);
    const year = datum.getFullYear();
    const month = extraZero(datum.getMonth() + 1);
    const day = extraZero(datum.getDate());
    return `${day}/${month}/${year}`;
  };

const abrvTipoDocumentos = (doctype) => {
    switch (doctype) {
        case 'taloes':
            return 'FA';
        case 'facturas':
            return 'FT';
        case 'notas_credito':
            return 'NC';
        case 'vendas_dinheiro':
            return 'FR';
        case 'recibos':
            return 'RC';
        default:
            return '';
    }
}

const startOftoday = new Date();
startOftoday.setHours(0, 0, 0, 0);

const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 999);

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    this.cardRef = React.createRef();
    this.matriculaRef = React.createRef();

    const { match: { params }, type } = this.props;

    const searchHousehold = "";

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    this.state = {
        loaded: false,
        nome_1: '',
        nome_2: '',
        email: '',
        nif: '',
        telefone: '',
        telefone_2: '',
        fax: '',
        telemovel: '',
        morada_1: '',
        morada_2: '',
        card: '',
        data_inicio_ft: '',
        data_de_nascimento: '',
        codigo_postal: '',
        credito_concedido: 0.00,
        plafond: 0.00,
        codigo: type === 'edit' && params.codigo,
        edit: type === 'edit',
        dataChanged: -1,
        tableDescontos: {
            columns: [
                {
                    Header: "Grupo",
                    accessor: "grupo",
                },
              {
                Header: "Familia",
                accessor: "familia",
              },
              {
                Header: "Produto",
                accessor: "produto",
              },
              {
                Header: "Dia Semana",
                accessor: "dia_semana",
              },
              {
                Header: "Hora Inicial",
                accessor: "hora_inicial",
              },
              {
                Header: "Hora Final",
                accessor: "hora_final",
              },
              {
                Header: "Desconto %",
                accessor: "desconto_%",
              },
              {
                Header: "Desconto Valor",
                accessor: "desconto_valor",
              },
              {
                Header: "Preço Unitário",
                accessor: "preco_unitario",
              },
              {
                Header: "Produto Parceiro",
                accessor: "produto_parceiro",
              },
              {
                Header: "",
                accessor: "delete",
              },
            ],
            rows: [],
            loading: true,
        },
        emailmatricula: {},
        tableMatriculas: {
            columns: [
                {
                    Header: "Codigo",
                    accessor: "codigo",
                },
                {
                    Header: "Cartão",
                    accessor: "card",
                },
              {
                Header: "Matricula",
                accessor: "matricula",
              },
              {
                Header: "Codigo Tipo Viatura",
                accessor: "codigo_tipo_viatura",
              },
              {
                Header: "Codigo Marca",
                accessor: "codigo_marca",
              },
              {
                Header: "Modelo",
                accessor: "modelo",
              },
              {
                Header: "Codigo Tipo Combustivel",
                accessor: "codigo_tipo_combustivel",
              },
              {
                Header: "Cilindrada",
                accessor: "Cilindrada",
              },
              {
                Header: "Ano Matricula",
                accessor: "ano_matricula",
              },
              {
                Header: "Chassis",
                accessor: "chassis",
              },
              {
                Header: "PS",
                accessor: "ps",
              },
              {
                Header: "Bloqueada",
                id: "bloqueada",
                accessor: d => d.bloqueada === 'True' ? (
                    <span className="badge badge-danger">Sim</span>
                  ) : (
                    <span className="badge badge-success">Nao</span>
                  ),
              },
              {
                Header: "Pergunta Kilometros",
                id: "pergunta_kilometros",
                accessor: d => d.pergunta_kilometros === 'True' ? (
                    <span className="badge badge-success">Sim</span>
                  ) : (
                    <span className="badge badge-danger">Nao</span>
                  ),
              },
              {
                Header: "Kms",
                accessor: "kms",
              },
              {
                Header: "Pergunta Requisição",
                id: "pergunta_requisicao",
                accessor: d => d.pergunta_requisicao === 'True' ? (
                    <span className="badge badge-success">Sim</span>
                  ) : (
                    <span className="badge badge-danger">Nao</span>
                  ),
              },
              {
                Header: "Acumula Pontos",
                id: "acumula_pontos",
                accessor: d => d.acumula_pontos === 'True' ? (
                    <span className="badge badge-success">Sim</span>
                  ) : (
                    <span className="badge badge-danger">Nao</span>
                  ),
              },
              {
                Header: "Obs Consola Mat",
                accessor: "obs_consola_mat",
              },
              {
                Header: "Sub Conta",
                accessor: "sub_conta",
              },
              {
                Header: "Colectivo",
                accessor: "colectivo",
              },
              {
                Header: "Grupo Mat",
                accessor: "grupo_mat",
              },
              {
                Header: "",
                accessor: "delete",
              },
            ],
            rows: [],
            loading: true,
        },
        tableDocumentos: {
            columns: [
                {
                    Header: "Tipo",
                    accessor: "source_abrv",
                },
              {
                Header: "Posto",
                accessor: "posto",
              },
              {
                Header: "Série",
                accessor: "serie",
              },
              {
                Header: "TPV",
                accessor: "tpv",
              },
              {
                Header: "M. Pagamento",
                id: "pagamento",
                accessor: d => {
                    switch (d.pagamento) {
                        case "1":
                            return "Dinheiro";
                        case "2":
                            return "Crédito";
                        case "3":
                            return "Cheque";
                        case "4":
                            return "TPA";
                        case "8":
                            return "Frota";
                        case "14":
                            return "Serviços Internos";
                        case "101":
                            return "Dinheiro";
                        case "102":
                            return "TPA";
                        case "103":
                            return "Cheque";
                        case "104":
                            return "Transferência";
                        case "105":
                            return "Letra";
                        default:
                            return '';
                    }
                },
              },
              {
                Header: "Nº",
                accessor: "numero",
              },
              {
                Header: "Débito",
                accessor: "debit",
              },
              {
                Header: "Crédtio",
                accessor: "credit",
              },
              {
                Header: "Data",
                id: "date_time",
                accessor: d => TimestampToDatetime(d.date_time),
              },
            ],
            rows: [],
            loading: true,
        },
        tipoDocumentos: [
            {
                value: "",
                label: "TODOS",
            },
            {
                value: "FA",
                label: "Talões",
            },
            {
                value: "FT",
                label: "Faturas",
            },
            {
                value: "FR",
                label: "Vendas a Dinheiro",
            },
            {
                value: "NC",
                label: "Notas de crédito",
            },
            {
                value: "RC",
                label: "Recibos",
            },
        ],
        tipoDocumento: {
            value: "",
            label: "TODOS",
        },

        nifDocumentos: [],
        nifDocumento: {
            value: "",
            label: "Nenhum",
        },

        postos: [],
        posto: {},

        search: '',

        date: [startOftoday.getTime() , endOfToday.getTime()],

        newgrupo: '',
        newfamilia: { value: '', label: 'Nenhuma' },
        newproduto: { value: '', label: 'Nenhum' },
        newdiasemana: '',
        newhorainicial: '',
        newhorafinal: '',
        newdescontoper: '',
        newdescontovalor: '',
        newprecounitario: '',
        newprodutoparceiro: '',


        newEdit: false,
        newEditCodigo: '',
        newcodigo: '',
        newmatricula: '',
        newcodigo_tipo_viatura: '',
        newcodigo_marca: '',
        newmodelo: '',
        newcodigo_tipo_combustivel: '',
        newcilindrada: '',
        newano_matricula: '',
        newchassis: '',
        newps: '',
        newbloqueada: false,
        newpergunta_kilometros: false,
        newkms: '',
        newpergunta_requisicao: false,
        newacumula_pontos: false,
        newobs_consola_mat: '',
        newsub_conta: '',
        newcolectivo: '',
        newgrupo_mat: '',


        torniquete: {
            columns: [
                {
                    Header: "Data",
                    id: "date",
                    accessor: d => d.timestamp.split(' ')[0],
                },
                {
                    Header: "Hora",
                    id: "time",
                    accessor: d => d.timestamp.split(' ')[1].split('.')[0],
                },
                {
                    Header: 'Entrada/Saída',
                    id: 'way',
                    accessor: d => d.way === 'in' ? (
                        <b>Entrada</b>
                      ) : (
                        <span>Saída</span>
                      ),
                },
                {
                  Header: 'Método',
                  id: 'method',
                  accessor: d => d.method === 'card' ? (
                    <i class="far fa-id-card"></i>
                  ) : d.method === 'phone' ? (
                    <i class="fas fa-mobile-alt"></i>
                  ) : (
                    <i class="fas fa-users-cog"></i>
                  ),
                },
                {
                    Header: "Plano",
                    accessor: "product_name",
                },
                {
                    Header: "Estado",
                    id: "status",
                    accessor: d => d.status === 'SUCCESS' ? (
                        <span className="badge badge-success">ok</span>
                      ) : (
                        <span className="badge badge-warning">aviso</span>
                      ),
                },
            ],
            rows: [],
            pageSize: 22,
            loading: true,
        },


        categories: [],
        products: [],
        // services: [],
        // kinships: [],
        // healthSystems: [],
        // showPopup: {},
        // tableHousehold: {
        //     columns: [
        //       {
        //         Header: "Nome",
        //         accessor: "name",
        //       },
        //       {
        //         Header: "Parentesco",
        //         accessor: "kinship",
        //       },
        //       {
        //         Header: "Telefone",
        //         accessor: "phone",
        //       },
        //       {
        //         Header: "Email",
        //         accessor: "email",
        //       },
        //       {
        //         Header: "Aniversário",
        //         accessor: "birthday",
        //       },
        //       {
        //         Header: "GDPR",
        //         accessor: "gdpr",
        //       },
        //       {
        //         Header: "",
        //         accessor: "view",
        //       },
        //     ],
        //     rows: [],
        //     loading: true,
        // },
        // tableQuotas: {
        //     columns: [
        //         {
        //             Header: "Data Pagamento",
        //             accessor: "date",
        //         },
        //       {
        //         Header: "Ano",
        //         accessor: "year",
        //       },
        //       {
        //         Header: "Mês",
        //         accessor: "month",
        //       },
        //       {
        //         Header: "Valor Quota",
        //         accessor: "value",
        //       },
        //       {
        //         Header: "recbio nr.",
        //         accessor: "invoice",
        //       },
        //       {
        //         Header: "",
        //         accessor: "view",
        //       },
        //     ],
        //     rows: [],
        //     loading: true,
        // },
        fotografia: ''
    };

    //this.handleEmailSend = this.handleEmailSend.bind(this);
    //this.handleTableSubmit = this.handleTableSubmit.bind(this);
    this.handleComissionUpdate = this.handleComissionUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.showPopupHandler = this.showPopupHandler.bind(this);
    this.hidePopupHandler = this.hidePopupHandler.bind(this);
    this.showCameraHandler = this.showCameraHandler.bind(this);
    this.showUploadHandler = this.showUploadHandler.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.removeFunction = this.removeFunction.bind(this);
    this.handleDescontosSubmitSuccess = this.handleDescontosSubmitSuccess.bind(this);
    this.handleDescontoDeleteSuccess = this.handleDescontoDeleteSuccess.bind(this);
    this.handleMatriculasSubmitSuccess = this.handleMatriculasSubmitSuccess.bind(this);
    this.handleMatriculaDeleteSuccess = this.handleMatriculaDeleteSuccess.bind(this);
    this.editMatricula = this.editMatricula.bind(this);
    this.handleGerarCartaoSuccess = this.handleGerarCartaoSuccess.bind(this);
    this.handleDownloadCartao = this.handleDownloadCartao.bind(this);
    this.handleEmailCartao = this.handleEmailCartao.bind(this);
    this.handleDownloadMatricula = this.handleDownloadMatricula.bind(this);
    this.handleEmailMatricula = this.handleEmailMatricula.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleRemovePhoto = this.handleRemovePhoto.bind(this);
    this.todayDataInicioFt = this.todayDataInicioFt.bind(this);
    this.localDateChange = this.localDateChange.bind(this);
    this.getDocuemnts = this.getDocuemnts.bind(this);
  }

getDocuemnts(first) {
    const profile = this.context;
    const startDate = first ? 0 : Math.round(this.state.date[0]/1000);
    const endDate = first ? (
        this.state.codigo == 'CF' && this.state.nifDocumento.value == '' ? 0 : 9999999999
    ) : Math.round(this.state.date[1]/1000)    

    if (first || this.state.codigo != 'CF' || (this.state.codigo == 'CF' && this.state.nifDocumento.value != '')) {
        let ifnif = '';
        if (this.state.codigo == 'CF' && this.state.nifDocumento.value != '') {
            ifnif = `&nif=${this.state.nifDocumento.value}`;
        }
        axios.get(`https://eposgestofinal.pt/api/statistics/customer/get.php?idempresa=${profile.idEmpresa}&customer=${this.state.codigo}&startdate=${startDate}&enddate=${endDate}${ifnif}`)
            .then(response => {
                const documentos = response.data !== "NOK" ? response.data : [];     

                this.setState({
                    tableDocumentos: {
                        ...this.state.tableDocumentos,
                        rows: documentos.lines.map((document) => ({
                            ...document,
                            total: twoDecimal(document.total) || "0.00",
                            source_abrv: abrvTipoDocumentos(document.source),
                        })),
                        loading: false,
                    },
                    documentstotal: documentos.totals.credit - documentos.totals.debit,
                    documentsdebit: documentos.totals.debit,
                    documentscredit: documentos.totals.credit,
                    loaded: true,
                });
            })
            .catch(error => {
                console.error(error);
                alert("Erro");
            });
    } else {
        this.setState({
            tableDocumentos: {
                ...this.state.tableDocumentos,
                loading: false,
            },
            loaded: true,
        });
        if (this.state.codigo == 'CF') {
            alert("Necessário filtrar por NIF");
        }
    }

}

loadFunction() {
    const profile = this.context;
    const { history } = this.props;

    if (!this.state.loaded) {
        if (this.props.type === 'edit') {
            axios.get(`https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const products = response.data !== 'NOK' ? response.data : [];
        
                    this.setState({ products: [{ value: '', label: 'Nenhum' }, ...products.map((product) => ({ ...product, value: product.idproduct, label: product.nome }))], loaded: true });
                })
                .catch(error => {
                    console.error(error);
                });

            axios
                .get(
                `https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresa}`
                )
                .then((response) => {
                const categories = response.data !== "NOK" ? response.data : [];

                this.setState({
                    categories: [{ value: '', label: 'Nenhuma' },
                    ...categories.map((category) => ({
                        value: category.idcategoria,
                        label: `[${category.idcategoria}] ${category.descricaocategoria}`,
                    })),
                    ],
                    loaded: true,
                });
                })
                .catch((error) => {
                console.error(error);
                });

            axios.get(`https://eposgestofinal.pt/api/clientes/getById.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            .then(response => {
                const customer = response.data !== 'NOK' ? response.data[0] : {};

                this.setState({
                    ...customer,
                    ativo: !(customer.inactivo === 't'),
                    // healthSystem: this.state.healthSystems.find((healthSystem) => healthSystem.value === customers.health_system) || {
                    //     label: 'Nenhum',
                    //     value: undefined,
                    // },
                    // quota: this.state.quotaTypes.find((quotatype) => quotatype.value === parseInt((customers.quota || 0), 10)),
                    // gdpr: customers.gdpr === 't',
                    loaded: true });
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/clientes/descontos/getById.php?idempresa=${profile.idEmpresa}&cliente=${this.state.codigo}`)
            .then(response => {
                const descontos = response.data !== "NOK" ? response.data : [];

                const enrichedDescontos = descontos.map((desconto) => ({
                    ...desconto,
                    delete: (
                      <MdClear
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() =>
                            this.handleDescontoDeleteSuccess(desconto.grupo, desconto.familia, desconto.produto)
                        }
                      />
                    ),
                  }));

                this.setState({
                    tableDescontos: {
                        ...this.state.tableDescontos,
                        rows: enrichedDescontos,
                        loading: false,
                    },
                    loaded: true,
                });
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/clientes/matriculas/getById.php?idempresa=${profile.idEmpresa}&cliente=${this.state.codigo}`)
            .then(response => {
                const matriculas = response.data !== "NOK" ? response.data : [];

                const enrichedMatriculas = matriculas.map((matricula) => ({
                    ...matricula,
                    card: (
                        <MdCreditCard
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => this.setState({
                                emailmatricula: matricula,
                            }, () => eventBus.$emit("Modal-emailmatricula", { header: "Cartão de Matrícula", body: null }))}
                        />
                    ),
                    delete: (
                      <table className="actionButtons" style={{ width: '100%', fontSize: 'xx-small' }}>
                        <tr style={{ lineHeight: '10px' }}>
                        <td
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleMatriculaDeleteSuccess(matricula.codigo)}
                        >
                            <FaTrash/>
                            <p>apagar</p>
                        </td>
                        <td
                            style={{ fontWeight: 'bolder', fontSize: 'x-small', cursor: "pointer" }}
                            onClick={() => this.editMatricula(matricula.codigo)}
                        >
                            <FaEdit/>
                            <p>editar</p>
                        </td>
                        </tr>
                    </table>
                    ),
                  }));

                this.setState({
                    tableMatriculas: {
                        ...this.state.tableMatriculas,
                        rows: enrichedMatriculas,
                        loading: false,
                    },
                    loaded: true,
                });
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`)
            .then(response => {
                const postos = response.data !== 'NOK' ? response.data : [];

                const enrichedPostos = postos.map((posto) => ({ value: posto.posto, label: posto.nome === 'Backoffice' ? 'TODOS' : posto.nome }));

                this.setState({ postos: enrichedPostos, posto: enrichedPostos[0], loaded: true });
                
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/clientes/nif/get.php?idempresa=${profile.idEmpresa}`)
            .then(response => {
                const nifs = response.data !== 'NOK' ? response.data : [];

                const enrichedNifs = nifs.map((nif) => ({ value: nif, label: nif }));

                this.setState({ nifDocumentos: [
                    {
                        value: "",
                        label: "Nenhum",
                    },
                    ...enrichedNifs
                ], loaded: true });
                
            })
            .catch(error => {
                console.error(error);
            });

            this.getDocuemnts(true);

            axios.get(`https://eposgestofinal.pt/api/clientes/members/access/getById.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            .then(response => {
                const torniquete = response.data !== "NOK" ? response.data : [];

                this.setState({
                    torniquete: {
                        ...this.state.torniquete,
                        rows: torniquete,
                        loading: false,
                    },
                    loaded: true,
                });
            })
            .catch(error => {
                console.error(error);
            });
            
            // axios.get(`https://eposgestofinal.pt/api/clientes/services/get.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            // .then(response => {
            //     const services = response.data !== 'NOK' ? response.data : [];

            //     this.setState({ services, loaded: true });
            // })
            // .catch(error => {
            //     console.error(error);
            // });
            // this.setState({ loaded: true, dataChanged: 0 });

            // axios.get(`https://eposgestofinal.pt/api/clientes/household/get.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            // .then(response => {
            //     const households = response.data !== "NOK" ? response.data : [];

            //     console.log(this.state.kinships, households);

            //     const enrichedHouseholds = households.map((household) => ({
            //         ...household,
            //         kinship: this.state.kinships.find((kinship) => kinship.value === household.kinship) ? this.state.kinships.find((kinship) => kinship.value === household.kinship).label : 'Não definido',
            //         gdpr: household.gdpr === "t" ? "TRUE" : "FALSE",
            //         view: (
            //         <MdVisibility
            //             style={{ fontSize: "15px", cursor: "pointer" }}
            //             onClick={() =>
            //             history.push(`/clientes/${this.state.codigo}/household/${household.idhousehold}`)
            //             }
            //         />
            //         ),
            //     }));

            //     this.setState({
            //         tableHousehold: {
            //             ...this.state.tableHousehold,
            //             rows: enrichedHouseholds,
            //             loading: false,
            //         },
            //         loaded: true,
            //     });
            // })
            // .catch(error => {
            //     console.error(error);
            // });

            // axios.get(`https://eposgestofinal.pt/api/clientes/quotas/get.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            // .then(response => {
            //     const quotas = response.data !== "NOK" ? response.data : [];

            //     const enrichedQuotas = quotas.map((quota) => ({
            //         ...quota,
            //         date: TimestampToDatetime(quota.date),
            //         view: (
            //         <MdVisibility
            //             style={{ fontSize: "15px", cursor: "pointer" }}
            //             onClick={() =>
            //             history.push(`/clientes/${this.state.codigo}/quotas/${quota.idquota}`)
            //             }
            //         />
            //         ),
            //     }));

            //     this.setState({
            //         tableQuotas: {
            //             ...this.state.tableQuotas,
            //             rows: enrichedQuotas,
            //             loading: false,
            //         },
            //         loaded: true,
            //     });
            // })
            // .catch(error => {
            //     console.error(error);
            // });
        } else {
            this.setState({ loaded: true });
        }
    }
  }

  handleComissionUpdate = (idproduct, comissionInfo) => {
    const { data } = this.state;
    const newData = data.map(row => {
      if (row.idproduct === idproduct) {
        const comission = comissionInfo.comission || row.comission;
        return {
          ...row,
          ...comissionInfo,
          finalprice: threeDecimal(parseFloat(row.costprice || 0) + parseFloat(comission || 0)),
          finalprice_iva: threeDecimal(valueWithIva(parseFloat(row.costprice || 0) + parseFloat(comission || 0), row.iva)),
        };
      }
      return row;
    });
    this.setState({ data: newData, dataChanged: 1 });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    }, () => {
        if (name === 'quota') {
            this.handleFormSubmit(null, true);
        } else if (name === 'nifDocumento') {            
            this.setState({
              tableDocumentos: {
                ...this.state.tableDocumentos,
                loading: true,
              },
              date: [startOftoday.getTime(), endOfToday.getTime()],
            }, () => this.getDocuemnts(true));
        }
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
        [name]: value,
    });
  };

  editMatricula(codigo) {
    const profile = this.context;
    axios.get(`https://eposgestofinal.pt/api/clientes/matriculas/getById.php?idempresa=${profile.idEmpresa}&cliente=${this.state.codigo}&codigo=${codigo}`)
        .then(response => {
            const matricula = response.data !== "NOK" ? response.data[0] : {};

            this.setState({
                newEdit: true,
                newcodigo: matricula.codigo,
                newmatricula: matricula.matricula,
                newcodigo_tipo_viatura: matricula.codigo_tipo_viatura,
                newcodigo_marca: matricula.codigo_marca,
                newmodelo: matricula.modelo,
                newcodigo_tipo_combustivel: matricula.codigo_tipo_combustivel,
                newcilindrada: matricula.cilindrada,
                newano_matricula: matricula.ano_matricula,
                newchassis: matricula.chassis,
                newps: matricula.ps,
                newbloqueada: matricula.bloqueada === 'True',
                newpergunta_kilometros: matricula.pergunta_kilometros === 'True',
                newkms: matricula.kms,
                newpergunta_requisicao: matricula.pergunta_requisicao === 'True',
                newacumula_pontos: matricula.acumula_pontos === 'True',
                newobs_consola_mat: matricula.obs_consola_mat,
                newsub_conta: matricula.sub_conta,
                newcolectivo: matricula.colectivo,
                newgrupo_mat: matricula.grupo_mat,
            }, () => eventBus.$emit("Modal-matriculas", { header: "Editar matrícula", body: null }));
        })
        .catch(error => {
            console.error(error);
        });
  }

//   handleTableSubmit(e) {
//     const profile = this.context;
//     e.preventDefault();
    
//     axios.post('https://eposgestofinal.pt/api/clientes/services/set.php', {
//         services: this.state.data.filter((product) => product.comission),
//         idempresa: profile.idEmpresa,
//         codigo: this.state.codigo,
//     })
//     .then(response => {
//         this.setState({ dataChanged: 0 });
//     })
//     .catch(error => {
//         console.error(error);
//     });
//   }

//   handleEmailSend(e) {
//     const profile = this.context;

//     e.preventDefault();
//     confirmAlert({
//         title: 'Confirmação',
//         message: `Tem a certeza que deseja enviar estes dados por email para o cliente ${this.state.name} <${this.state.email}>?`,
//         buttons: [
//             {
//                 label: 'Sim',
//                 onClick: () => {
//                     const { history } = this.props;
                    
//                     axios.get(`https://eposgestofinal.pt/api/clientes/services/sendEmail.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
//                     .then(response => {
//                         if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
//                             window.close();
//                         } else {
//                             history.push('/clientes');
//                         }
//                     })
//                     .catch(error => {
//                         console.error(error);
//                     });
//                 }
//             },
//             {
//                 label: 'Não',
//                 onClick: () => {}
//             }
//         ]
//     });
//   }

  handleFormSubmit(e, quota) {
    const profile = this.context;
    const { history } = this.props;
    if (e) {
        e.preventDefault();
    }

    const validacp = /^\d{4}(-\d{3})?$/.test(this.state.codigo_postal);

    if (!validacp) {
      alert("Código Postal inválido! Apenas formato xxxx-xxx ou xxxx permitidos.")
      return;
    }
    
    axios.post('https://eposgestofinal.pt/api/clientes/set.php', {
        idempresa: profile.idEmpresa,
        codigo: this.state.codigo,
        nome_1: this.state.nome_1,
        nome_2: this.state.nome_2,
        nif: this.state.nif,
        email: this.state.email,
        telefone: this.state.telefone,
        telefone_2: this.state.telefone_2,
        fax: this.state.fax,
        telemovel: this.state.telemovel,
        morada_1: this.state.morada_1,
        morada_2: this.state.morada_2,
        codigo_postal: this.state.codigo_postal,
        credito_concedido: this.state.credito_concedido,
        data_inicio_ft: this.state.data_inicio_ft,
        data_de_nascimento: this.state.data_de_nascimento,
        fotografia: this.state.fotografia,
        ativo: this.state.ativo ? 't' : 'f',
    })
    .then(response => {
        if (quota) {
            return;
        }
        if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
            window.close();
        } else {
            history.push('/clientes');
        }
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
        window.close();
    } else {
        history.push('/clientes');
    }
  }

  showPopupHandler(idempresa, codigo, idservice) {
    this.setState({ showPopup: { idempresa, codigo, idservice, photo: false, upload: false } });
  }

  hidePopupHandler() {
    this.setState({ showPopup: {} });
  }

  showCameraHandler() {
    this.setState({ showPopup: { ...this.state.showPopup, photo: true, upload: false } });
  }

  showUploadHandler() {
    this.setState({ showPopup: { ...this.state.showPopup, photo: false, upload: true } });
  }

  filterData(searchTerm) {
    this.setState({ searchHousehold: searchTerm });
  }

  async removeFunction(idservice) {
    const profile = this.context;

    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja apagar o serviço?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                    axios.get(`https://eposgestofinal.pt/api/clientes/services/unset.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}&idservice=${idservice}`)
                    .then(response => {
                        if (response.data !== 'NOK') {
                            this.setState({ services: this.state.services.filter(service => service.idservice !== idservice) });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {}
            }
        ]
    });
}

handleDescontosSubmitSuccess() {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .post("https://eposgestofinal.pt/api/clientes/descontos/set.php", {
          idempresa: profile.idEmpresa,
          cliente: this.state.codigo,
          grupo: this.state.newgrupo,
          familia: this.state.newfamilia.value,
          produto: this.state.newproduto.value,
          dia_semana: this.state.newdiasemana,
          hora_inicial: this.state.newhorainicial,
          hora_final: this.state.newhorafinal,
          desconto_per: this.state.newdescontoper,
          desconto_valor: this.state.newdescontovalor,
          preco_unitario: this.state.newprecounitario,
          produto_parceiro: this.state.newprodutoparceiro,
        })
        .then((response) => {
          eventBus.$emit("Modal-descontos", { isOpen: false });
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleDescontoDeleteSuccess(grupo, familia, produto) {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .post("https://eposgestofinal.pt/api/clientes/descontos/unset.php", {
          idempresa: profile.idEmpresa,
          cliente: this.state.codigo,
          grupo: grupo,
          familia: familia,
          produto: produto,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleMatriculasSubmitSuccess() {
    const profile = this.context;
    if (this.state.newcodigo && !isNaN(this.state.newcodigo) && this.state.newcodigo.length > 0 && this.state.newcodigo.length <= 9) {
        axios.get(`https://eposgestofinal.pt/api/clientes/matriculas/getById.php?idempresa=${profile.idEmpresa}&codigo=${this.state.newcodigo}`)
            .then(response => {
                const uniqCodigo = response.data !== 'NOK' && !response.data.length;
                if (this.state.newEdit || uniqCodigo) {
                    axios
                      .post("https://eposgestofinal.pt/api/clientes/matriculas/set.php", {
                          idempresa: profile.idEmpresa,
                          cliente: this.state.codigo,
                          codigo: this.state.newcodigo,
                          matricula: this.state.newmatricula,
                          codigo_tipo_viatura: this.state.newcodigo_tipo_viatura,
                          codigo_marca: this.state.newcodigo_marca,
                          modelo: this.state.newmodelo,
                          codigo_tipo_combustivel: this.state.newcodigo_tipo_combustivel,
                          cilindrada: this.state.newcilindrada,
                          ano_matricula: this.state.newano_matricula,
                          chassis: this.state.newchassis,
                          ps: this.state.newps,
                          bloqueada: this.state.newbloqueada ? 'True' : 'False',
                          pergunta_kilometros: this.state.newpergunta_kilometros ? 'True' : 'False',
                          kms: this.state.newkms,
                          pergunta_requisicao: this.state.newpergunta_requisicao ? 'True' : 'False',
                          acumula_pontos: this.state.newacumula_pontos ? 'True' : 'False',
                          obs_consola_mat: this.state.newobs_consola_mat,
                          sub_conta: this.state.newsub_conta,
                          colectivo: this.state.newcolectivo,
                          grupo_mat: this.state.newgrupo_mat,
                      })
                      .then((response) => {
                        eventBus.$emit("Modal-matriculas", { isOpen: false });
                        window.location.reload();
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                    } else {
                        alert('Código já existente!')
                    }
            })
            .catch(error => {
                console.error(error);
            });
    } else {
        alert('Código inválido! Tem de ser um número com no máximo 9 carcteres.')
    }
  }

  handleMatriculaDeleteSuccess(codigo) {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .post("https://eposgestofinal.pt/api/clientes/matriculas/unset.php", {
          idempresa: profile.idEmpresa,
          cliente: this.state.codigo,
          codigo: codigo,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleGerarCartaoSuccess() {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .get(`https://eposgestofinal.pt/api/clientes/members/generate.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
        .then((response) => {
            if (response.data !== 'NOK') {
                this.setState({ card: response.data.card });
            }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleDownloadCartao() {
    eventBus.$emit("Loading", { active: true })
    toPng(this.cardRef.current, { cacheBust: false })
      .then((dataUrl) => {
          download(dataUrl, "cartao_" + this.state.nome_1 + ".png");
            eventBus.$emit("Loading", { active: false })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEmailCartao() {
    const profile = this.context;
    let email = prompt("Email:", this.state.email);
    
    if (email) {
        eventBus.$emit("Loading", { active: true })
        toPng(this.cardRef.current, { cacheBust: false })
          .then((dataUrl) => {
            axios
                .post("https://eposgestofinal.pt/api/clientes/members/mail.php", {
                    idempresa: profile.idEmpresa,
                    codigo: this.state.codigo,
                    email: email,
                    cardfile: dataUrl,
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    eventBus.$emit("Loading", { active: false })
                });
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }

  handleDownloadMatricula() {
    eventBus.$emit("Loading", { active: true })
    toPng(this.matriculaRef.current, { cacheBust: false })
      .then((dataUrl) => {
          download(dataUrl, "cartao_" + this.state.nome_1 + "_" + this.state.emailmatricula.matricula + ".png");
            eventBus.$emit("Loading", { active: false });
            eventBus.$emit("Modal-emailmatricula", { isOpen: false });

      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEmailMatricula() {
    const profile = this.context;
    let email = prompt("Email:", this.state.email);
    
    if (email) {
        eventBus.$emit("Loading", { active: true })
        toPng(this.matriculaRef.current, { cacheBust: false })
          .then((dataUrl) => {
            axios
                .post("https://eposgestofinal.pt/api/clientes/matriculas/mail.php", {
                    idempresa: profile.idEmpresa,
                    codigo: this.state.codigo,
                    matricula: this.state.emailmatricula.matricula,
                    email: email,
                    cardfile: dataUrl,
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    eventBus.$emit("Loading", { active: false });
                    eventBus.$emit("Modal-emailmatricula", { isOpen: false });
                });
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }

  handleFile(event) {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                fotografia: reader.result
            })
        };
        reader.readAsDataURL(file);
    }
  }

  handleRemovePhoto() {
    this.setState({
        fotografia: ''
    })
  }

  todayDataInicioFt() {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    this.setState({
        data_inicio_ft: formattedDate,
    })
  }

  localDateChange(date) {
    this.setState({ date: [
        new Date(date[0].setHours(0 , 0 , 0)).getTime(),
        new Date(date[1].setHours(23 , 59 , 59)).getTime(),
      ],
      tableDocumentos: {
        ...this.state.tableDocumentos,
        loading: true,
      }
    }, () => this.getDocuemnts(false));
  }

  render() {
    const { location, history } = this.props;
    const {
        edit,
        codigo,
        card,
        loaded,
        nome_1,
        nome_2,
        email,
        telefone,
        telefone_2,
        fax,
        telemovel,
        nif,
        morada_1,
        morada_2,
        codigo_postal,
        credito_concedido,
        plafond,
        data_inicio_ft,
        data_de_nascimento,
        dataChanged,
        ativo,
        tableDescontos,
        tableDocumentos,
        documentsdebit,
        documentscredit,
        documentstotal,
        tipoDocumentos,
        tipoDocumento,
        nifDocumentos,
        nifDocumento,
        postos,
        posto,
        search,
        date,
        newgrupo,
        newfamilia,
        newproduto,
        newdiasemana,
        newhorainicial,
        newhorafinal,
        newdescontoper,
        newdescontovalor,
        newprecounitario,
        newprodutoparceiro,
        tableMatriculas,
        newEdit,
        newcodigo,
        newmatricula,
        newcodigo_tipo_viatura,
        newcodigo_marca,
        newmodelo,
        newcodigo_tipo_combustivel,
        newcilindrada,
        newano_matricula,
        newchassis,
        newps,
        newbloqueada,
        newpergunta_kilometros,
        newkms,
        newpergunta_requisicao,
        newacumula_pontos,
        newobs_consola_mat,
        newsub_conta,
        newcolectivo,
        newgrupo_mat,
        categories,
        products,
        torniquete,
        fotografia,
        emailmatricula,
    } = this.state;
    const cookies = new URLSearchParams(location.search);
    const profile = this.context;

    const torniqueteRows = torniquete.rows;
    // const torniqueteRows = torniquete.rows.length
    //         ? torniquete.rows.filter((tickets) =>
    //           filtertorniqueteearch.every(
    //                 (eachSearch) => {
    //                     return eachSearch === "" ||
    //                     (tickets.name && tickets.name.toLowerCase().includes(eachSearch.toLowerCase()))
    //                 }
    //             )
    //             )
    //         : [];

    // const filterSearchHousehold = searchHousehold.split(" ");

    // const tableHouseholdRows = tableHousehold.rows.length
    //   ? tableHousehold.rows.filter((customer) =>
    //     filterSearchHousehold.every(
    //         (eachSearch) =>
    //           eachSearch === "" ||
    //           (customer.name && customer.name.toLowerCase().includes(eachSearch.toLowerCase())) ||
    //           (customer.idagregado	 && customer.idagregado	.toLowerCase().includes(eachSearch.toLowerCase()))
    //       )
    //     )
    //   : [];

    const filterSearch = search.split(" ");    

    const filteredTableDocumentos = {
        ...tableDocumentos,
        rows: tableDocumentos.rows
          .filter((documento) =>
            posto.value > 0
              ? documento.posto === posto.value
              : true
          )
          .filter((documento) =>
            tipoDocumento.value
              ? documento.source_abrv === tipoDocumento.value
              : true
          )
          .filter((documento) =>
            filterSearch.every(
              (eachSearch) =>
                (documento.numero || '')
                  .toLowerCase()
                  .includes(eachSearch.toLowerCase())
            )
          )
      };

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/clientes">Clientes</a></li>
                        <li className="active">
                            {edit ? (<span>{codigo}</span   >) : (<span>Novo Cliente</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            <Tabs>
                <TabList>
                    {this.state.codigo != 'CF' && (<Tab>Detalhes</Tab>)}
                    <Tab>Documentos</Tab>
                    {this.state.codigo != 'CF' && (<Tab>Descontos</Tab>)}
                    {this.state.codigo != 'CF' && (<Tab>Matriculas</Tab>)}
                        {this.state.codigo != 'CF' && (<Tab>Cartão</Tab>)}
                    {/* {dataChanged !== -1 && (
                    <>
                        <Tab>Quotas</Tab>
                        <Tab>Agregado</Tab>
                        <Tab>Serviços</Tab>
                    </>
                    )} */}
                </TabList>

                {this.state.codigo != 'CF' && (<TabPanel>
                    {loaded && (
                    <div className="fullContainer" style={{ maxWidth: '100%' }}>
                    <div className="col-sm-12">
                      <div className="panel panel-default" style={{ 
                        borderRadius: '12px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                      }}>
                        <div className="container-fluid">
                          <div className="invoice-panel" style={{ padding: '40px' }}>
                            <div className="header" style={{ marginBottom: '40px' }}>
                              <h1 className="title" style={{ 
                                fontSize: '2.5rem',
                                color: '#1a365d',
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px'
                              }}>
                                <div style={{
                                  width: '40px',
                                  height: '40px',
                                  backgroundColor: '#3182ce',
                                  borderRadius: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                                    <circle cx="12" cy="7" r="4"/>
                                  </svg>
                                </div>
                                Detalhes do Cliente
                              </h1>
                            </div>
            
                            <div style={{ display: 'grid', gap: '24px' }}>
                              <div className="responsive-grid" style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '24px'}} >
                              {/* Personal Information Card */}
                                <div style={{
                                  backgroundColor: 'white',
                                  borderRadius: '12px',
                                  overflow: 'hidden',
                                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                                }}>
                                  <div style={{
                                    padding: '16px 24px',
                                    borderBottom: '1px solid #e2e8f0',
                                    backgroundColor: '#f8fafc'
                                  }}>
                                    <h2 style={{
                                      fontSize: '1.25rem',
                                      fontWeight: '600',
                                      color: '#1e293b',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '8px'
                                    }}>
                                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                                        <circle cx="12" cy="7" r="4"/>
                                      </svg>
                                      Informações Pessoais
                                    </h2>
                                  </div>
                                  <div style={{
                                    padding: '24px',
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                    gap: '20px'
                                  }}>
                                    <Input
                                      title="Nome Principal"
                                      inputType="text"
                                      name="nome_1"
                                      value={nome_1}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                                          <circle cx="12" cy="7" r="4"/>
                                        </svg>
                                      }
                                    />
                                    <Input
                                      title="Nome Secundário"
                                      inputType="text"
                                      name="nome_2"
                                      value={nome_2}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                                          <circle cx="12" cy="7" r="4"/>
                                        </svg>
                                      }
                                    />
                                    <Input
                                          title="Data de Nascimento"
                                          inputType="date"
                                          name="data_de_nascimento"
                                          value={data_de_nascimento}
                                          handleChange={this.handleChange}
                                          icon={
                                            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                              <rect x="3" y="4" width="18" height="16" rx="2"/>
                                              <line x1="16" y1="2" x2="16" y2="6"/>
                                              <line x1="8" y1="2" x2="8" y2="6"/>
                                              <line x1="3" y1="10" x2="21" y2="10"/>
                                            </svg>
                                          }
                                      />
                                    <Input
                                      title="NIF"
                                      inputType="text"
                                      name="nif"
                                      value={nif}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <rect x="3" y="4" width="18" height="16" rx="2"/>
                                          <path d="M7 8h10M7 12h10M7 16h10"/>
                                        </svg>
                                      }
                                    />
                                    <Input
                                      title="E-Mail"
                                      inputType="email"
                                      name="email"
                                      value={email}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                                          <polyline points="22,6 12,13 2,6"/>
                                        </svg>
                                      }
                                    />
                                  </div>
                                </div>
                                {/* Photo Card */}
                                <div style={{
                                  backgroundColor: 'white',
                                  borderRadius: '12px',
                                  overflow: 'hidden',
                                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                                }}>
                                  <div style={{
                                    padding: '16px 24px',
                                    borderBottom: '1px solid #e2e8f0',
                                    backgroundColor: '#f8fafc'
                                  }}>
                                    <h2 style={{
                                      fontSize: '1.25rem',
                                      fontWeight: '600',
                                      color: '#1e293b',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '8px'
                                    }}>
                                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                                        <circle cx="12" cy="12" r="4"/>
                                        <path d="M16 8v-2a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2"/>
                                      </svg>
                                      Fotografia
                                    </h2>
                                  </div>
                                  <div style={{
                                    padding: '24px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '24px'
                                  }}>
                                    <div style={{
                                      width: '200px',
                                      height: '200px',
                                      borderRadius: '12px',
                                      overflow: 'hidden',
                                      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                      position: 'relative'
                                    }}>
                                      <img 
                                        src={fotografia || 'https://eposgestofinal.pt/site/images/profile.jpg'} 
                                        alt="Profile"
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover'
                                        }}
                                      />
                                      <div style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        padding: '8px',
                                        background: 'rgba(0,0,0,0.7)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '8px'
                                      }}>
                                        <label 
                                          htmlFor="photo-upload"
                                          style={{
                                            padding: '6px 12px',
                                            borderRadius: '6px',
                                            backgroundColor: '#3b82f6',
                                            color: 'white',
                                            fontSize: '0.875rem',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px'
                                          }}
                                        >
                                          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                            <polyline points="17 8 12 3 7 8"/>
                                            <line x1="12" y1="3" x2="12" y2="15"/>
                                          </svg>
                                          Alterar
                                        </label>
                                        {fotografia && (
                                          <button
                                            onClick={this.handleRemovePhoto}
                                            style={{
                                              padding: '6px 12px',
                                              borderRadius: '6px',
                                              backgroundColor: '#ef4444',
                                              border: 'none',
                                              color: 'white',
                                              fontSize: '0.875rem',
                                              cursor: 'pointer',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '6px'
                                            }}
                                          >
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                              <path d="M3 6h18"/>
                                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                            </svg>
                                            Remover
                                          </button>
                                        )}
                                      </div>
                                    </div>

                                    <div style={{
                                      width: '100%',
                                      maxWidth: '400px',
                                      border: '2px dashed #e2e8f0',
                                      borderRadius: '12px',
                                      padding: '24px',
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      transition: 'all 0.2s',
                                      backgroundColor: '#f8fafc'
                                    }}
                                    onDragOver={(e) => {
                                      e.preventDefault();
                                      e.currentTarget.style.borderColor = '#3b82f6';
                                      e.currentTarget.style.backgroundColor = '#eff6ff';
                                    }}
                                    onDragLeave={(e) => {
                                      e.currentTarget.style.borderColor = '#e2e8f0';
                                      e.currentTarget.style.backgroundColor = '#f8fafc';
                                    }}
                                    onDrop={(e) => {
                                      e.preventDefault();
                                      e.currentTarget.style.borderColor = '#e2e8f0';
                                      e.currentTarget.style.backgroundColor = '#f8fafc';
                                      const file = e.dataTransfer.files[0];
                                      if (file) {
                                        this.handleFile({ target: { files: [file] } });
                                      }
                                    }}
                                    >
                                      <input
                                        type="file"
                                        id="photo-upload"
                                        onChange={this.handleFile}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                      />
                                      <svg 
                                        width="40" 
                                        height="40" 
                                        viewBox="0 0 24 24" 
                                        fill="none" 
                                        stroke="#64748b" 
                                        strokeWidth="2"
                                        style={{ margin: '0 auto 16px' }}
                                      >
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                        <polyline points="17 8 12 3 7 8"/>
                                        <line x1="12" y1="3" x2="12" y2="15"/>
                                      </svg>
                                      <p style={{ 
                                        color: '#1e293b',
                                        fontSize: '0.875rem',
                                        fontWeight: '500',
                                        marginBottom: '8px'
                                      }}>
                                        Arraste uma foto ou clique para selecionar
                                      </p>
                                      <p style={{
                                        color: '#64748b',
                                        fontSize: '0.75rem'
                                      }}>
                                        PNG, JPG ou GIF até 5MB
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                
                                </div>
              
                                {/* Contact Information Card */}
                                <div style={{
                                  backgroundColor: 'white',
                                  borderRadius: '12px',
                                  overflow: 'hidden',
                                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                                }}>
                                  <div style={{
                                    padding: '16px 24px',
                                    borderBottom: '1px solid #e2e8f0',
                                    backgroundColor: '#f8fafc'
                                  }}>
                                    <h2 style={{
                                      fontSize: '1.25rem',
                                      fontWeight: '600',
                                      color: '#1e293b',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '8px'
                                    }}>
                                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                                      </svg>
                                      Contatos
                                    </h2>
                                  </div>
                                  <div style={{
                                    padding: '24px',
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                    gap: '20px'
                                  }}>
                                    <Input
                                      title="Telefone Principal"
                                      inputType="tel"
                                      name="telefone"
                                      value={telefone}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                                        </svg>
                                      }
                                    />
                                    <Input
                                      title="Telefone Secundário"
                                      inputType="tel"
                                      name="telefone_2"
                                      value={telefone_2}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                                        </svg>
                                      }
                                    />
                                    <Input
                                      title="Telemóvel"
                                      inputType="tel"
                                      name="telemovel"
                                      value={telemovel}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <rect x="5" y="2" width="14" height="20" rx="2" ry="2"/>
                                          <path d="M12 18h.01"/>
                                        </svg>
                                      }
                                    />
                                    <Input
                                      title="Fax"
                                      inputType="tel"
                                      name="fax"
                                      value={fax}
                                      handleChange={this.handleChange}
                                      icon={
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                          <rect x="2" y="7" width="20" height="14" rx="2" ry="2"/>
                                          <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"/>
                                        </svg>
                                      }
                                    />
                                  </div>
                              </div>
            
                              {/* Address and Financial Card */}
                              <div style={{
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                overflow: 'hidden',
                                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                              }}>
                                <div style={{
                                  padding: '16px 24px',
                                  borderBottom: '1px solid #e2e8f0',
                                  backgroundColor: '#f8fafc'
                                }}>
                                  <h2 style={{
                                    fontSize: '1.25rem',
                                    fontWeight: '600',
                                    color: '#1e293b',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                  }}>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                                      <polyline points="9 22 9 12 15 12 15 22"/>
                                    </svg>
                                    Endereço e Financeiro
                                  </h2>
                                </div>
                                <div style={{
                                  padding: '24px',
                                  display: 'grid',
                                  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                  gap: '20px'
                                }}>
                                  <Input
                                    title="Morada"
                                    inputType="text"
                                    name="morada_1"
                                    value={morada_1}
                                    handleChange={this.handleChange}
                                    icon={
                                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                                        <polyline points="9 22 9 12 15 12 15 22"/>
                                      </svg>
                                    }
                                  />
                                  <Input
                                    title="Localidade"
                                    inputType="text"
                                    name="morada_2"
                                    value={morada_2}
                                    handleChange={this.handleChange}
                                    icon={
                                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                                        <circle cx="12" cy="10" r="3"/>
                                      </svg>
                                    }
                                  />
                                  <Input
                                    title="Código Postal"
                                    inputType="text"
                                    name="codigo_postal"
                                    value={codigo_postal}
                                    handleChange={this.handleChange}
                                    placeholder="XXXX-XXX"
                                    icon={
                                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                                        <circle cx="12" cy="10" r="3"/>
                                      </svg>
                                    }
                                  />
                                  <Input
                                    title="Crédito Concedido"
                                    inputType="number"
                                    step="0.01"
                                    name="credito_concedido"
                                    value={credito_concedido}
                                    handleChange={this.handleChange}
                                    icon={
                                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <circle cx="12" cy="12" r="10"/>
                                        <path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"/>
                                        <path d="M12 6v2m0 8v2"/>
                                      </svg>
                                    }
                                  />
                                  <Input
                                    title="Plafond"
                                    inputType="number"
                                    step="0.01"
                                    name="plafond"
                                    value={plafond}
                                    handleChange={this.handleChange}
                                    disabled
                                    icon={
                                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <circle cx="12" cy="12" r="10"/>
                                        <path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"/>
                                        <path d="M12 6v2m0 8v2"/>
                                      </svg>
                                    }
                                  />
                                </div>
                              </div>
            
                              {/* Additional Settings Card */}
                              <div style={{
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                overflow: 'hidden',
                                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                              }}>
                                <div style={{
                                  padding: '16px 24px',
                                  borderBottom: '1px solid #e2e8f0',
                                  backgroundColor: '#f8fafc'
                                }}>
                                  <h2 style={{
                                    fontSize: '1.25rem',
                                    fontWeight: '600',
                                    color: '#1e293b',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                  }}>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                      <circle cx="12" cy="12" r="3"/>
                                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
                                    </svg>
                                    Configurações Adicionais
                                  </h2>
                                </div>
                                <div style={{
                                  padding: '24px',
                                  display: 'grid',
                                  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                  gap: '20px'
                                }}>
                                  <div>
                                    <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '12px'
                                    }}>
                                      <Input
                                        title="Data Inicio Faturação"
                                        inputType="date"
                                        name="data_inicio_ft"
                                        value={data_inicio_ft}
                                        handleChange={this.handleChange}
                                        icon={
                                          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                            <rect x="3" y="4" width="18" height="16" rx="2"/>
                                            <line x1="16" y1="2" x2="16" y2="6"/>
                                            <line x1="8" y1="2" x2="8" y2="6"/>
                                            <line x1="3" y1="10" x2="21" y2="10"/>
                                          </svg>
                                        }
                                      />
                                      <button
                                        onClick={this.todayDataInicioFt}
                                        style={{
                                          padding: '8px 12px',
                                          marginTop: '25px',
                                          borderRadius: '6px',
                                          border: '1px solid #e2e8f0',
                                          backgroundColor: 'white',
                                          color: '#3182ce',
                                          fontSize: '0.875rem',
                                          cursor: 'pointer',
                                          transition: 'all 0.2s',
                                          whiteSpace: 'nowrap'
                                        }}
                                      >
                                        Data de Hoje
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <label className="form-label">Ativo</label>
                                    <div style={{ marginTop: '8px' }}>
                                      <Switch
                                        name="ativo"
                                        checked={ativo}
                                        handleChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
            
                            {/* Action Buttons */}
                            <div style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              gap: '16px',
                              marginTop: '32px',
                              padding: '24px',
                              backgroundColor: 'white',
                              borderRadius: '12px',
                              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                            }}>
                              <button
                                onClick={this.handleFormCancel}
                                style={{
                                  padding: '10px 20px',
                                  borderRadius: '8px',
                                  border: '1px solid #e2e8f0',
                                  backgroundColor: 'white',
                                  color: '#64748b',
                                  fontSize: '0.875rem',
                                  fontWeight: '500',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                  cursor: 'pointer',
                                  transition: 'all 0.2s'
                                }}
                              >
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                  <path d="M19 12H5M12 19l-7-7 7-7"/>
                                </svg>
                                Cancelar
                              </button>
                              <button
                                onClick={this.handleFormSubmit}
                                style={{
                                  padding: '10px 20px',
                                  borderRadius: '8px',
                                  border: 'none',
                                  backgroundColor: '#3b82f6',
                                  color: 'white',
                                  fontSize: '0.875rem',
                                  fontWeight: '500',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                  cursor: 'pointer',
                                  transition: 'all 0.2s'
                                }}
                              >
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                  <path d="M5 13l4 4L19 7"/>
                                </svg>
                                Guardar Alterações
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    )}
                </TabPanel>)}
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default" style={{ minHeight: "500px" }}>
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Documentos{" "}
                              <h6>
                                {edit ? (
                                  <span>{nome_1}</span>
                                ) : (
                                  <span>Novo Cliente</span>
                                )}
                              </h6>
                            </div>
                        </div>
                        <table className="table table-bordered table-hover table-sortable">
                            <thead>
                                <tr>
                                    {this.state.codigo == 'CF' && (<th className="text-center">Nif</th>)}
                                    <th className="text-center">Data</th>
                                    <th className="text-center">Posto</th>
                                    <th className="text-center">Tipo de Documento</th>
                                    <th className="text-center">Pesquisa</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {this.state.codigo == 'CF' && (<td style={{ width: "200px" }}>
                                        <AutoComplete
                                            name={"nifDocumento"}
                                            value={nifDocumento}
                                            handleChange={this.handleChange}
                                            options={nifDocumentos}
                                        />
                                    </td>)}
                                    <td style={{ width: "200px" }}>
                                        <DateRangePicker maxDate={new Date()} value={date} onChange={this.localDateChange} />
                                    </td>
                                    <td style={{ width: "200px" }}>
                                        <AutoComplete
                                            name={"posto"}
                                            value={posto}
                                            handleChange={this.handleChange}
                                            options={postos}
                                        />
                                    </td>
                                    <td style={{ width: "200px" }}>
                                        <AutoComplete
                                            name={"tipoDocumento"}
                                            value={tipoDocumento}
                                            handleChange={this.handleChange}
                                            options={tipoDocumentos}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            inputType={"text"}
                                            name={"search"}
                                            value={search}
                                            handleChange={this.handleChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Débito<h4>{numberWithCommas(twoDecimal(documentsdebit) || "0.00")} €</h4></td>
                                    <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Crédito<h4>{numberWithCommas(twoDecimal(documentscredit) || "0.00")} €</h4></td>
                                    <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                        Saldo Total<h4 style={{ color: documentstotal < 0 ? '#E03241' : documentstotal > 0 ? '#1BA73F' : 'black' }}>{numberWithCommas(twoDecimal(documentstotal) || "0.00")} €</h4>
                                    </td>
                                </tr>
                                {this.state.codigo == 'CF' && this.state.nifDocumento.value == '' ? null : (<tr>
                                    <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }} colspan="3">
                                        <PrintDocuments
                                            table={ filteredTableDocumentos }
                                            documentstotal={ documentstotal }
                                            documentsdebit={ documentsdebit }
                                            documentscredit={ documentscredit }
                                            date={ date }
                                            posto={ posto }
                                            tipoDocumento={ tipoDocumento }
                                            search={ search }
                                            nifDocumento={ nifDocumento }
                                        />
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                          {loaded && this.state.codigo == 'CF' && this.state.nifDocumento.value == '' ? null : (
                            <div>
                                <ReactTable
                                    columns={filteredTableDocumentos.columns}
                                    sortable={true}
                                    loading={filteredTableDocumentos.loading}
                                    data={filteredTableDocumentos.rows}
                                    excelDownload={true}
                                />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </TabPanel>
                {this.state.codigo != 'CF' && (<TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Descontos{" "}
                              <h6>
                                {edit ? (
                                  <span>{nome_1}</span>
                                ) : (
                                  <span>Novo Cliente</span>
                                )}
                              </h6>
                            </div>
                            <div onClick={() => eventBus.$emit("Modal-descontos", { header: "Novo desconto", body: null })}>
                              <MdAddCircleOutline
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          {loaded && (
                            <div>
                                <ReactTable
                                    columns={tableDescontos.columns}
                                    sortable={true}
                                    loading={tableDescontos.loading}
                                    data={tableDescontos.rows}
                                />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </TabPanel>)}
                {this.state.codigo != 'CF' && (<TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Matriculas{" "}
                              <h6>
                                {edit ? (
                                  <span>{nome_1}</span>
                                ) : (
                                  <span>Novo Cliente</span>
                                )}
                              </h6>
                            </div>
                                <div onClick={() => this.setState({
                                    newEdit: false,
                                    newEditCodigo: '',
                                    newcodigo: '',
                                    newmatricula: '',
                                    newcodigo_tipo_viatura: '',
                                    newcodigo_marca: '',
                                    newmodelo: '',
                                    newcodigo_tipo_combustivel: '',
                                    newcilindrada: '',
                                    newano_matricula: '',
                                    newchassis: '',
                                    newps: '',
                                    newbloqueada: false,
                                    newpergunta_kilometros: false,
                                    newkms: '',
                                    newpergunta_requisicao: false,
                                    newacumula_pontos: false,
                                    newobs_consola_mat: '',
                                    newsub_conta: '',
                                    newcolectivo: '',
                                    newgrupo_mat: '',
                                }, () => eventBus.$emit("Modal-matriculas", { header: "Nova matrícula", body: null }))}>
                                    <table
                                        style={{ cursor: "pointer" }}
                                    >
                                        <tr>
                                            <td>
                                                <MdAddCircleOutline
                                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "xx-small" }}>
                                                Adicionar matrícula
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                          </div>
                          {loaded && (
                            <div>
                                <ReactTable
                                    columns={tableMatriculas.columns}
                                    sortable={true}
                                    loading={tableMatriculas.loading}
                                    data={tableMatriculas.rows}
                                />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </TabPanel>)}
                {this.state.codigo != 'CF' && (<TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className={loaded && card ? "col-sm-6" : "col-sm-12"}
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Cartão{" "}
                              <h6>
                                {edit ? (
                                  <span>{nome_1}</span>
                                ) : (
                                  <span>Novo Cliente</span>
                                )}
                              </h6>
                            </div>
                            {loaded && card ? (
                                <>
                                    <div>
                                        <table
                                            style={{ cursor: "pointer" }}
                                            onClick={this.handleEmailCartao}
                                        >
                                            <tr>
                                                <td>
                                                    <MdEmail
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: "xx-small" }}>
                                                    Enviar cartão
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <table
                                            style={{ cursor: "pointer" }}
                                            onClick={this.handleDownloadCartao}
                                        >
                                            <tr>
                                                <td>
                                                    <MdDownload
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: "xx-small" }}>
                                                    Descarregar cartão
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <table
                                            style={{ cursor: "pointer" }}
                                            onClick={this.handleGerarCartaoSuccess}
                                        >
                                            <tr>
                                                <td>
                                                    <MdOutlineAutorenew
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: "xx-small" }}>
                                                    Renovar cartão
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <table
                                        style={{ cursor: "pointer" }}
                                        onClick={this.handleGerarCartaoSuccess}
                                    >
                                        <tr>
                                            <td>
                                                <MdAddCircleOutline
                                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "xx-small" }}>
                                                Criar cartão
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            )}
                          </div>
                          {loaded && card ? (
                            <div ref={this.cardRef} style={{ padding: '50px' }}>
                                <Card nome={nome_1} nif={nif} card={card} fotografia={fotografia} />
                            </div>
                          ) : (<h1>Nenhum cartão encontrado.</h1>)}
                        </div>
                    </div>
                    {loaded && card ? (<div
                        className="col-sm-6"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Torniquete
                                </div>
                            </div>
                            <ReactTable
                                columns={ torniquete.columns }
                                sortable={ true }
                                pageSize={ torniquete.pageSize }
                                loading={ torniquete.loading }
                                data={ torniqueteRows }
                            />
                            </div>
                      </div>
                    ) : null}
                    </div>
                </TabPanel>)}
                {/* {dataChanged !== -1 && (
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Tipo de Quota
                                </div>
                            </div>
                            <AutoComplete
                                name={"quota"}
                                value={quota}
                                handleChange={this.handleChange}
                                options={quotaTypes}
                            />
                            </div>
                        </div>
                    </div>
                    {quota.value !== 0 && (<div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Quotas
                                </div>
                                <div>
                                <table
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/clientes/${codigo}/quotas/new`)}
                                >
                                    <tr>
                                    <td>
                                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{ fontSize: "xx-small" }}>Adicionar quota</td>
                                    </tr>
                                </table>
                                </div>
                            </div>
                            <ReactTable
                                columns={tableQuotas.columns}
                                sortable={true}
                                loading={tableQuotas.loading}
                                data={tableQuotas.rows}
                            />
                            </div>
                        </div>
                    </div>)}
                </TabPanel>
                )}
                {dataChanged !== -1 && (
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Agregado
                                </div>
                                <div>
                                <table
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/clientes/${codigo}/household/new`)}
                                >
                                    <tr>
                                    <td>
                                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{ fontSize: "xx-small" }}>Adicionar membro</td>
                                    </tr>
                                </table>
                                </div>
                            </div>

                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                <tr>
                                    <th className="text-center">Pesquisa</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{ width: "250px" }}>
                                    <Input
                                        inputType={"text"}
                                        name={"search"}
                                        value={searchHousehold}
                                        placeholder="pesquisa..."
                                        handleChange={(e) => this.filterData(e.target.value)}
                                    />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <ReactTable
                                columns={tableHousehold.columns}
                                sortable={true}
                                loading={tableHousehold.loading}
                                data={tableHouseholdRows}
                            />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                )} */}
            </Tabs>
            <Modal id="descontos">
                <form class="well form-horizontal" action=" " method="post" id="contact_form">
                    <div class="st st-theme-github st-horizontal">
                        <fieldset>
                        <div className="form-group">
                                <label className="col-md-1 control-label">Groupo</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"number"}
                                            name={"newgrupo"}
                                            value={newgrupo}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Familia</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <AutoComplete
                                            name={"newfamilia"}
                                            value={newfamilia}
                                            handleChange={this.handleChange}
                                            options={categories}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Produto</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <AutoComplete
                                            name={"newproduto"}
                                            value={newproduto}
                                            handleChange={this.handleChange}
                                            options={products}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Dia Semana</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newdiasemana"}
                                            value={newdiasemana}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Hora Inicial</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newhorainicial"}
                                            value={newhorainicial}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Hora Final</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newhorafinal"}
                                            value={newhorafinal}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-1 control-label">Desconto %</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                inputType={"number"}
                                step="0.01"
                                name={"newdescontoper"}
                                value={newdescontoper}
                                handleChange={this.handleChange}
                            />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Desconto Valor</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"number"}
                                            step="0.01"
                                            name={"newdescontovalor"}
                                            value={newdescontovalor}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Preço Unitário</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            step="0.01"
                                            name={"newprecounitario"}
                                            value={newprecounitario}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Produto Parceiro</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newprodutoparceiro"}
                                            value={newprodutoparceiro}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                action={this.handleDescontosSubmitSuccess}
                                type={"success"}
                                title={"Submeter"}
                                style={buttonStyle}
                            />
                        </fieldset>
                    </div>
                </form>
            </Modal>
            <Modal id="matriculas" modifier="big">
            <div className="fullContainer" style={{ marginTop: '25px', marginBottom: '50px' }}>
          <div className="col-sm-12">
            <div className="panel panel-default" style={{ borderRadius: '12px', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="container-fluid">
                <div className="invoice-panel" style={{ padding: '40px' }}>
                  <div className="header" style={{ marginBottom: '40px' }}>
                    <h1 className="title" style={{ 
                      fontSize: '2.5rem',
                      color: '#1a365d',
                      fontWeight: '600',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px'
                    }}>
                      <div style={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#3182ce',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
                          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
                          <path d="M1 10h22"/>
                        </svg>
                      </div>
                      Nova Matrícula
                    </h1>
                  </div>

                  <form onSubmit={this.handleFormSubmit}>
                    <div style={{ display: 'grid', gap: '24px' }}>
                      {/* Vehicle Information Card */}
                      <div style={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                      }}>
                        <div style={{
                          padding: '16px 24px',
                          borderBottom: '1px solid #e2e8f0',
                          backgroundColor: '#f8fafc'
                        }}>
                          <h2 style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: '#1e293b',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                          }}>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                              <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
                              <path d="M1 10h22"/>
                            </svg>
                            Informações do Veículo
                          </h2>
                        </div>
                        <div style={{
                          padding: '24px',
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                          gap: '20px'
                        }}>
                          <Input
                            title="Código"
                            inputType="text"
                            name="matricula"
                            value={this.matricula}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
                                <path d="M1 10h22"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Matrícula"
                            inputType="text"
                            name="matricula"
                            value={this.matricula}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
                                <path d="M1 10h22"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Tipo de Veículo"
                            inputType="text"
                            name="codigo_tipo_viatura"
                            value={this.codigo_tipo_viatura}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M16 3h5v5"/>
                                <path d="M21 3l-7 7"/>
                                <path d="M3 21h5v-5"/>
                                <path d="M3 21l7-7"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Marca"
                            inputType="text"
                            name="codigo_marca"
                            value={this.codigo_marca}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <circle cx="12" cy="12" r="10"/>
                                <path d="M12 16v-4"/>
                                <path d="M12 8h.01"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Modelo"
                            inputType="text"
                            name="modelo"
                            value={this.modelo}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <rect x="3" y="3" width="18" height="18" rx="2"/>
                                <path d="M3 9h18"/>
                              </svg>
                            }
                          />
                        </div>
                      </div>

                      {/* Technical Details Card */}
                      <div style={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                      }}>
                        <div style={{
                          padding: '16px 24px',
                          borderBottom: '1px solid #e2e8f0',
                          backgroundColor: '#f8fafc'
                        }}>
                          <h2 style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: '#1e293b',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                          }}>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                              <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"/>
                            </svg>
                            Detalhes Técnicos
                          </h2>
                        </div>
                        <div style={{
                          padding: '24px',
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                          gap: '20px'
                        }}>
                          <Input
                            title="Tipo de Combustível"
                            inputType="text"
                            name="codigo_tipo_combustivel"
                            value={this.codigo_tipo_combustivel}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M3 22h12"/>
                                <path d="M19 22h2"/>
                                <path d="M19 22v-6"/>
                                <path d="M7 2h10"/>
                                <path d="M7 6h10"/>
                                <path d="M7 10h10"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Cilindrada"
                            inputType="number"
                            name="cilindrada"
                            value={this.cilindrada}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <circle cx="12" cy="12" r="10"/>
                                <path d="M8 14s1.5 2 4 2 4-2 4-2"/>
                                <line x1="9" y1="9" x2="9.01" y2="9"/>
                                <line x1="15" y1="9" x2="15.01" y2="9"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Ano de Matrícula"
                            inputType="number"
                            name="ano_matricula"
                            value={this.ano_matricula}
                            handleChange={this.handleChange}
                            required
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                                <line x1="16" y1="2" x2="16" y2="6"/>
                                <line x1="8" y1="2" x2="8" y2="6"/>
                                <line x1="3" y1="10" x2="21" y2="10"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Número do Chassis"
                            inputType="text"
                            name="chassis"
                            value={this.chassis}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M4 9h16"/>
                                <path d="M4 15h16"/>
                                <path d="M10 3L8 21"/>
                                <path d="M16 3l-2 18"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Potência (CV)"
                            inputType="number"
                            name="ps"
                            value={this.ps}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"/>
                              </svg>
                            }
                          />
                        </div>
                      </div>

                      {/* Additional Settings Card */}
                      <div style={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                      }}>
                        <div style={{
                          padding: '16px 24px',
                          borderBottom: '1px solid #e2e8f0',
                          backgroundColor: '#f8fafc'
                        }}>
                          <h2 style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: '#1e293b',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                          }}>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                              <circle cx="12" cy="12" r="3"/>
                              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
                            </svg>
                            Configurações Adicionais
                          </h2>
                        </div>
                        <div style={{
                          padding: '24px',
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                          gap: '20px'
                        }}>
                          <div>
                            <label className="form-label">Bloqueada</label>
                            <div style={{ marginTop: '8px' }}>
                              <Switch
                                name="bloqueada"
                                checked={this.bloqueada}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div>
                            <label className="form-label">Pergunta Quilómetros</label>
                            <div style={{ marginTop: '8px' }}>
                              <Switch
                                name="pergunta_kilometros"
                                checked={this.pergunta_kilometros}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div>
                            <label className="form-label">Pergunta Requisição</label>
                            <div style={{ marginTop: '8px' }}>
                              <Switch
                                name="pergunta_requisicao"
                                checked={this.pergunta_requisicao}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div>
                            <label className="form-label">Acumula Pontos</label>
                            <div style={{ marginTop: '8px' }}>
                              <Switch
                                name="acumula_pontos"
                                checked={this.acumula_pontos}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Additional Information Card */}
                      <div style={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                      }}>
                        <div style={{
                          padding: '16px 24px',
                          borderBottom: '1px solid #e2e8f0',
                          backgroundColor: '#f8fafc'
                        }}>
                          <h2 style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: '#1e293b',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                          }}>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
                            </svg>
                            Informações Adicionais
                          </h2>
                        </div>
                        <div style={{
                          padding: '24px',
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                          gap: '20px'
                        }}>
                          <Input
                            title="Observações"
                            inputType="text"
                            name="obs_consola_mat"
                            value={this.obs_consola_mat}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Sub-conta"
                            inputType="text"
                            name="sub_conta"
                            value={this.sub_conta}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                                <line x1="2" y1="10" x2="22" y2="10"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Coletivo"
                            inputType="text"
                            name="colectivo"
                            value={this.colectivo}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                                <circle cx="9" cy="7" r="4"/>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                              </svg>
                            }
                          />
                          <Input
                            title="Grupo"
                            inputType="text"
                            name="grupo_mat"
                            value={this.grupo_mat}
                            handleChange={this.handleChange}
                            icon={
                              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                                <circle cx="9" cy="7" r="4"/>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                              </svg>
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '16px',
                      marginTop: '32px',
                      padding: '24px',
                      backgroundColor: 'white',
                      borderRadius: '12px',
                      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                    }}>
                      <button
                        type="button"
                        onClick={() => window.location.href = "/vehicles"}
                        style={{
                          padding: '10px 20px',
                          borderRadius: '8px',
                          border: '1px solid #e2e8f0',
                          backgroundColor: 'white',
                          color: '#64748b',
                          fontSize: '0.875rem',
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          cursor: 'pointer',
                          transition: 'all 0.2s'
                        }}
                      >
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M19 12H5M12 19l-7-7 7-7"/>
                        </svg>
                        Voltar
                      </button>
                      <button
                        onClick={this.handleMatriculasSubmitSuccess}
                        disabled={this.isSubmitting}
                        style={{
                          padding: '10px 20px',
                          borderRadius: '8px',
                          border: 'none',
                          backgroundColor: '#3b82f6',
                          color: 'white',
                          fontSize: '0.875rem',
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          cursor: this.isSubmitting ? 'not-allowed' : 'pointer',
                          transition: 'all 0.2s',
                          opacity: this.isSubmitting ? 0.7 : 1
                        }}
                      >
                        {this.isSubmitting ? (
                          <>
                            <svg className="animate-spin" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                              <circle cx="12" cy="12" r="10" strokeDasharray="32" strokeDashoffset="32"/>
                            </svg>
                            Processando...
                          </>
                        ) : (
                          <>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                              <path d="M5 13l4 4L19 7"/>
                            </svg>
                            Registrar Matrícula
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
            </Modal>
            <Modal id="emailmatricula">
                <form class="well form-horizontal" action=" " method="post" id="contact_form">
                    <div ref={this.matriculaRef} style={{ padding: '50px' }}>
                        <Card nome={nome_1} nif={emailmatricula.matricula} card={`;${(emailmatricula.codigo || '')}`} fotografia={fotografia} />
                    </div>
                    <Button
                        action={this.handleEmailMatricula}
                        type={"success"}
                        title={(
                            <table
                                style={{ cursor: "pointer" }}
                            >
                                <tr>
                                    <td>
                                        <MdEmail
                                            style={{ fontSize: "20px", cursor: "pointer" }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: "xx-small" }}>
                                        Enviar cartão
                                    </td>
                                </tr>
                            </table>
                        )}
                        style={buttonStyle}
                    />
                    <Button
                        action={this.handleDownloadMatricula}
                        type={"success"}
                        title={(
                            <table
                                style={{ cursor: "pointer" }}
                            >
                                <tr>
                                    <td>
                                        <MdDownload
                                            style={{ fontSize: "20px", cursor: "pointer" }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: "xx-small" }}>
                                        Descarregar cartão
                                    </td>
                                </tr>
                            </table>
                        )}
                        style={buttonStyle}
                    />
                </form>
            </Modal>
        </div>
      
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
