import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { withCookies, Cookies } from 'react-cookie';

// Components
import ReactTable from '@components/ReactTable';
import Modal from '@components/Modal'
import Input from "@components/Input";
import Audit from "./audit";
import Print from './print'
import Menu from '@components/Menu'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import 'react-smarttab/dist/index.css'

import './tickets.css';

import { twoDecimal, numberWithCommas } from '@utils/format.js'

import UserContext from '@components/context/userContext';

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    static contextType = UserContext;

    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            table: {
                columns: [
                    {
                        Header: 'Sequencia',
                        accessor: 'idticket',
                    },
                    {
                      Header: 'Data',
                      accessor: 'created_at',
                    },
                    {
                      Header: 'Cliente',
                      accessor: 'customer',
                    },
                    {
                      Header: 'Colaborador',
                      accessor: 'employee',
                    },
                    {
                      Header: 'Valor',
                      accessor: 'value',
                    },
                    {
                      Header: 'Resgatar',
                      accessor: 'rescue',
                      hideFromPrint: true,
                    },
                ],
                rows: [],
                loading: true,
            },
            customers: {
              columns: [
                  {
                      Header: 'Nome',
                      accessor: 'name',
                  },
                  {
                    Header: 'Crédito',
                    accessor: 'credit',
                  },
                  {
                    Header: 'Débito',
                    accessor: 'debit',
                  },
                  {
                    Header: 'Total',
                    accessor: 'visualStatus',
                  }
              ],
              rows: [],
              loading: true,
            },
            total: 0,
            debit: 0,
            credit: 0,
            currency: '',
            loaded: false,
            search: '',
            searchCustomers: '',
            ticketText: '',
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.filterData = this.filterData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.filterCustomerData = this.filterCustomerData.bind(this);
        this.validateTicket = this.validateTicket.bind(this);
        this.askTicketValidation = this.askTicketValidation.bind(this);
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/tickets/totals.php?idempresa=${profile.idEmpresa}`)
              .then(response => {
                const totals = response.data !== 'NOK' ? response.data : {};
                const { debit, credit, total } = totals;

                this.setState({ debit, credit, total, loaded: true });
            })
            .catch(error => {
                NotificationManager.error(error.message, 'Senhas');
            });
            axios.get(`https://eposgestofinal.pt/api/tickets/get.php?idempresa=${profile.idEmpresa}&where=t.status is true`)
                .then(response => {
                    const tickets = response.data !== 'NOK' ? response.data : [];

                    this.setState({ table: { ...this.state.table, rows: tickets.map((ticket) => ({
                        ...ticket,
                        rescue: (<i
                            onClick={() => this.askTicketValidation(ticket.idticket)}
                            className={classNames({
                                'fa': true,
                                'fa-undo': true,
                            })}
                            style={{
                                cursor: 'pointer',
                            }}
                        ></i>),
                        visualStatus: ticket.status === 't' ? (<span className="badge badge-success">Disponível</span>) : (<span className="badge badge-danger">Resgatada</span>),
                    })), loading: false }, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Senhas');
                });
            axios.get(`https://eposgestofinal.pt/api/customers/tickets/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const customers = response.data !== 'NOK' ? response.data : [];

                    this.setState({ customers: { ...this.state.customers, rows: customers.map((customer) => ({
                      ...customer,
                      credit: numberWithCommas(twoDecimal(customer.credit)),
                      debit: numberWithCommas(twoDecimal(customer.debit)),
                      total: numberWithCommas(twoDecimal(customer.total)),
                      visualStatus: customer.total > 0 ?
                        (<span className="badge badge-success">{numberWithCommas(twoDecimal(customer.total))}</span>) :
                        customer.total < 0 ?
                          (<span className="badge badge-danger">{numberWithCommas(twoDecimal(customer.total))}</span>) :
                          (<span className="badge">{numberWithCommas(twoDecimal(customer.total))}</span>),
                  })), loading: false }, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Clientes');
                });
            axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const currency = response.data !== 'NOK' ? response.data[0].currency : '';

                    this.setState({ currency, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Moeda');
                });
        }
    }

    filterData(searchTerm) {
      this.setState({ search: searchTerm });
  }

askTicketValidation(idticket, callback) {
  confirmAlert({
    title: 'Confirmação',
    message: `Tem a certeza que deseja resgatar a senha ${idticket}?`,
    buttons: [
        {
            label: 'Sim',
            onClick: () => {
              this.validateTicket(idticket, callback)
            }
        },
        {
            label: 'Não',
            onClick: () => {}
        }
    ]
})
}

validateTicket(idticket, callback) {
  const profile = this.context;
  axios
    .post("https://eposgestofinal.pt/api/tickets/validate.php", {
      idempresa: profile.idEmpresa,
      idemployeeread: profile.idEmployee,
      idticket: idticket,
    })
    .then(() => {
      this.setState({ loaded: false });

      if (typeof callback === 'function') {
        callback();
      }
    })
    .catch((error) => {
      console.error(error);
      this.setState({ loaded: true });
    });
}

  filterCustomerData(searchTerm) {
    this.setState({ searchCustomers: searchTerm });
}

handleChange(e) {
  let value = e.target.value;
  let name = e.target.name;
  
  this.setState({
      [name]: value,
  });
}

    render () {
        const { location, history } = this.props;
        const { table, customers, total, debit, credit, currency, search, searchCustomers, ticketText } = this.state;
        const cookies = new URLSearchParams(location.search);

        const filterSearch = search.split(" ");
        const filterCustomerSearch = searchCustomers.split(" ");

        const tableRows = table.rows.length
            ? table.rows.filter((tickets) =>
                filterSearch.every(
                    (eachSearch) => {
                        return eachSearch === "" ||
                        (tickets.idticket && tickets.idticket.toLowerCase().includes(eachSearch.toLowerCase())) ||
                        (tickets.created_at && tickets.created_at.toLowerCase().includes(eachSearch.toLowerCase())) ||
                        (tickets.customer && tickets.customer.toLowerCase().includes(eachSearch.toLowerCase())) ||
                        (tickets.employee && tickets.employee.toLowerCase().includes(eachSearch.toLowerCase())) ||
                        (tickets.used_at && tickets.used_at.toLowerCase().includes(eachSearch.toLowerCase())) ||
                        (tickets.employeeread && tickets.employeeread.toLowerCase().includes(eachSearch.toLowerCase()))
                    }
                )
                )
            : [];

          const customersRows = customers.rows.length
            ? customers.rows.filter((tickets) =>
              filterCustomerSearch.every(
                    (eachSearch) => {
                        return eachSearch === "" ||
                        (tickets.name && tickets.name.toLowerCase().includes(eachSearch.toLowerCase()))
                    }
                )
                )
            : [];

        return (
          <div>
            <Menu
              loadFunction={this.loadFunction()}
              location={location}
              history={history}
              newCookies={cookies}
            />
            <div className="fullContainer" style={{ maxWidth: "100%" }}>
              <div className="col-xs-12">
                <ol
                  style={{
                    textAlign: "left",
                    marginTop: "75px",
                  }}
                  className="breadcrumb"
                >
                  <li>
                    <a href="http://eposgestofinal.pt/index.php">Portal</a>
                  </li>
                  <li className="active">Senhas</li>
                </ol>
              </div>
            </div>
            <div className="fullContainer" style={{ maxWidth: "100%" }}>
              <div className="col-sm-12" style={{ textAlign: "center" }}>
              <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15pt",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: '15px'
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Totais
                    </div>
                  </div>
                    <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                      <tbody>
                          <tr>
                              <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Débito<h4>{currency} {numberWithCommas(twoDecimal(debit))}</h4></td>
                              <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Crédito<h4>{currency} {numberWithCommas(twoDecimal(credit))}</h4></td>
                              <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                  Saldo Total<h4 style={{ color: total < 0 ? '#E03241' : '#1BA73F' }}>{currency} {numberWithCommas(twoDecimal(total))}</h4>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15pt",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: '15px'
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Pesquisa de senhas
                    </div>
                  </div>
                    <table style={{ width: '200px', marginBottom: 0 }} className="table table-bordered-only-inside">
                      <tbody>
                          <tr>
                              <td style={{ width: '200px', fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'left' }}>
                                <Input
                                  inputType={"text"}
                                  name={"ticketText"}
                                  value={ticketText}
                                  placeholder="Digite o código"
                                  handleChange={this.handleChange}
                                />
                                <button style={buttonStyle} type="button" className="btn btn-success" onClick={() => eventBus.$emit("Modal-customers-audittickets", { header: "Auditoria", body: <Audit idticket={ticketText} askTicketValidation={this.askTicketValidation} /> })}>Verificar</button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15pt",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: '15px'
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Senhas por resgatar
                    </div>
                  </div>
                    <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                      <tbody>
                          <tr>
                              <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                  {total > 0 ? (
                                      <Print
                                          table={ { ...table, rows: tableRows } }
                                          total={ total }
                                          debit={ debit }
                                          credit={ credit }
                                          currency={ currency }
                                      />
                                  ) : null}
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <hr style={{ marginTop: 0, borderColor: '#DDDDDD' }} />
                  <table className="table table-bordered table-hover table-sortable">
                      <thead>
                      <tr>
                          <th className="text-center">Pesquisa</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td style={{ width: "250px" }}>
                          <Input
                              inputType={"text"}
                              name={"search"}
                              value={search}
                              placeholder="pesquisa..."
                              handleChange={(e) => this.filterData(e.target.value)}
                          />
                          </td>
                      </tr>
                      </tbody>
                  </table>
                  <ReactTable
                      columns={ table.columns }
                      sortable={ true }
                      loading={ table.loading }
                      data={ tableRows }
                  />
                </div>
                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15pt",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: '15px'
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Clientes
                    </div>
                  </div>
                  <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                      <tbody>
                          <tr>
                              <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                  {total > 0 ? (
                                      <Print
                                          customers={ { ...customers, rows: customersRows } }
                                          total={ total }
                                          debit={ debit }
                                          credit={ credit }
                                          currency={ currency }
                                      />
                                  ) : null}
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <hr style={{ marginTop: 0, borderColor: '#DDDDDD' }} />
                  <table className="table table-bordered table-hover table-sortable">
                      <thead>
                      <tr>
                          <th className="text-center">Pesquisa</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td style={{ width: "250px" }}>
                          <Input
                              inputType={"text"}
                              name={"searchCustomers"}
                              value={searchCustomers}
                              placeholder="pesquisa..."
                              handleChange={(e) => this.filterCustomerData(e.target.value)}
                          />
                          </td>
                      </tr>
                      </tbody>
                  </table>
                  <ReactTable
                      columns={ customers.columns }
                      sortable={ true }
                      loading={ customers.loading }
                      data={ customersRows }
                  />
                </div>
              </div>
            </div>
            <Modal id="customers-audittickets" />
          </div>
        );
    }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Page);