import React, { Component } from "react";

// Components
import { TimestampToDate } from "@utils/date.js";
import { twoDecimal } from "@utils/format";

import "./table.css";

class Page extends Component {
  componentDidMount() {
    if (typeof this.props.handleDidMount == "function") {
      this.props.handleDidMount();
    }
  }

  render() {
    const { table, documentstotal, documentsdebit, documentscredit, date, posto, tipoDocumento, search, nifDocumento } = this.props;

    return (
      <table style={{ width: "100%" }}>
        <tr>
          <th colSpan="8">
            <table style={{ width: "100%", borderBottom: '1px solid black' }}>
              <tr>
                {nifDocumento.value != '' && (<th>NIF</th>)}
                <th>Data Inicial</th>
                <th>Data Final</th>
                <th>Posto</th>
                <th>Tipo de Documento</th>
                <th>Pesquisa</th>
              </tr>
              <tr>
                {nifDocumento.value != '' && (<td>{nifDocumento.label}</td>)}
                <td>{TimestampToDate(date[0] / 1000).split(" ")[0]}</td>
                <td>{TimestampToDate(date[1] / 1000).split(" ")[0]}</td>
                <td>{posto.label}</td>
                <td>{tipoDocumento.label}</td>
                <td>{search}</td>
              </tr>
            </table>
          </th>
        </tr>
        <tr>
          <th colSpan="5">&nbsp;</th>
          <th style={{ border: "1px solid black", borderBottom: 0 }}>Débito</th>
          <th style={{ border: "1px solid black", borderBottom: 0 }}>Crédito</th>
          <th style={{ border: "1px solid black", borderBottom: 0 }}>Total</th>
        </tr>
        <tr>
          <td colSpan="5">&nbsp;</td>
          <td style={{ border: "1px solid black", borderTop: 0 }}>
            {twoDecimal(documentsdebit) || "0.00"}
          </td>
          <td style={{ border: "1px solid black", borderTop: 0 }}>
            {twoDecimal(documentscredit) || "0.00"}
          </td>
          <td style={{ border: "1px solid black", borderTop: 0 }}>
            {twoDecimal(documentstotal) || "0.00"}
          </td>
        </tr>
        <tr>
          {table.columns.map(({ Header }, index) => (
            <th>{Header}</th>
          ))}
        </tr>
        {table.rows.map((row) => (
          <tr>
            <td>{row.source}</td>
            <td>{row.posto}</td>
            <td>{row.serie}</td>
            <td>{row.tpv}</td>
            <td>{row.pagamento}</td>
            <td>{row.numero}</td>
            <td>{twoDecimal(row.total) || "0.00"}</td>
            <td>{TimestampToDate(row.date_time)}</td>
          </tr>
        ))}
      </table>
    );
  }
}

export default Page;
