import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdAddCircleOutline } from "react-icons/md";

// Components
import Menu from "@components/Menu";
import VerticalTabs from "@components/VerticalTabs/.";

import { threeDecimal } from "@utils/format.js";

import "./list.css";

import UserContext from "@components/context/userContext";

const formatThreeDecimal = (value) => parseFloat(threeDecimal(value));

const getColor = (status) => {
  switch (status) {
    case "verylow":
      return "bg-danger";
    case "low":
      return "bg-warning";
    default:
      return "bg-success";
  }
};

const translateSondagem = (
  sondagem,
  qtd,
  qtdmensal,
  qtdmensalant,
  qtdmensal2ant
) => {
  if (
    parseFloat(qtdmensal) === 0 &&
    parseFloat(qtdmensalant) === 0 &&
    parseFloat(qtdmensal2ant) === 0
  ) {
    return "Sem dados";
  } else if (parseFloat(qtd) === 0) {
    return "Sem stock";
  } else if (sondagem < 0) {
    return "Sem stock";
  } else if (sondagem === 0) {
    return "menos de um dia";
  } else if (sondagem === 1) {
    return `${sondagem} dia`;
  }
  return `${sondagem} dias`;
};

const calcSondagem = (qtd, qtdmensal, qtdmensalant, qtdmensal2ant, loss) => {
  const d = new Date();
  const dayNumber = d.getDate();
  d.setMonth(d.getMonth() - 1);
  const previousDayNumber = new Date(
    d.getFullYear(),
    d.getMonth() + 1,
    0
  ).getDate();
  d.setMonth(d.getMonth() - 1);
  const previous2DayNumber = new Date(
    d.getFullYear(),
    d.getMonth() + 1,
    0
  ).getDate();
  let media = "-";

  media = formatThreeDecimal(
    (parseFloat(qtdmensal) +
      parseFloat(qtdmensalant) +
      parseFloat(qtdmensal2ant)) /
      (dayNumber + previousDayNumber + previous2DayNumber)
  );
  const valor = Math.floor((qtd - loss) / media);
  return [media, valor];
};

class Page extends Component {
  static contextType = UserContext;
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const estimates = [];
    const warehouses = [];

    this.state = {
      loaded: false,
      estimates,
      warehouses,
      showloss: false,
    };
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      axios
        .get(
          `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const warehouses = response.data !== "NOK" ? response.data : [];

          this.setState({ warehouses, loaded: true });

          axios
            .get(
              `https://eposgestofinal.pt/api/estimates/get.php?idempresa=${profile.idEmpresa}`
            )
            .then((response) => {
              const estimates = response.data !== "NOK" ? response.data : [];
              let estimateLogic = [];
              estimates.forEach((estimate) => {
                let currentProduct = estimateLogic.filter(
                  (product) => product.produto === estimate.produto
                );
                if (!currentProduct.length) {
                  estimateLogic.push({
                    produto: estimate.produto,
                    nome: estimate.nome,
                    posto: [],
                    qtd: [],
                    qtdmensal: [],
                    qtdmensalant: [],
                    qtdmensal2ant: [],
                    loss: [],
                    media: [],
                    sondagem: [],
                  });
                }
                estimateLogic = estimateLogic.map((product) => {
                  if (product.produto === estimate.produto) {
                    product.posto.push(estimate.posto);
                    product.qtd.push(estimate.qtd);
                    product.qtdmensal.push(estimate.qtdmensal);
                    product.qtdmensalant.push(estimate.qtdmensalant);
                    product.qtdmensal2ant.push(estimate.qtdmensal2ant);
                    product.loss.push(estimate.loss);
                    if (
                      !this.state.showloss &&
                      estimate.loss &&
                      parseFloat(estimate.loss) !== 0
                    ) {
                      this.setState({ showloss: true });
                    }
                    const sondagem = calcSondagem(
                      parseFloat(estimate.qtd),
                      parseFloat(estimate.qtdmensal),
                      parseFloat(estimate.qtdmensalant),
                      parseFloat(estimate.qtdmensal2ant),
                      parseFloat(estimate.loss)
                    );
                    product.media.push(sondagem[0]);
                    product.sondagem.push(sondagem[1]);
                  }
                  return product;
                });
              });

              this.setState({ estimates: estimateLogic, loaded: true });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async removeFunction(data) {
    const profile = this.context;

    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja apagar o produto ${data.nome}?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            axios
              .get(
                `https://eposgestofinal.pt/api/estimates/unset.php?idempresa=${profile.idEmpresa}&produto=${data.produto}`
              )
              .then((response) => {
                if (response.data !== "NOK") {
                  this.setState({
                    estimates: this.state.estimates.filter(
                      (estimate) => estimate.produto !== data.produto
                    ),
                  });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    const { estimates, warehouses, showloss } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Sondagens</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Sondagens
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/estimates/new")}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Criar sondagem</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div>
                <VerticalTabs
                  tabs={[
                    {
                      label: "Todos Postos",
                      component: (
                        <div>
                          <table
                            style={{
                              width: "100%",
                              marginBottom: "0px",
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderBottom: "1px solid #ddd",
                              borderRight: "1px solid #ddd",
                              borderLeft: "1px solid #ddd",
                              cursor: "pointer",
                            }}
                            className="table"
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#ddd",
                                    textAlign: "center",
                                  }}
                                >
                                  Produto
                                </th>
                                <th
                                  colspan="7"
                                  style={{
                                    backgroundColor: "#ddd",
                                    textAlign: "center",
                                  }}
                                ></th>
                              </tr>
                            </thead>
                            <tbody>
                              {estimates.length > 0 &&
                                estimates.map((estimate, estimateIndex) => {
                                  return (
                                    <tr key={estimateIndex}>
                                      <td
                                        onClick={() =>
                                          history.push(
                                            `/estimates/${estimate.produto}`
                                          )
                                        }
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        {`[${estimate.produto}] ${estimate.nome}`}
                                      </td>
                                      <td colspan="7">
                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th className="text-center">Posto</th>
                                              <th className="text-center">
                                                qtd/sondagem
                                              </th>
                                              {showloss ? (
                                                <th className="text-center">
                                                  qtd n/pescável
                                                </th>
                                              ) : null}
                                              <th className="text-center">
                                                Vendas mês atual
                                              </th>
                                              <th className="text-center">
                                                Vendas último mês
                                              </th>
                                              <th className="text-center">
                                                Vendas penúltimo mês
                                              </th>
                                              <th className="text-center">
                                                Média diária
                                              </th>
                                              <th className="text-center">
                                                Estimativa
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {estimate.posto.map(
                                              (posto, estIndex) => {
                                                const status =
                                                  (estimate.sondagem[estIndex] <
                                                    5 &&
                                                    "verylow") ||
                                                  (estimate.sondagem[estIndex] <
                                                    5 &&
                                                    "low") ||
                                                  "normal";
                                                const currentPosto =
                                                  warehouses.find(
                                                    (warehouse) =>
                                                      warehouse.posto === posto
                                                  ) || { nome: posto };
                                                return (
                                                  <tr
                                                    className={getColor(status)}
                                                  >
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {currentPosto.nome}
                                                      </p>
                                                    </td>
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {formatThreeDecimal(
                                                          estimate.qtd[estIndex]
                                                        )}
                                                      </p>
                                                    </td>
                                                    {showloss ? (
                                                      <td
                                                        onClick={() =>
                                                          history.push(
                                                            `/estimates/${estimate.produto}`
                                                          )
                                                        }
                                                        style={{
                                                          verticalAlign:
                                                            "middle",
                                                        }}
                                                      >
                                                        <p key={estIndex}>
                                                          {formatThreeDecimal(
                                                            estimate.loss[
                                                              estIndex
                                                            ]
                                                          )}
                                                        </p>
                                                      </td>
                                                    ) : null}
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {formatThreeDecimal(
                                                          estimate.qtdmensal[
                                                            estIndex
                                                          ]
                                                        )}
                                                      </p>
                                                    </td>
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {formatThreeDecimal(
                                                          estimate.qtdmensalant[
                                                            estIndex
                                                          ]
                                                        )}
                                                      </p>
                                                    </td>
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {formatThreeDecimal(
                                                          estimate
                                                            .qtdmensal2ant[
                                                            estIndex
                                                          ]
                                                        )}
                                                      </p>
                                                    </td>
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {
                                                          estimate.media[
                                                            estIndex
                                                          ]
                                                        }
                                                      </p>
                                                    </td>
                                                    <td
                                                      onClick={() =>
                                                        history.push(
                                                          `/estimates/${estimate.produto}`
                                                        )
                                                      }
                                                      style={{
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <p key={estIndex}>
                                                        {translateSondagem(
                                                          estimate.sondagem[
                                                            estIndex
                                                          ],
                                                          estimate.qtd[
                                                            estIndex
                                                          ],
                                                          estimate.qtdmensal[
                                                            estIndex
                                                          ],
                                                          estimate.qtdmensalant[
                                                            estIndex
                                                          ],
                                                          estimate
                                                            .qtdmensal2ant[
                                                            estIndex
                                                          ]
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      ),
                    },
                    ...warehouses.map((warehouse) => ({
                      label: warehouse.nome || warehouse.posto,
                      component:
                        estimates
                          .map((estimate) => {
                            const posPosto = estimate.posto.findIndex(
                              (posto) => posto === warehouse.posto
                            );
                            return posPosto !== -1
                              ? {
                                  ...estimate,
                                  posto: [estimate.posto[posPosto]],
                                  qtd: [estimate.qtd[posPosto]],
                                  qtdmensal: [estimate.qtdmensal[posPosto]],
                                  qtdmensalant: [
                                    estimate.qtdmensalant[posPosto],
                                  ],
                                  qtdmensal2ant: [
                                    estimate.qtdmensal2ant[posPosto],
                                  ],
                                  loss: [estimate.loss[posPosto]],
                                  media: [estimate.media[posPosto]],
                                  sondagem: [estimate.sondagem[posPosto]],
                                }
                              : null;
                          })
                          .filter((estimate) => estimate !== null).length >
                        0 ? (
                          <div>
                            <table
                              style={{
                                width: "100%",
                                marginBottom: "0px",
                                fontSize: "10px",
                                fontWeight: "bold",
                                borderBottom: "1px solid #ddd",
                                borderRight: "1px solid #ddd",
                                borderLeft: "1px solid #ddd",
                                cursor: "pointer",
                              }}
                              className="table"
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    Produto
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    qtd/sondagem
                                  </th>
                                  {showloss ? (
                                    <th
                                      style={{
                                        backgroundColor: "#ddd",
                                        textAlign: "center",
                                      }}
                                    >
                                      qtd n/pescável
                                    </th>
                                  ) : null}
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    Vendas mês atual
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    Vendas mês anterior
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    Vendas penúltimo mês
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    Média diária
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#ddd",
                                      textAlign: "center",
                                    }}
                                  >
                                    Estimativa
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {estimates
                                  .map((estimate) => {
                                    const posPosto = estimate.posto.findIndex(
                                      (posto) => posto === warehouse.posto
                                    );
                                    return posPosto !== -1
                                      ? {
                                          ...estimate,
                                          posto: [estimate.posto[posPosto]],
                                          qtd: [estimate.qtd[posPosto]],
                                          qtdmensal: [
                                            estimate.qtdmensal[posPosto],
                                          ],
                                          qtdmensalant: [
                                            estimate.qtdmensalant[posPosto],
                                          ],
                                          qtdmensal2ant: [
                                            estimate.qtdmensal2ant[posPosto],
                                          ],
                                          loss: [estimate.loss[posPosto]],
                                          media: [estimate.media[posPosto]],
                                          sondagem: [
                                            estimate.sondagem[posPosto],
                                          ],
                                        }
                                      : null;
                                  })
                                  .filter((estimate) => estimate !== null)
                                  .map((estimate, estimateIndex) =>
                                    estimate.posto.map((posto, estIndex) => {
                                      const status =
                                        (estimate.sondagem[estIndex] < 5 &&
                                          "verylow") ||
                                        (estimate.sondagem[estIndex] < 5 &&
                                          "low") ||
                                        "normal";
                                      return (
                                        <tr className={getColor(status)}>
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            {`[${estimate.produto}] ${estimate.nome}`}
                                          </td>
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <p key={estIndex}>
                                              {formatThreeDecimal(
                                                estimate.qtd[estIndex]
                                              )}
                                            </p>
                                          </td>
                                          {showloss ? (
                                            <td
                                              onClick={() =>
                                                history.push(
                                                  `/estimates/${estimate.produto}`
                                                )
                                              }
                                              style={{
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <p key={estIndex}>
                                                {formatThreeDecimal(
                                                  estimate.loss[estIndex]
                                                )}
                                              </p>
                                            </td>
                                          ) : null}
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <p key={estIndex}>
                                              {formatThreeDecimal(
                                                estimate.qtdmensal[estIndex]
                                              )}
                                            </p>
                                          </td>
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <p key={estIndex}>
                                              {formatThreeDecimal(
                                                estimate.qtdmensalant[estIndex]
                                              )}
                                            </p>
                                          </td>
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <p key={estIndex}>
                                              {formatThreeDecimal(
                                                estimate.qtdmensal2ant[estIndex]
                                              )}
                                            </p>
                                          </td>
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <p key={estIndex}>
                                              {estimate.media[estIndex]}
                                            </p>
                                          </td>
                                          <td
                                            onClick={() =>
                                              history.push(
                                                `/estimates/${estimate.produto}`
                                              )
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <p key={estIndex}>
                                              {translateSondagem(
                                                estimate.sondagem[estIndex],
                                                estimate.qtd[estIndex],
                                                estimate.qtdmensal[estIndex],
                                                estimate.qtdmensalant[estIndex],
                                                estimate.qtdmensal2ant[estIndex]
                                              )}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          "Sem dados."
                        ),
                    })),
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
