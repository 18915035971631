import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Button from "@components/Button";
import Checkbox from "@components/CheckBox";
import AutoComplete from "@components/AutoComplete";
import { MdClear, MdAddCircleOutline, MdVisibility } from "react-icons/md";

import Photos from "./services/photos";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ReactTable from "@components/ReactTable";

import { threeDecimal, valueWithIva } from '@utils/format.js'

import { TimestampToDate } from '@utils/date'

import './form.css';

import UserContext from '@components/context/userContext';

const extraZero = (value) => (value < 10 ? `0${value}` : value);

const TimestampToDatetime = (timestamp) => {
    if (!timestamp) {
        return '';
    }
    const datum = new Date(timestamp * 1000);
    const year = datum.getFullYear();
    const month = extraZero(datum.getMonth() + 1);
    const day = extraZero(datum.getDate());
    return `${year}-${month}-${day}`;
  };

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params }, type } = this.props;

    const searchHousehold = "";

    this.state = {
        loaded: false,
        name: '',
        email: '',
        nif: '',
        phone: '',
        address: '',
        birthday: '',
        quota: '',
        idassociado: '',
        kinship: '',
        healthSystem: '',
        number_ss: '',
        numero_utente: '',
        gdpr: false,
        idcustomer: type === 'edit' && params.idcustomer,
        edit: type === 'edit',
        dataChanged: -1,
        services: [],
        kinships: [],
        healthSystems: [],
        showPopup: {},
        tableHousehold: {
            columns: [
              {
                Header: "Nome",
                accessor: "name",
              },
              {
                Header: "Parentesco",
                accessor: "kinship",
              },
              {
                Header: "Telefone",
                accessor: "phone",
              },
              {
                Header: "Email",
                accessor: "email",
              },
              {
                Header: "Aniversário",
                accessor: "birthday",
              },
              {
                Header: "GDPR",
                accessor: "gdpr",
              },
              {
                Header: "",
                accessor: "view",
              },
            ],
            rows: [],
            loading: true,
        },
        tableQuotas: {
            columns: [
                {
                    Header: "Data Pagamento",
                    accessor: "date",
                },
              {
                Header: "Ano",
                accessor: "year",
              },
              {
                Header: "Mês",
                accessor: "month",
              },
              {
                Header: "Valor Quota",
                accessor: "value",
              },
              {
                Header: "recbio nr.",
                accessor: "invoice",
              },
              {
                Header: "",
                accessor: "view",
              },
            ],
            rows: [],
            loading: true,
        },
        searchHousehold,
        quotaTypes: [{
            value: 0,
            label: 'Nenhuma'
        },{
            value: 3,
            label: 'Mensal'
        },{
            value: 2,
            label: 'Trimestral'
        },{
            value: 5,
            label: 'Quadrimestral'
        },{
            value: 4,
            label: 'Semestral'
        },{
            value: 1,
            label: 'Anual'
        }]
    };

    this.handleEmailSend = this.handleEmailSend.bind(this);
    this.handleTableSubmit = this.handleTableSubmit.bind(this);
    this.handleComissionUpdate = this.handleComissionUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.showPopupHandler = this.showPopupHandler.bind(this);
    this.hidePopupHandler = this.hidePopupHandler.bind(this);
    this.showCameraHandler = this.showCameraHandler.bind(this);
    this.showUploadHandler = this.showUploadHandler.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.removeFunction = this.removeFunction.bind(this);
  }

loadFunction() {
    const profile = this.context;
    const { history } = this.props;

    if (!this.state.loaded) {
        axios
            .get(
            `https://eposgestofinal.pt/api/kinship/get.php`
            )
            .then((response) => {
                const kinship = response.data !== "NOK" ? response.data : [];
                this.setState({
                    kinships: [
                        {
                            label: 'Não definido',
                            value: undefined,
                        },
                        ...kinship.map((eachKinship) => ({
                            ...kinship,
                            label: eachKinship.name,
                            value: eachKinship.idkinship,
                        }))
                    ],
                    loaded: true,
                }, () => {
                    axios
                        .get(
                        `https://eposgestofinal.pt/api/health_systems/get.php`
                        )
                        .then((response) => {
                            const healthSystems = response.data !== "NOK" ? response.data : [];
                            this.setState({
                                healthSystems: [
                                    {
                                        label: 'Nenhum',
                                        value: undefined,
                                    },
                                    ...healthSystems.map((healthSystem) => ({
                                        ...healthSystem,
                                        label: healthSystem.name,
                                        value: healthSystem.idhealthsystem,
                                    }))
                                ],
                                loaded: true,
                            }, () => {
                                if (this.props.type === 'edit') {
                                    axios.get(`https://eposgestofinal.pt/api/customers/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}`)
                                    .then(response => {
                                        const customers = response.data !== 'NOK' ? response.data[0] : [];
                        
                                        this.setState({
                                            ...customers,
                                            healthSystem: this.state.healthSystems.find((healthSystem) => healthSystem.value === customers.health_system) || {
                                                label: 'Nenhum',
                                                value: undefined,
                                            },
                                            quota: this.state.quotaTypes.find((quotatype) => quotatype.value === parseInt((customers.quota || 0), 10)),
                                            gdpr: customers.gdpr === 't',
                                            loaded: true });
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                                    
                                    axios.get(`https://eposgestofinal.pt/api/customers/services/get.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}`)
                                    .then(response => {
                                        const services = response.data !== 'NOK' ? response.data : [];
                        
                                        this.setState({ services, loaded: true });
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                                    this.setState({ loaded: true, dataChanged: 0 });
                        
                                    axios.get(`https://eposgestofinal.pt/api/customers/household/get.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}`)
                                    .then(response => {
                                        const households = response.data !== "NOK" ? response.data : [];
                        
                                        console.log(this.state.kinships, households);

                                        const enrichedHouseholds = households.map((household) => ({
                                            ...household,
                                            kinship: this.state.kinships.find((kinship) => kinship.value === household.kinship) ? this.state.kinships.find((kinship) => kinship.value === household.kinship).label : 'Não definido',
                                            gdpr: household.gdpr === "t" ? "TRUE" : "FALSE",
                                            view: (
                                            <MdVisibility
                                                style={{ fontSize: "15px", cursor: "pointer" }}
                                                onClick={() =>
                                                history.push(`/customers/${this.state.idcustomer}/household/${household.idhousehold}`)
                                                }
                                            />
                                            ),
                                        }));
                        
                                        this.setState({
                                            tableHousehold: {
                                                ...this.state.tableHousehold,
                                                rows: enrichedHouseholds,
                                                loading: false,
                                            },
                                            loaded: true,
                                        });
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                        
                                    axios.get(`https://eposgestofinal.pt/api/customers/quotas/get.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}`)
                                    .then(response => {
                                        const quotas = response.data !== "NOK" ? response.data : [];
                        
                                        const enrichedQuotas = quotas.map((quota) => ({
                                            ...quota,
                                            date: TimestampToDatetime(quota.date),
                                            view: (
                                            <MdVisibility
                                                style={{ fontSize: "15px", cursor: "pointer" }}
                                                onClick={() =>
                                                history.push(`/customers/${this.state.idcustomer}/quotas/${quota.idquota}`)
                                                }
                                            />
                                            ),
                                        }));
                        
                                        this.setState({
                                            tableQuotas: {
                                                ...this.state.tableQuotas,
                                                rows: enrichedQuotas,
                                                loading: false,
                                            },
                                            loaded: true,
                                        });
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                                } else {
                                    this.setState({ loaded: true });
                                }
                            });
                        });
                });
            });
    }
  }

  handleComissionUpdate = (idproduct, comissionInfo) => {
    const { data } = this.state;
    const newData = data.map(row => {
      if (row.idproduct === idproduct) {
        const comission = comissionInfo.comission || row.comission;
        return {
          ...row,
          ...comissionInfo,
          finalprice: threeDecimal(parseFloat(row.costprice || 0) + parseFloat(comission || 0)),
          finalprice_iva: threeDecimal(valueWithIva(parseFloat(row.costprice || 0) + parseFloat(comission || 0), row.iva)),
        };
      }
      return row;
    });
    this.setState({ data: newData, dataChanged: 1 });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    }, () => {
        if (name === 'quota') {
            this.handleFormSubmit(null, true);
        }
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
        [name]: value,
    });
  };

  handleTableSubmit(e) {
    const profile = this.context;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/customers/services/set.php', {
        services: this.state.data.filter((product) => product.comission),
        idempresa: profile.idEmpresa,
        idcustomer: this.state.idcustomer,
    })
    .then(response => {
        this.setState({ dataChanged: 0 });
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleEmailSend(e) {
    const profile = this.context;

    e.preventDefault();
    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja enviar estes dados por email para o cliente ${this.state.name} <${this.state.email}>?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                    const { history } = this.props;
                    
                    axios.get(`https://eposgestofinal.pt/api/customers/services/sendEmail.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}`)
                    .then(response => {
                        if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
                            window.close();
                        } else {
                            history.push('/customers');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {}
            }
        ]
    });
  }

  handleFormSubmit(e, quota) {
    const profile = this.context;
    const { history } = this.props;
    if (e) {
        e.preventDefault();
    }
    
    axios.post('https://eposgestofinal.pt/api/customers/set.php', {
        name: this.state.name,
        nif: this.state.nif,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        birthday: this.state.birthday,
        gdpr: `${this.state.gdpr}`,
        idempresa: profile.idEmpresa,
        idcustomer: this.state.idcustomer,
        idassociado: this.state.idassociado,
        quota: this.state.quota.value,
        healthsystem: this.state.healthSystem.value,
        number_ss: this.state.number_ss,
        numero_utente: this.state.numero_utente,
    })
    .then(response => {
        if (quota) {
            return;
        }
        if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
            window.close();
        } else {
            history.push('/customers');
        }
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
        window.close();
    } else {
        history.push('/customers');
    }
  }

  showPopupHandler(idempresa, idcustomer, idservice) {
    this.setState({ showPopup: { idempresa, idcustomer, idservice, photo: false, upload: false } });
  }

  hidePopupHandler() {
    this.setState({ showPopup: {} });
  }

  showCameraHandler() {
    this.setState({ showPopup: { ...this.state.showPopup, photo: true, upload: false } });
  }

  showUploadHandler() {
    this.setState({ showPopup: { ...this.state.showPopup, photo: false, upload: true } });
  }

  filterData(searchTerm) {
    this.setState({ searchHousehold: searchTerm });
  }

  async removeFunction(idservice) {
    const profile = this.context;

    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja apagar o serviço?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                    axios.get(`https://eposgestofinal.pt/api/customers/services/unset.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}&idservice=${idservice}`)
                    .then(response => {
                        if (response.data !== 'NOK') {
                            this.setState({ services: this.state.services.filter(service => service.idservice !== idservice) });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {}
            }
        ]
    });
}

  render() {
    const { location, history } = this.props;
    const {
        edit,
        idcustomer,
        loaded,
        name,
        email,
        phone,
        nif,
        address,
        birthday,
        gdpr,
        dataChanged,
        services,
        showPopup,
        tableHousehold,
        tableQuotas,
        searchHousehold,
        quotaTypes,
        quota,
        idassociado,
        healthSystems,
        healthSystem,
        number_ss,
        numero_utente,
    } = this.state;
    const cookies = new URLSearchParams(location.search);
    const profile = this.context;

    const filterSearchHousehold = searchHousehold.split(" ");

    const tableHouseholdRows = tableHousehold.rows.length
      ? tableHousehold.rows.filter((customer) =>
        filterSearchHousehold.every(
            (eachSearch) =>
              eachSearch === "" ||
              (customer.name && customer.name.toLowerCase().includes(eachSearch.toLowerCase())) ||
              (customer.idagregado	 && customer.idagregado	.toLowerCase().includes(eachSearch.toLowerCase()))
          )
        )
      : [];

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/customers">Clientes</a></li>
                        <li className="active">
                            {edit ? (<span>{idcustomer}</span   >) : (<span>Novo Cliente</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            <Tabs>
                <TabList>
                    <Tab>Detalhes</Tab>
                    {dataChanged !== -1 && (
                    <>
                        <Tab>Quotas</Tab>
                        <Tab>Agregado</Tab>
                        <Tab>Serviços</Tab>
                    </>
                    )}
                </TabList>

                <TabPanel>
                    {loaded && (
                    <div
                        className="fullContainer" style={{ maxWidth: '100%' }}
                    >
                        <div
                            className="col-sm-12"
                            style={{ textAlign: 'center' }}
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15pt',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '15px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Detalhes
                                    </div>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        width: '100%',
                                    }}
                                    className="container-fluid"
                                >
                                    <form class="well form-horizontal" action=" " method="post" id="contact_form">
                                        <div class="st st-theme-github st-horizontal">
                                            <fieldset>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Nome</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-user"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"name"}
                                                                value={name}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">NIF</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-id-card"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"nif"}
                                                                value={nif}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">E-Mail</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-envelope"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"email"}
                                                                value={email}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Telefone</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"phone"}
                                                                value={phone}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Data de nascimento</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-birthday-cake"></i></span>
                                                            <Input
                                                                inputType={"date"}
                                                                name={"birthday"}
                                                                value={birthday}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Morada</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-home"></i></span>
                                                            <TextArea
                                                                rows={5}
                                                                value={address}
                                                                name={"address"}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">ID Associado</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-handshake"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"idassociado"}
                                                                value={idassociado}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Tipo de Quota</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-money-bill"></i></span>
                                                            <AutoComplete
                                                                name={"quota"}
                                                                value={quota}
                                                                handleChange={this.handleChange}
                                                                options={quotaTypes}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Subsistema de Saúde:</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-heartbeat"></i></span>
                                                            <AutoComplete
                                                                name={"healthSystem"}
                                                                value={healthSystem}
                                                                handleChange={this.handleChange}
                                                                options={healthSystems}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Número SS:</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-id-card"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"number_ss"}
                                                                value={number_ss}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Número Utente SNS:</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-id-card"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"numero_utente"}
                                                                value={numero_utente}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">RGPD</label>
                                                    <div className="col-md-11">
                                                        <Checkbox
                                                            name={"gdpr"}
                                                            checked={gdpr}
                                                            handleChange={this.handleCheckboxChange}
                                                        />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </form>
                                    {/* About you */}
                                    <Button
                                    action={this.handleFormSubmit}
                                    type={"primary"}
                                    title={"Submeter"}
                                    style={buttonStyle}
                                    />
                                    {/*Submit */}
                                    <Button
                                    action={this.handleFormCancel}
                                    type={"secondary"}
                                    title={"cancelar"}
                                    style={buttonStyle}
                                    />
                                    {/* Clear the form */}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </TabPanel>
                {dataChanged !== -1 && (
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Tipo de Quota
                                </div>
                            </div>
                            <AutoComplete
                                name={"quota"}
                                value={quota}
                                handleChange={this.handleChange}
                                options={quotaTypes}
                            />
                            </div>
                        </div>
                    </div>
                    {quota.value !== 0 && (<div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Quotas
                                </div>
                                <div>
                                <table
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/customers/${idcustomer}/quotas/new`)}
                                >
                                    <tr>
                                    <td>
                                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{ fontSize: "xx-small" }}>Adicionar quota</td>
                                    </tr>
                                </table>
                                </div>
                            </div>
                            <ReactTable
                                columns={tableQuotas.columns}
                                sortable={true}
                                loading={tableQuotas.loading}
                                data={tableQuotas.rows}
                            />
                            </div>
                        </div>
                    </div>)}
                </TabPanel>
                )}
                {dataChanged !== -1 && (
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: '15px'
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Agregado
                                </div>
                                <div>
                                <table
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/customers/${idcustomer}/household/new`)}
                                >
                                    <tr>
                                    <td>
                                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{ fontSize: "xx-small" }}>Adicionar membro</td>
                                    </tr>
                                </table>
                                </div>
                            </div>

                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                <tr>
                                    <th className="text-center">Pesquisa</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{ width: "250px" }}>
                                    <Input
                                        inputType={"text"}
                                        name={"search"}
                                        value={searchHousehold}
                                        placeholder="pesquisa..."
                                        handleChange={(e) => this.filterData(e.target.value)}
                                    />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <ReactTable
                                columns={tableHousehold.columns}
                                sortable={true}
                                loading={tableHousehold.loading}
                                data={tableHouseholdRows}
                            />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                )}
                {dataChanged !== -1 && (
                <TabPanel>
                <div
                        className="fullContainer" style={{ maxWidth: '100%' }}
                    >
                        <div
                            className="col-sm-12"
                            style={{ textAlign: 'center' }}
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15pt',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '15px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Serviços
                                    </div>
                                    <div>
                                        <MdAddCircleOutline style={{ fontSize: '20px', cursor: 'pointer' }} onClick={ () => history.push(`/customers/${idcustomer}/services/new`) } />
                                    </div>
                                </div>
                                {loaded && (
                                <div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Data</th>
                                                <th>Tipo de serviço</th>
                                                <th>Produtos utilizados</th>
                                                <th>Tempo de Pose (minutos)</th>
                                                <th>Nuances aplicadas</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {services.length ? services.map(row => (
                                            <TableRowEmails
                                            key={row.id}
                                            data={row}
                                            history={history}
                                            idempresa={profile.idEmpresa}
                                            idcustomer={idcustomer}
                                            showPopup={showPopup}
                                            showPopupHandler={this.showPopupHandler}
                                            removeFunction={this.removeFunction}
                                        />
                                        )) : (
                                            <tr>
                                                <td colSpan="5">Sem resultados</td> 
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    {showPopup.idservice && (
                                        <Photos
                                            showPopup={showPopup}
                                            hidePopupHandler={this.hidePopupHandler}
                                            showCameraHandler={this.showCameraHandler}
                                            showUploadHandler={this.showUploadHandler} />
                                    )}
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                )}
            </Tabs>
        </div>
      
    );
  }
}

class TableRowEmails extends Component {
  render() {
    const {
      data: { idservice, date_time, type, products, time, method },
      history,
      idcustomer,
      idempresa,
      showPopupHandler,
      removeFunction,
    } = this.props;

    return (
      <tr>
        <td onClick={ () => history.push(`/customers/${idcustomer}/services/${idservice}`) }>{TimestampToDate(date_time).split(" ")[0]}</td>
        <td onClick={ () => history.push(`/customers/${idcustomer}/services/${idservice}`) }>{type}</td>
        <td onClick={ () => history.push(`/customers/${idcustomer}/services/${idservice}`) }>
            <ul
                style={{
                    textAlign: 'left',
                }}
            >
                {JSON.parse(products).map(row => (
                    <li>{row.label || row}</li>
                ))}
            </ul>
        </td>
        <td onClick={ () => history.push(`/customers/${idcustomer}/services/${idservice}`) }>{time}</td>
        <td onClick={ () => history.push(`/customers/${idcustomer}/services/${idservice}`) }>{method}</td>
        <td>
            {/* <button
                onClick={ () => history.push(`/customers/${idcustomer}/services/${idservice}/photos`) }
            >
                <i className="fas fa-camera fa-lg"></i>
            </button> */}
            <button
                onClick={ () => showPopupHandler(idempresa, idcustomer, idservice) }
            >
                <i className="fas fa-images fa-lg"></i>
            </button>
        </td>
        <td>
            <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => removeFunction(idservice) } />
        </td>
      </tr>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
