import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
    transform: 'translate(14px, 16px) scale(1)'
  },
  '& .MuiOutlinedInput-root': {
    padding: '4px'
  },
  '& .MuiAutocomplete-option': {
    fontSize: '14px',
    '& > span': {
      marginRight: 10,
      fontSize: '18px'
    }
  }
}));

const AutoCompleteField = React.forwardRef(({
  options,
  value,
  onChange,
  label,
  getOptionLabel,
  disabled,
  error,
  helperText,
  required,
  searchableFields = ['nome'], // Default searchable field is 'label'
}, ref) => {

  const filterOptions = (options, state) => {
    const query = state.inputValue.toLowerCase();

    return query.length > 0 ? options.filter((option) => {
      return searchableFields.some(field => {
        // If the field exists on the option object, we check if it matches the query
        if (option[field]) {
          return option[field].toString().toLowerCase().includes(query);
        }
        return false;
      });
    }) : options;
  };

  return (
    <StyledAutocomplete
      options={options}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          label={label}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
      disabled={disabled}
      autoComplete
      openOnFocus
      getOptionKey={(option) => {
        if (option?.idcustomer) return `customer-${option.idcustomer}`;
        if (option?.idproduct) return `product-${option.idproduct}`;
        if (option?.posto) return `posto-${option.posto}`;
        if (option?.value) return `payment-${option.value}`;
        return JSON.stringify(option);
      }}
    />
  );
});

AutoCompleteField.displayName = 'AutoCompleteField';

export default AutoCompleteField;
