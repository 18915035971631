import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import { MdCalendarToday } from 'react-icons/md';
import Vencimento from './vencimento';
import { formatDate } from './dateUtils';
import Input from '@components/Input';

const InputWrapper = styled('div')({
  position: 'relative',
  width: '100%',
});

const IconWrapper = styled('div')({
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  color: 'black',
});

const VencimentoInput = ({ value, onChange, label = "Data de vencimento" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (newDate) => {
    onChange(newDate);
    handleClose();
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current && 
        !inputRef.current.contains(event.target) && 
        popoverRef.current && 
        !popoverRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <InputWrapper ref={inputRef}>
      <Input
        inputType="text"
        value={value ? formatDate(value) : ''}
        readOnly
        onClick={handleClick}
        handleChange={() => {}}
        style={{ backgroundColor: 'white' }}
      />
      <IconWrapper>
        <MdCalendarToday size={10} />
      </IconWrapper>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(event) => {
          if (popoverRef.current && popoverRef.current.contains(event.target)) {
            return;
          }
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          ref: popoverRef,
          sx: {
            mt: 1,
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          },
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Vencimento
          value={value}
          onChange={handleDateChange}
        />
      </Popover>
    </InputWrapper>
  );
};

export default VencimentoInput;