import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

const PDFViewer = forwardRef((props, ref) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const iframeRef = useRef(null);
  const [pdfObjectUrl, setPdfObjectUrl] = useState(null);

  useEffect(() => {
    import('./PdfPrint.css').then(() => {});
  }, []);

  const loadPDF = async () => {
    try {
      const response = await fetch(props.pdfUrl, {
        mode: 'cors',
        headers: {
          'Accept': 'application/pdf'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setPdfObjectUrl(url);
      return url;
    } catch (error) {
      console.error('Error loading PDF:', error);
      alert('Failed to load PDF. Please try again.');
      throw error;
    }
  };

  const handlePrint = async () => {
    try {
      setIsPrinting(true);
      const url = await loadPDF();
      
      if (iframeRef.current) {
        iframeRef.current.onload = () => {
          try {
            if (iframeRef.current?.contentWindow) {
              iframeRef.current.contentWindow.focus();
              iframeRef.current.contentWindow.print();
            }
          } catch (error) {
            console.error('Error printing:', error);
            alert('Failed to print. Please try again.');
          } finally {
            setIsPrinting(false);
          }
        };
        iframeRef.current.src = url;
      }
    } catch (error) {
      console.error('Error in handlePrint:', error);
      setIsPrinting(false);
    }
  };

  const showPreview = async () => {
    try {
      const url = await loadPDF();
      if (iframeRef.current) {
        iframeRef.current.src = url;
        setIsPreviewVisible(true);
      }
    } catch (error) {
      console.error('Error in showPreview:', error);
      setIsPreviewVisible(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handlePrint,
    showPreview
  }));

  React.useEffect(() => {
    return () => {
      if (pdfObjectUrl) {
        URL.revokeObjectURL(pdfObjectUrl);
      }
    };
  }, [pdfObjectUrl]);

  return (
    <>
      <iframe
        ref={iframeRef}
        style={{ display: 'none' }}
        title="Print Frame"
      />

      {isPreviewVisible && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h2 className="modal-title">Pré-vizualizar</h2>
              <button
                className="close-button"
                onClick={() => {
                  setIsPreviewVisible(false);
                  if (pdfObjectUrl) {
                    URL.revokeObjectURL(pdfObjectUrl);
                    setPdfObjectUrl(null);
                  }
                }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div className="modal-content">
              <iframe
                className="pdf-iframe"
                src={pdfObjectUrl || ''}
                title="PDF Preview"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default PDFViewer;