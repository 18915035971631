import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import Input from "@components/Input";
import Modal from "@components/Modal";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdAddCircleOutline, MdVisibility } from "react-icons/md";

// Components
import Menu from "@components/Menu";
import ReactTable from "@components/ReactTable";

import UserContext from "@components/context/userContext";

import New from "./new";

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const customers = [{}];
    const search = "";

    this.state = {
      loaded: false,
      customers,
      table: {
        columns: [
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Morada",
            accessor: "address",
          },
          {
            Header: "Telefone",
            accessor: "phone",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Aniversário",
            accessor: "birthday",
          },
          {
            Header: "GDPR",
            accessor: "gdpr",
          },
          {
            Header: "",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      search,
    };
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      const { history } = this.props;

      axios
        .get(
          `https://eposgestofinal.pt/api/customers/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const customers = response.data !== "NOK" ? response.data : [];

          const enrichedCustomers = customers.map((customer) => ({
            ...customer,
            gdpr: customer.gdpr === "t" ? "TRUE" : "FALSE",
            view: (
              <MdVisibility
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() =>
                  history.push(`/customers/${customer.idcustomer}`)
                }
              />
            ),
          }));

          this.setState({
            table: {
              ...this.state.table,
              rows: enrichedCustomers,
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async removeFunction(data) {
    const profile = this.context;

    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja apagar o cliente ${data.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            axios
              .get(
                `https://eposgestofinal.pt/api/customers/unset.php?idempresa=${profile.idEmpresa}&idcustomer=${data.idcustomer}`
              )
              .then((response) => {
                if (response.data !== "NOK") {
                  this.setState({
                    customers: this.state.customers.filter(
                      (client) => client.idcustomer !== data.idcustomer
                    ),
                  });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  filterData(searchTerm) {
    this.setState({ search: searchTerm });
  }

  render() {
    const { table, search } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

    const oneOfAll = (customer, search) => {
      const keys = Object.keys(customer);
      return keys.some((key) => `${customer[key] || ''}`.toLowerCase().includes(search.toLowerCase()))
    }

    const tableRows = table.rows.length
      ? table.rows.filter((customer) =>
          filterSearch.every(
            (eachSearch) =>
              eachSearch === "" ||
              oneOfAll(customer, eachSearch)
          )
        )
      : [];

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Clientes</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Clientes
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => eventBus.$emit("Modal-newcustomer", { header: "Criar cliente", body: <New /> })}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Criar cliente</td>
                    </tr>
                  </table>
                </div>
              </div>

              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Pesquisa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "250px" }}>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        placeholder="pesquisa..."
                        handleChange={(e) => this.filterData(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={tableRows}
              />
            </div>
          </div>
        </div>
        <Modal id="newcustomer" />
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
