import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import ReactTable from "@components/ReactTable";
import { MdEuro, MdAddCircleOutline } from "react-icons/md";
import { FaBoxes, FaEdit, FaClipboardList } from "react-icons/fa";

import { twoDecimal, threeDecimal, valueWithIva, valueWithoutIva } from "@utils/format.js";

import UserContext from "@components/context/userContext";

import "./list.css";

const avoidDuplication = (first, second, field) => {
  if (first === second) {
    switch (field) {
      case 'iva':
        return first;
      case 'profitper':
          return twoDecimal(first);
      default:
        return threeDecimal(first);
    }
  }
  switch (field) {
    case 'iva':
      return `${first} - ${second}`;
    case 'profit':
    case 'profitper':
      return `${twoDecimal(first)} - ${twoDecimal(second)}`;
    default:
      return `${threeDecimal(first)} - ${threeDecimal(second)}`;
  }
}

const valueToShow = (
  minValues = {},
  maxValues = {},
  field,
  withIva,
  showIva
) => {
  if (field !== 'iva' && (parseFloat(minValues[field]) === 0 && parseFloat(maxValues[field]) === 0)) {
    return "";
  } else if (
    minValues[field] &&
    maxValues[field]
  ) {
    if (showIva) {
      if (withIva) {
        return avoidDuplication(minValues[field], maxValues[field], field);
      }
      return avoidDuplication(valueWithIva(minValues[field], minValues.iva), valueWithIva(maxValues[field], maxValues.iva), field);
    }
    if (withIva) {
      return avoidDuplication(valueWithoutIva(minValues[field], minValues.iva), valueWithoutIva(maxValues[field], maxValues.iva), field);
    }
    return avoidDuplication(minValues[field], maxValues[field], field);
  } else if (['profit', 'profitper'].includes(field)) {
    const minPrice = valueWithoutIva(minValues['price'], minValues.iva);
    const maxPrice = valueWithoutIva(maxValues['price'], maxValues.iva);
    const minCostPrice = minValues['costprice'];
    const maxCostPrice = maxValues['costprice'];
    if (field === 'profitper') {
      const minProfitPer = minCostPrice > 0 ? ((minPrice * 100 / minCostPrice) - 100) : 100;
      const maxProfitPer = maxCostPrice > 0 ? ((maxPrice * 100 / maxCostPrice) - 100) : 100;
      return avoidDuplication(minProfitPer, maxProfitPer, field);
    }
    const minProfit = minPrice - minCostPrice;
    const maxProfit = maxPrice - maxCostPrice;
    return avoidDuplication(minProfit, maxProfit, field);
  } else {
    return field !== 'iva' ? threeDecimal(minValues[field]) : minValues[field];
  }
};

const importantFlags = (flags) => {
  const result = {};

  const priority = {
    "ALERT": 2,  // Higher value means higher priority
    "WARN": 1
  };

  flags.forEach(flag => {
      const { key, value } = flag;
      if (!result[key] || (priority[value] > priority[result[key]])) {
          result[key] = value;
      }
  });

  return result;
}

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      canLoadProducts: true,
      loaded: false,
      synced: false,
      table: {
        columns: [
          {
            Header: "Produto",
            accessor: "name",
          },
          {
            Header: "P.C. S/IVA",
            accessor: "costprice",
          },
          {
            Header: "P.C. C/IVA",
            accessor: "costprice_iva",
          },
          {
            Header: "PVP s/IVA",
            accessor: "price",
          },
          {
            Header: "PVP c/IVA",
            accessor: "price_iva",
          },
          {
            Header: "Margem s/IVA",
            accessor: "profit",
          },
          {
            Header: "Margem (%)",
            accessor: "profitper",
          },
          {
            Header: "IVA (%)",
            accessor: "iva",
          },
          {
            Header: "Familia",
            accessor: "category",
          },
          {
            Header: 'Estado',
            id: 'inativo',
            accessor: d => {
              return (
                <table style={{width: '100%'}}>
                  <tr>
                    <td>
                      {d.inativo === 't' ? (
                        <span className="badge badge-danger">INATIVO</span>
                      ) : (
                        <span className="badge badge-success">ATIVO</span>
                      )}
                    </td>
                    <td>
                      {Object.keys(d.flags).map((key) => {
                        if (key === 'LOW_STOCK') {
                          return d.flags[key].value === 'WARN' ? (
                            <span className="badge badge-warning">Stock baixo</span>
                          ) : (
                            <span className="badge badge-danger">Stock muito baixo</span>
                          )
                        } else {
                          return null;
                        }
                      })}
                    </td>
                  </tr>
                </table>
              );
            },
          },
          {
            Header: "",
            accessor: "edit",
          },
        ],
        rows: [],
        loading: true,
      },
      search: JSON.parse(localStorage.getItem("products.search")) || "",
      idcategory: JSON.parse(localStorage.getItem("products.idcategory")) || {
        value: "",
        label: "TODAS",
      },
      status: JSON.parse(localStorage.getItem("products.status")) || {
        value: "f",
        label: "ATIVOS",
      },
      allStatus: [
        { value: "", label: "TODOS" },
        { value: "f", label: "ATIVOS" },
        { value: "t", label: "INATIVOS" },
      ]
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.uploadFunction = this.uploadFunction.bind(this);
  }

  getProducts() {
    if (this.state.canLoadProducts) {
      this.setState({ canLoadProducts: false });
      const profile = this.context;

      const { history } = this.props;
      let whereFamilia = ''
      if (this.state.idcategory && this.state.idcategory.value !== '') {
          whereFamilia = `&familia=${this.state.idcategory.value}`
      }
      axios
        .get(
          `https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresaSec}${whereFamilia}`
        )
        .then((response) => {
          const products = response.data !== "NOK" ? response.data : [];

          const enrichedProducts = products.map((product) => ({
            ...product,
            inativo: product.inativo === 't' ? 't' : 'f',
            name: `[${product.idproduct}] ${product.nome}`,
            flags: importantFlags(product.flags),
            costprice: valueToShow(
              product.minValues,
              product.maxValues,
              "costprice",
              false,
              false
            ),
            costprice_iva: valueToShow(
              product.minValues,
              product.maxValues,
              "costprice",
              false,
              true
            ),
            profit: valueToShow(
              product.minValues,
              product.maxValues,
              "profit"
            ),
            profitper: valueToShow(
              product.minValues,
              product.maxValues,
              "profitper"
            ),
            price: valueToShow(
              product.minValues,
              product.maxValues,
              "price",
              true,
              false
            ),
            price_iva: valueToShow(
              product.minValues,
              product.maxValues,
              "price",
              true,
              true
            ),
            iva: valueToShow(
              product.minValues,
              product.maxValues,
              "iva",
              false
            ),
            category: `[${product.idcategoria}] ${product.familia}`,
            edit: (
              <table className="actionButtons" style={{ width: '100%', fontSize: 'xx-small' }}>
                <tr style={{ lineHeight: '10px' }}>
                  {product.composed == 't' ? (<td
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/products/${encodeURIComponent(product.idproduct)}/composition`)}
                  >
                    <FaClipboardList/>
                    <p>F. Tec.</p>
                  </td>) : null}
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/products/${encodeURIComponent(product.idproduct)}/stocks`)}
                  >
                    <FaBoxes/>
                    <p>stock</p>
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/products/${encodeURIComponent(product.idproduct)}/prices`)}
                  >
                    <MdEuro/>
                    <p>preços</p>
                  </td>
                  <td
                    style={{ fontWeight: 'bolder', fontSize: 'x-small', cursor: "pointer" }}
                    onClick={() => history.push(`/products/${encodeURIComponent(product.idproduct)}`)}
                  >
                    <FaEdit/>
                    <p>editar</p>
                  </td>
                </tr>
              </table>
            ),
          }));

          this.setState({
            table: {
              ...this.state.table,
              rows: enrichedProducts,
              loading: false,
            },
            loaded: true,
            canLoadProducts: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      axios
        .get(
          `https://eposgestofinal.pt/api/apollo/sync/get.php?idempresa=${profile.idEmpresaSec}`
        )
        .then((response) => {
          const synced = response.data !== "NOK" ? response.data : [];

          this.setState({ synced: !!synced.warehouses });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresaSec}`
        )
        .then((response) => {
          const categories = response.data !== "NOK" ? response.data : [];

          this.setState({
            categories: [
              { value: "", label: "TODAS" },
              ...categories.map((category) => ({
                value: category.idcategoria,
                label: `[${category.idcategoria}] ${category.descricaocategoria}`,
              })),
            ],
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });

      this.getProducts();
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    }, () => {
        switch (name) {
            case "idcategory":
                localStorage.setItem("products.idcategory", JSON.stringify(value));
                this.getProducts();
                break;
            case "search":
            case "status":
                localStorage.setItem(`products.${name}`, JSON.stringify(value));
                break;
            default:
                break;
            }
    });
  }

  async removeFunction(data) {
    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja apagar o produto ${data.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            const profile = this.context;

            axios
              .get(
                `https://eposgestofinal.pt/api/products/unset.php?idempresa=${profile.idEmpresaSec}&idproduct=${data.idproduct}`
              )
              .then((response) => {
                if (response.data !== "NOK") {
                  this.setState({
                    products: this.state.products.filter(
                      (product) => product.idproduct !== data.idproduct
                    ),
                  });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  async uploadFunction() {
    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja enviar todos os produtos para o Apollo?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            const profile = this.context;
            axios
              .get(
                `https://eposgestofinal.pt/api/products/export.php?idempresa=${profile.idEmpresaSec}`
              )
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    const { synced, table, idcategory, categories, search, status, allStatus } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Produtos</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Produtos
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/products/pricetags")}
                  >
                    <tr>
                      <td>
                        <i className="fas fa-tags"></i>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>
                        Etiquetas
                      </td>
                    </tr>
                  </table>
                </div>
                <div>
                  {synced && (
                    <table
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/products/sync")}
                    >
                      <tr>
                        <td>
                          <i className="fas fa-gas-pump"></i>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "xx-small" }}>
                          Enviar Combustiveis
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={this.uploadFunction}
                  >
                    <tr>
                      <td>
                        <i className="fas fa-upload"></i>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>
                        Enviar Produtos
                      </td>
                    </tr>
                  </table>
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/products/new")}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>
                        Adicionar Produto
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Família</th>
                    <th className="text-center">Pesquisa</th>
                    <th className="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "400px" }}>
                      <AutoComplete
                        name={"idcategory"}
                        value={idcategory}
                        handleChange={this.handleChange}
                        options={categories}
                      />
                    </td>
                    <td>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"status"}
                        value={status} 
                        handleChange={this.handleChange}
                        options={allStatus}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={table.rows
                  .filter((product) =>
                    idcategory.value
                      ? product.idcategoria === idcategory.value
                      : true
                  )
                  .filter((product) =>
                    filterSearch.every(
                      (eachSearch) =>
                        product.name
                          .toLowerCase()
                          .includes(eachSearch.toLowerCase()) ||
                        product.barcodes
                          .toLowerCase()
                          .includes(eachSearch.toLowerCase()) ||
                        product.idproduct
                          .toLowerCase()
                          .includes(eachSearch.toLowerCase())
                    )
                  )
                  .filter((product) =>
                    status.value !== ''
                      ? product.inativo === status.value
                      : true
                  )
                  .map((product) => ({
                    ...product,
                    name: <div style={{ textAlign: "left" }}>{product.name}</div>,
                    costprice: product.costprice,
                    costprice_iva: product.costprice_iva,
                    price: product.price,
                    price_iva: product.price_iva,
                    iva: product.iva,
                    category: <div style={{ textAlign: "left" }}>{product.category}</div>,
                    edit: product.edit,
                  }))}
              />
              {/* <MDBDataTable
                                data={ { ...table, rows: table.rows
                                    .filter((product) => idcategory.value ? product.idcategoria === idcategory.value : true)
                                    .filter((product) => product.name.toLowerCase().includes(search.toLowerCase()) || product.idproduct.toLowerCase().includes(search.toLowerCase()))
                                    .map((product) => ({
                                        name: product.name,
                                        costprice: product.costprice,
                                        costprice_iva: product.costprice_iva,
                                        price: product.price,
                                        price_iva: product.price_iva,
                                        iva: product.iva,
                                        category: product.category,
                                        edit: product.edit,
                                    }))
                                } }
                                striped
                                bordered
                                searching={false}
                            /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
