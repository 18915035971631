import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { MdAddCircleOutline, MdClear, MdRefresh, MdPreview } from "react-icons/md";
import AutoCompleteField from "@components/AutoCompleteV2";
import Input from "@components/Input";
import Switch from "@components/Switch";
import Menu from "@components/Menu";
import PDFPrint from "@components/PdfPrint";
import Alert from "@mui/material/Alert";
import TextField from "@components/TextField";
import Vencimento from '@components/Vencimento';
import UserContext from "@components/context/userContext";

import { currentDateTime, dateToTimestamp } from '@utils/date'

function App() {
  const profile = useContext(UserContext);
  const history = useHistory();

  const defaultCustomer = {
    idcustomer: "CF",
    name: "Cliente Final",
    code: "CF",
  };

  const [customers, setCustomers] = useState([defaultCustomer]);
  const [products, setProducts] = useState([]);
  const [series, setSeries] = useState({
    FT: '',
    FR: '',
  });
  const [recurrence, setRecurrence] = useState({
    isRecurring: false,
    frequency: 'monthly',
    interval: 1,
    startDate: new Date().toISOString().split('T')[0],
    withEndDate: false,
    endDate: ''
  });
  const [postos, setPostos] = useState([]);
  const [taxa_ivas, settaxa_ivas] = useState([]);
  const [isps, setIsps] = useState([]);
  const [redIsp, setRedIsp] = useState([]);
  const [sc, setSc] = useState([]);
  const [error, setError] = useState(null);
  const [previewId] = useState(crypto.randomUUID());
  const [previewUrl, setPreviewUrl] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    lines: {},
    customer: false,
    date: false,
    data_vencimento: false,
    paymentMethod: false,
  });

  const latestProductRef = useRef(null);
  const latestPostoRef = useRef(null);
  const printerRef = useRef(null);

  const payments = [
    { value: 1, label: "Dinheiro" },
    { value: 2, label: "Crédito" },
    { value: 3, label: "Cheque" },
    { value: 4, label: "TPA" },
    { value: 8, label: "Frota" },
    { value: 14, label: "Serviços Internos" },
  ];

  const frequences = [
    { value: 'daily', label: "Diária" },
    { value: 'weekly', label: "Semanal" },
    { value: 'monthly', label: "Mensal" },
    { value: 'yearly', label: "Anual" },
  ];

  const emptyLine = {
    produto: "",
    postoId: 0,
    designacao: "",
    quantidade: 1,
    preco_sem_iva: 0,
    desconto_per: 0,
    valor_desconto: 0,
    taxa_iva: 0,
    subtotal: 0,
    taxAmount: 0,
    total: 0,
  };

  const [invoice, setInvoice] = useState({
    customerId: defaultCustomer.idcustomer,
    date: currentDateTime(),
    data_vencimento: new Date(),
    paymentMethod: 1,
    serie: '',
    lines: [],
    subtotal: 0,
    totalDiscount: 0,
    totalTax: 0,
    total: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customersRes, productsRes, postosRes, taxa_ivasRes, ispRes, redIspRes, scRes, seriesFT, seriesFR] =
          await Promise.all([
            axios.get(
              `https://eposgestofinal.pt/api/customers/get.php?idempresa=${profile.idEmpresa}`
            ),
            axios.get(
              `https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}`
            ),
            axios.get(
              `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`
            ),
            axios.get("https://eposgestofinal.pt/api/tax/get.php"),
            axios.get(`https://eposgestofinal.pt/api/isp/get.php?idempresa=${profile.idEmpresa}`),
            axios.get(`https://eposgestofinal.pt/api/reducao_isp/get.php?idempresa=${profile.idEmpresa}`),
            axios.get(`https://eposgestofinal.pt/api/sobrecusto_biocombustivel/get.php?idempresa=${profile.idEmpresa}`),
            axios.get(
              `https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresa}&doctype=FT_epos`
            ),
            axios.get(
                `https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresa}&doctype=FR_epos`
            )
          ]);

          addLine(true);

        const allCustomers = [defaultCustomer, ...customersRes.data];
        setCustomers(allCustomers);

        const filteredProducts = productsRes.data.filter(
          (product) => product.idproduct !== ""
        );
        setProducts(filteredProducts);

        const filteredPostos = postosRes.data;
        setPostos(filteredPostos);

        settaxa_ivas(taxa_ivasRes.data);

        setIsps(ispRes.data);

        setRedIsp(redIspRes.data);

        setSc(scRes.data);

        setSeries({
          FT: seriesFT.data[0]?.sequence,
          FR: seriesFR.data[0]?.sequence,
        })
      } catch (err) {
        setError("Erro ao carregar dados. Por favor, tente novamente.");
      }
    };

    fetchData();
  }, [profile.idEmpresa]);

  const addLine = (first = false) => {
    if (!invoice.customerId) {
      setValidationErrors((prev) => ({ ...prev, customer: true }));
      return;
    }

    const previousLine = invoice.lines[invoice.lines.length - 1];
    const previousPosto = previousLine?.postoId || 0;

    setInvoice((prev) => ({
      ...prev,
      lines: [...prev.lines, { ...emptyLine, postoId: previousPosto }],
    }));
    setError(null);

    if (!first) {
      setTimeout(() => latestProductRef.current?.focus(), 100);
    } else {
      setTimeout(() => latestPostoRef.current?.focus(), 100);
    }
  };

  const removeLine = (index) => {
    setInvoice((prev) => {
      const newLines = prev.lines.filter((_, i) => i !== index);
      return calculateTotals(prev, newLines);
    });
  };

  const calculateTotals = (prevInvoice, lines) => {
    const totals = lines.reduce(
      (acc, line) => {
        const subtotal = parseFloat(line.quantidade) * parseFloat(line.preco_sem_iva);
        const valor_desconto = parseFloat(line.valor_desconto) || 0;
        const afterDiscount = subtotal - valor_desconto;
        const taxAmount = afterDiscount * (parseFloat(line.taxa_iva) / 100);

        return {
          subtotal: acc.subtotal + subtotal,
          totalDiscount: acc.totalDiscount + valor_desconto,
          totalTax: acc.totalTax + taxAmount,
          total: acc.total + afterDiscount + taxAmount,
        };
      },
      { subtotal: 0, totalDiscount: 0, totalTax: 0, total: 0 }
    );

    return {
      ...prevInvoice,
      lines,
      ...totals,
    };
  };

  const validateLine = (line, index) => {
    const errors = {};

    if (!line.produto) {
      errors[`product-${index}`] = "Selecione um produto";
    }

    if (line.quantidade <= 0) {
      errors[`quantidade-${index}`] = "A quantidade deve ser maior que zero";
    }

    const subtotal = line.quantidade * line.preco_sem_iva;
    if (line.valor_desconto > subtotal) {
      errors[`discount-${index}`] =
        "O desconto não pode ser maior que o subtotal";
    }

    return errors;
  };

  const updateLine = (index, updates) => {
    setInvoice((prev) => {
      const newLines = [...prev.lines];
      const line = { ...newLines[index], ...updates };

      const quantidade = line.quantidade;
      const preco_sem_iva = line.preco_sem_iva;
      const subtotal = quantidade * preco_sem_iva;

      if (updates.hasOwnProperty("valor_desconto")) {
        const valor_desconto = Math.min(
          Math.max(updates.valor_desconto, 0),
          subtotal
        );
        const desconto_per =
          subtotal > 0 ? (valor_desconto / subtotal) * 100 : 0;
        line.valor_desconto = valor_desconto;
        line.desconto_per = parseFloat(desconto_per).toFixed(2);
      } else if (updates.hasOwnProperty("desconto_per")) {
        const desconto_per = Math.min(
          Math.max(updates.desconto_per, 0),
          100
        );
        const valor_desconto = (subtotal * desconto_per) / 100;
        line.desconto_per = desconto_per;
        line.valor_desconto = parseFloat(valor_desconto).toFixed(3);
      }

      const afterDiscount = subtotal - (line.valor_desconto || 0);
      const taxAmount = afterDiscount * (line.taxa_iva / 100);
      const total = subtotal;

      const lineErrors = validateLine(line, index);
      if (Object.keys(lineErrors).length > 0) {
        setValidationErrors((prev) => ({
          ...prev,
          lines: { ...prev.lines, ...lineErrors },
        }));
        return prev;
      }

      newLines[index] = {
        ...line,
        subtotal,
        taxAmount,
        total,
      };

      setValidationErrors((prev) => ({
        ...prev,
        lines: Object.keys(prev.lines).reduce((acc, key) => {
          if (!key.includes(`-${index}`)) {
            acc[key] = prev.lines[key];
          }
          return acc;
        }, {}),
      }));

      return calculateTotals(prev, newLines);
    });
  };

  const handleProductChange = async (index, _, product) => {
    if (!product) {
      setValidationErrors((prev) => ({
        ...prev,
        lines: {
          ...prev.lines,
          [`product-${index}`]: "Selecione um produto válido",
        },
      }));
      return;
    }

    const produto = product.idproduct;
    try {
      const response = await axios.get(
        `https://eposgestofinal.pt/api/products/getById.php?idempresa=${profile.idEmpresa}&idproduct=${produto}`
      );

      const productDetail = response.data[0];
      const postoId = invoice.lines[index].postoId;
      const postoValue = productDetail.values.find(
        (v) => v.idposto === postoId
      );

      const price = postoValue
        ? Number(postoValue.price)
        : Number(productDetail.maxValues.price);
      const iva = postoValue
        ? Number(postoValue.iva)
        : Number(productDetail.maxValues.iva);

      const matchingtaxa_iva = taxa_ivas.find((t) => t.code === iva.toString());
      const taxa_iva = matchingtaxa_iva ? Number(matchingtaxa_iva.tax) : iva;

      const subtotal = invoice.lines[index].quantidade * price;
      const valor_desconto = (subtotal * invoice.lines[index].desconto_per) / 100;

      setValidationErrors((prev) => ({
        ...prev,
        lines: Object.keys(prev.lines).reduce((acc, key) => {
          if (!key.includes(`-${index}`)) {
            acc[key] = prev.lines[key];
          }
          return acc;
        }, {}),
      }));

      updateLine(index, {
        produto: productDetail.idproduct,
        designacao: productDetail.nome,
        isp: productDetail.isp,
        preco_sem_iva: price,
        taxa_iva: taxa_iva,
        valor_desconto: parseFloat(valor_desconto),
      });
    } catch (err) {
      console.error("Failed to fetch product details:", err);
      setValidationErrors((prev) => ({
        ...prev,
        lines: {
          ...prev.lines,
          [`product-${index}`]: "Erro ao carregar detalhes do produto",
        },
      }));
    }
  };

  const validateInvoice = () => {
    const errors = {
      customer: !invoice.customerId,
      date: !invoice.date,
      data_vencimento: !invoice.data_vencimento,
      paymentMethod: !invoice.paymentMethod,
      lines: {},
    };

    if (invoice.lines.length === 0) {
      return {
        ...errors,
        general: "Deve adicionar pelo menos um produto à fatura",
      };
    }

    invoice.lines.forEach((line, index) => {
      const lineErrors = validateLine(line, index);
      if (Object.keys(lineErrors).length > 0) {
        errors.lines = { ...errors.lines, ...lineErrors };
      }
    });

    return errors;
  };

  const handleSubmit = (e, preview = false) => {
    if (e) e.preventDefault();

    const errors = validateInvoice();
    setValidationErrors(errors);

    if (
      errors.general ||
      Object.keys(errors.lines).length > 0 ||
      errors.customer ||
      errors.date ||
      errors.data_vencimento ||
      errors.paymentMethod
    ) {
      return;
    }

    setError(null);

    const totalWithoutVAT = invoice.subtotal - invoice.totalDiscount;
    const totalDiscounts = invoice.lines.reduce(
      (acc, line) => acc + (parseFloat(line.valor_desconto) || 0),
      0
    );

    axios
      .post("https://eposgestofinal.pt/api/facturas/set.php", {
        doctype: "invoices",
        idempresa: profile.idEmpresa,
        idcustomer: invoice.customerId,
        date_time: dateToTimestamp(invoice.date),
        data_vencimento: invoice.data_vencimento.toLocaleString('pt-PT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }).slice(0, 10),
        payment: invoice.paymentMethod,
        desc: totalDiscounts,
        iva: invoice.totalTax,
        total: invoice.total,
        total_sem_iva: totalWithoutVAT,
        preview: preview ? previewId : null,
        linhas: invoice.lines.map((line) => ({
          idproduct: line.produto,
          idposto: line.postoId,
          quantidade: line.quantidade,
          price: line.preco_sem_iva,
          iva: line.taxa_iva,
          desc: line.valor_desconto || 0,
          desc_per: line.desconto_per || 0,
        })),
      })
      .then((response) => {
        if (!['NOK', 'OLD'].includes(response.data)) {
          if (preview) {
            const { type, serie, numero } = response.data;
            setPreviewUrl(`https://eposgestofinal.pt/api/facturas/pdf.php?idempresa=${profile.idEmpresa}&type=${type}&serie=${serie}&numero=${numero}&preview=${previewId}`);
            // eslint-disable-next-line no-unused-expressions
            printerRef.current?.showPreview();
          } else {
            setValidationErrors({
              lines: {},
              customer: false,
              date: false,
              data_vencimento: false,
              paymentMethod: false,
            });
            setError(null);
            const { type, numero, serie } = response.data;
            setTimeout(() => history.push(`/sales/${type}/${serie}/${numero}`), 1000);
          }
        } else if (response.data === 'OLD') {
          setValidationErrors((prev) => ({
            ...prev,
            general: "Já existe uma fatura com data posterior para esta série.",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            general: "Erro ao submeter fatura. Por favor, tente novamente.",
          }));
        }
      })
      .catch((err) => {
        setValidationErrors((prev) => ({
          ...prev,
          general: "Erro ao submeter fatura. Por favor, tente novamente.",
        }));
      });
  };

  const handlePrint = () => {
    const errors = validateInvoice();
    setValidationErrors(errors);

    if (
      errors.general ||
      Object.keys(errors.lines).length > 0 ||
      errors.customer ||
      errors.date ||
      errors.data_vencimento ||
      errors.paymentMethod
    ) {
      return;
    }

    setError(null);
    handleSubmit(null, true);
  };

  const getSelectedPaymentMethod = () => {
    return (
      payments.find((p) => p.value === invoice.paymentMethod) || payments[0]
    );
  };

  const getRecorringFrequency = () => {
    return (
      frequences.find((p) => p.value === recurrence.frequency) || frequences.find((p) => p.value === 'monthly')
    );
  };

  const canAddProduct = invoice.customerId;

  const isLastLineFilled = () => {
    if (invoice.lines.length === 0) return true;
    const lastLine = invoice.lines[invoice.lines.length - 1];
    return lastLine.produto !== "" && lastLine.quantidade > 0;
  };

  const canAddNewLine = canAddProduct && isLastLineFilled();

  const getSelectedCustomer = () => {
    return (
      customers.find((c) => c.idcustomer === invoice.customerId) ||
      defaultCustomer
    );
  };

  return (
    <div>
      <Menu />
      <div className="fullContainer" style={{ maxWidth: "100%" }}>
        <div className="col-xs-12">
          <ol
            style={{
              textAlign: "left",
              marginTop: "75px",
            }}
            className="breadcrumb"
          >
            <li>
              <a href="http://eposgestofinal.pt/index.php">Portal</a>
            </li>
            <li>
              <a href="/sales">Faturação</a>
            </li>
            <li className="active">
              <span>Novo Documento</span>
            </li>
          </ol>
        </div>
      </div>
      <div className="fullContainer" style={{ marginTop: "25px" }}>
        <div
          className="col-sm-12"
          style={{ textAlign: "center", fontSize: "10px" }}
        >
          <div className="panel panel-default">
            <div className="container-fluid">
              <div className="invoice-panel">
                <div className="header">
                  <h1 className="title">Nova Fatura</h1>
                  <div className="flex items-center space-x-4" style={{ display: 'flex', gap: '10px', color: 'rgb(107, 114, 128)', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <MdRefresh size={20} className="text-gray-500" />
                      <span style={{ fontSize: '12pt', fontWeight: 'bolder'}}>Fatura Recorrente</span>
                      <Switch
                        name={"recurrence|isRecurring"}
                        checked={recurrence.isRecurring}
                        handleChange={(e) => {
                          const newRecurring = e.target.value;                  
                          setRecurrence((prev) => {                            
                            return {
                              ...prev,
                              isRecurring: newRecurring,
                            }
                          });
                        }}
                      />
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={recurrence.isRecurring}
                        onChange={(e) => setRecurrence({...recurrence, isRecurring: e.target.checked})}
                      />
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  {validationErrors.general && (
                    <Alert severity="error" className="mb-4">
                      {validationErrors.general}
                    </Alert>
                  )}

                  <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '20px' }}>
                    {recurrence.isRecurring ? (<h1 className="title">Cabeçalho</h1>) : null}
                    <div className="form-grid" style={{ margin: 0 }}>
                      <div className="form-group">
                        <label className="form-label">Cliente</label>
                        <AutoCompleteField
                          options={customers.filter(({inactive}) => !(inactive == 't'))}
                          value={getSelectedCustomer()}
                          onChange={(_, customer) => {
                            setValidationErrors((prev) => ({
                              ...prev,
                              customer: false,
                            }));
                            setInvoice((prev) => ({
                              ...prev,
                              customerId: customer
                                ? customer.idcustomer
                                : defaultCustomer.idcustomer,
                            }));
                          }}
                          getOptionLabel={(option) => option.name || ""}
                          searchableFields={['name', 'nif', 'email', 'phone', 'mobile', 'fax']}
                          label="Selecione um cliente"
                          required
                          error={validationErrors.customer}
                          helperText={
                            validationErrors.customer
                              ? "Selecione um cliente"
                              : ""
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Método de Pagamento</label>
                        <AutoCompleteField
                          options={payments}
                          value={getSelectedPaymentMethod()}
                          onChange={(_, payment) => {
                            setValidationErrors((prev) => ({
                              ...prev,
                              paymentMethod: false,
                            }));
                            setInvoice((prev) => ({
                              ...prev,
                              paymentMethod: payment ? payment.value : 1,
                            }));
                          }}
                          getOptionLabel={(option) => option.label}
                          label="Selecione o método de pagamento"
                          required
                          error={validationErrors.paymentMethod}
                          helperText={
                            validationErrors.paymentMethod
                              ? "Selecione um método de pagamento"
                              : ""
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Série</label>
                        <TextField
                          type="text"
                          label="Série"
                          value={series[getSelectedPaymentMethod().value == 2 ? 'FT' : 'FR']}
                          readOnly
                        />
                      </div>

                      {!recurrence.isRecurring ? (
                        <>
                          <div className="form-group">
                            <label className="form-label">Vencimento</label>
                            <Vencimento
                              value={invoice.data_vencimento}
                              onChange={(newDate) => setInvoice((prev) => ({
                                ...prev,
                                data_vencimento: newDate,
                              }))}
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Data e Hora</label>
                            <Input
                              inputType="datetime-local"
                              name="date"
                              value={invoice.date}
                              handleChange={(e) => {
                                const newDate = e.target.value;
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  date: false,
                                }));                          
                                setInvoice((prev) => {
                                  console.log(newDate);
                                  
                                  return {
                                    ...prev,
                                    date: newDate,
                                  }
                                });
                              }}
                              className={validationErrors.date ? "error" : ""}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>

                    {recurrence.isRecurring ? (
                      <div style={{ marginTop: '20px' }}>
                        <h1 className="title">Recorrência</h1>
                        <div className="form-grid" style={{ margin: 0 }}>
                          <div className="form-group">
                            <label className="form-label">Frequência</label>
                            <AutoCompleteField
                              options={frequences}
                              value={getRecorringFrequency()}
                              onChange={(_, frequency) => {
                                setRecurrence((prev) => {                            
                                  return {
                                    ...prev,
                                    frequency: frequency.value,
                                  }
                                });
                              }}
                              getOptionLabel={(option) => option.label}
                              label="Selecione a recorrência"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Intervalo</label>
                            <TextField
                                type="number"
                                min="1"
                                label="Intervalo"
                                value={recurrence.interval}
                                onChange={(e) => {
                                  const newInterval = e.target.value;
                                  setRecurrence((prev) => {                            
                                    return {
                                      ...prev,
                                      interval: newInterval,
                                    }
                                  });
                                }}
                              />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Data Inicial</label>
                            <Input
                              inputType="date"
                              name="date"
                              value={recurrence.startDate}
                              handleChange={(e) => {
                                const newDate = e.target.value;
                                setRecurrence((prev) => {                            
                                  return {
                                    ...prev,
                                    startDate: newDate,
                                  }
                                });
                              }}
                              className={validationErrors.date ? "error" : ""}
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Com Data Final</label>
                            <Switch
                              name={"recurrence|withEndDate"}
                              checked={recurrence.withEndDate}
                              handleChange={(e) => {
                                const newwithEndDate = e.target.value;                  
                                setRecurrence((prev) => {                            
                                  return {
                                    ...prev,
                                    withEndDate: newwithEndDate,
                                  }
                                });
                              }}
                            />
                          </div>

                          {recurrence.withEndDate ? (
                            <div className="form-group">
                              <label className="form-label">Data Final</label>
                              <Input
                                inputType="date"
                                name="date"
                                value={recurrence.endDate}
                                handleChange={(e) => {
                                  const newDate = e.target.value;
                                  setRecurrence((prev) => {                            
                                    return {
                                      ...prev,
                                      endDate: newDate,
                                    }
                                  });
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="products-section">
                    <h2 className="products-title">Produtos</h2>

                    {invoice.lines.map((line, index) => (
                      <div key={index} className="product-grid">
                        <div className="product-field">
                          <AutoCompleteField
                            ref={index === invoice.lines.length - 1 ? latestPostoRef : null}
                            options={postos}
                            value={postos.find((p) => Number(p.posto) === Number(line.postoId))}
                            onChange={(_, posto) => {
                              if (posto) {
                                const postoId = Number(posto.posto);
                                setInvoice(prev => {
                                  const newLines = [...prev.lines];
                                  newLines[index] = {
                                    ...newLines[index],
                                    postoId
                                  };
                                  return {
                                    ...prev,
                                    lines: newLines
                                  };
                                });
                                
                                if (line.produto) {
                                  handleProductChange(index, null, products.find(p => p.idproduct === line.produto));
                                }
                                
                                setTimeout(() => index === invoice.lines.length - 1 && latestProductRef.current?.focus(), 100);
                              }
                            }}
                            getOptionLabel={(option) => option.nome || ""}
                            label="Selecione um posto"
                            error={Boolean(validationErrors.lines[`posto-${index}`])}
                            helperText={validationErrors.lines[`posto-${index}`]}
                          />
                        </div>

                        <div className="product-field">
                          <AutoCompleteField
                            ref={
                              index === invoice.lines.length - 1
                                ? latestProductRef
                                : null
                            }
                            options={products.filter(({inactive}) => !(inactive == 't'))}
                            searchableFields={['nome', 'barcodes', 'idproduct']}
                            value={
                              products.find(
                                (p) => p.idproduct === line.produto
                              ) || null
                            }
                            onChange={(_, product) => {
                              if (product) {
                                setInvoice(prev => {
                                  const newLines = [...prev.lines];
                                  newLines[index] = {
                                    ...newLines[index],
                                    produto: product.idproduct,
                                    designacao: product.nome
                                  };
                                  return {
                                    ...prev,
                                    lines: newLines
                                  };
                                });
                                handleProductChange(index, null, product);
                              }
                            }}
                            getOptionLabel={(option) => option.nome || ""}
                            label="Selecione um produto"
                            error={Boolean(
                              validationErrors.lines[`product-${index}`]
                            )}
                            helperText={
                              validationErrors.lines[`product-${index}`]
                            }
                          />
                        </div>

                        <div className="input-field" data-label="Quantidade">
                          <TextField
                            type="number"
                            min="1"
                            label="Quantidade"
                            value={line.quantidade}
                            onChange={(e) => {
                              updateLine(index, {
                                quantidade: Number(e.target.value),
                              });
                            }}
                            error={Boolean(
                              validationErrors.lines[`quantidade-${index}`]
                            )}
                          />
                        </div>

                        <div className="input-field" data-label="Preço">
                          <TextField
                            type="number"
                            min="0"
                            step="0.001"
                            label="Preço"
                            value={line.preco_sem_iva || 0}
                            onChange={(e) => {
                              updateLine(index, {
                                preco_sem_iva: Number(e.target.value),
                              });
                            }}
                          />
                        </div>

                        <div className="input-field" data-label="Desc %">
                          <TextField
                            type="number"
                            min="0"
                            max="100"
                            step="0.01"
                            label="Desc %"
                            value={line.desconto_per || 0}
                            onChange={(e) => {
                              updateLine(index, {
                                desconto_per: Number(e.target.value),
                              });
                            }}
                            error={Boolean(
                              validationErrors.lines[`discount-${index}`]
                            )}
                          />
                        </div>

                        <div className="input-field" data-label="Desc €">
                          <TextField
                            type="number"
                            min="0"
                            step="0.001"
                            label="Desc €"
                            value={line.valor_desconto || 0}
                            onChange={(e) => {
                              updateLine(index, {
                                valor_desconto: Number(e.target.value),
                              });
                            }}
                            error={Boolean(
                              validationErrors.lines[`discount-${index}`]
                            )}
                          />
                        </div>

                        <div className="input-field" data-label="IVA">
                          <TextField
                            type="text"
                            label="IVA"
                            value={line.taxa_iva ? `${line.taxa_iva}%` : ""}
                            readOnly
                          />
                        </div>

                        <div className="input-field" data-label="Total">
                          <TextField
                            type="text"
                            label="Total"
                            value={line.total.toFixed(2) + " €"}
                            readOnly
                          />
                        </div>

                        <button
                          type="button"
                          onClick={() => removeLine(index)}
                          className="delete-button"
                        >
                          <MdClear size={28} />
                        </button>
                      </div>
                    ))}

                    <div style={{ textAlign: "right", marginTop: "2rem" }}>
                      <button
                        type="button"
                        onClick={() => addLine(!invoice.lines.length)}
                        disabled={!canAddNewLine}
                        className="add-product-button"
                        title={
                          !canAddProduct
                            ? "Selecione um cliente primeiro"
                            : !isLastLineFilled()
                            ? "Preencha a última linha antes de adicionar uma nova"
                            : "Adicionar novo produto"
                        }
                      >
                        <MdAddCircleOutline size={24} />
                        Adicionar Produto
                      </button>
                    </div>
                  </div>

                  <div className="totals">
                    <div className="total-row">
                      Subtotal:{" "}
                      <span style={{ fontWeight: 500 }}>
                        {invoice?.subtotal?.toFixed(2)} €
                      </span>
                    </div>
                    <div className="total-row">
                      Total Descontos:{" "}
                      <span style={{ fontWeight: 500 }}>
                        {invoice?.totalDiscount?.toFixed(2)} €
                      </span>
                    </div>
                    <div className="total-row">
                      Total IVA:{" "}
                      <span style={{ fontWeight: 500 }}>
                        {invoice?.totalTax?.toFixed(2)} €
                      </span>
                    </div>
                    <div className="total-row">
                      Total: {invoice?.total?.toFixed(2)} €
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    
                    <button type="button" disabled={!canAddNewLine} onClick={handlePrint} className="preview-button">
                      <MdPreview size={28} />&nbsp;&nbsp;Pré-vizualizar
                    </button>
                    {recurrence.isRecurring ? (
                      <button type="button" disabled={true} onClick={() => {}} className="submit-button">
                        Criar Recorrência (Brevemente)
                      </button>
                    ) : (
                      <button type="button" disabled={!canAddNewLine} onClick={handleSubmit} className="submit-button">
                        Submeter Fatura
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PDFPrint ref={printerRef} pdfUrl={previewUrl} />
    </div>
  );
}

export default App;