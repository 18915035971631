// services/clientes.js
import axiosInstance from './axiosInstance';

const CLIENTES_API_URL = 'https://eposgestofinal.pt/api/clientes'; // Replace with your actual API endpoint

const clientesService = {
  async get(idempresa) {
    try {
      const response = await axiosInstance.get(`${CLIENTES_API_URL}/get.php?idempresa=${idempresa}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },

//   async getById(idempresa, id) {
//     try {
//       const response = await axiosInstance.get(`${CLIENTES_API_URL}/getById.php?idempresa=${idempresa}&codigo=${id}`);
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   },

//   async set(cliente) {
//     try {
//       if (cliente.id) {
//         const response = await axiosInstance.put(`${CLIENTES_API_URL}/${cliente.id}`, cliente);
//         return response.data;
//       } else {
//         const response = await axiosInstance.post(CLIENTES_API_URL, cliente);
//         return response.data;
//       }
//     } catch (error) {
//       throw error;
//     }
//   },

//   async unset(id) {
//     try {
//       await axiosInstance.delete(`${CLIENTES_API_URL}/${id}`);
//     } catch (error) {
//       throw error;
//     }
//   },
};

export default clientesService;