import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { NotificationManager } from 'react-notifications';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdAddCircleOutline, MdVisibility } from "react-icons/md";
import ReactTable from "@components/ReactTable";

// Components
import Menu from "@components/Menu";
import DateRangePicker from "@components/DateRangePicker";
import AutoComplete from "@components/AutoComplete";

import { dateToTimestamp } from "@utils/date";

import './tourniquet.css';

import UserContext from "@components/context/userContext";

const abbreviateName = (fullName) => {
  const ignoredWords = ["de", "da", "do", "dos", "das", "e"]; // Prepositions that should not be abbreviated
  let nameParts = fullName.split(/\s+/); // Split by spaces
  let abbreviatedParts = [];

  abbreviatedParts.push(nameParts[0]); // Keep the first name intact

  // for (let i = 1; i < nameParts.length - 1; i++) {
  //     if (!ignoredWords.includes(nameParts[i].toLowerCase())) {
  //         abbreviatedParts.push(nameParts[i][0] + ".");
  //     }
  // }

  abbreviatedParts.push(nameParts[nameParts.length - 1]); // Keep the last surname intact

  return abbreviatedParts.join(" ");
}

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.intervalIndoor = null;

    this.state = {
      loaded: false,
      toruniquet: {
        columns: [
            {
                Header: "Data",
                id: "date",
                accessor: d => d.timestamp.split(' ')[0],
            },
            {
                Header: "Hora",
                id: "time",
                accessor: d => d.timestamp.split(' ')[1].split('.')[0],
            },
            {
                Header: "Cliente",
                accessor: 'nome_1',
            },
            {
                Header: 'Entrada/Saída',
                id: 'way',
                accessor: d => d.way === 'in' ? (
                    <b>Entrada</b>
                    ) : (
                    <span>Saída</span>
                    ),
            },
            {
                Header: 'Método',
                id: 'method',
                accessor: d => d.method === 'card' ? (
                  <i class="far fa-id-card"></i>
                ) : d.method === 'phone' ? (
                  <i class="fas fa-mobile-alt"></i>
                ) : (
                  <i class="fas fa-users-cog"></i>
                ),
              },
              {
                Header: "Plano",
                accessor: 'product_name',
              },
              {
                  Header: "Estado",
                  id: "status",
                  accessor: d => d.status === 'SUCCESS' ? (
                      <span className="badge badge-success">ok</span>
                    ) : (
                      <span className="badge badge-warning">aviso</span>
                    ),
              },
          {
            Header: "",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      induty: [],
      date: [(new Date()).setHours(0 , 0 , 0) , (new Date()).setHours (23 , 59 , 59)],
      way: {
        value: "",
        label: "TODOS",
      },
      allWays: [
        { value: "", label: "TODOS" },
        { value: "in", label: "Entradas" },
        { value: "out", label: "Saídas" },
      ],
      method: {
        value: "",
        label: "TODOS",
      },
      allMethos: [
        { value: "", label: "TODOS" },
        { value: "card", label: "Cartão" },
        { value: "phone", label: "Telemóvel" },
        { value: "operator", label: "Operador" },
      ],
      status: {
        value: "",
        label: "TODOS",
      },
      allStatus: [
        { value: "", label: "TODOS" },
        { value: "SUCCESS", label: "ok" },
        { value: "WARNING", label: "aviso" },
      ]
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.exit = this.exit.bind(this);
    this.indoorClients = this.indoorClients.bind(this);
    this.getIndoorClients = this.getIndoorClients.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.localDateChange = this.localDateChange.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.intervalIndoor);
    this.intervalIndoor = null;
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
        const { history } = this.props;

        this.getIndoorClients();
        this.indoorClients();
    }
  }

  getIndoorClients() {
    const { history } = this.props;
    const profile = this.context;

    axios
        .get(
          `https://eposgestofinal.pt/api/clientes/members/access/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const toruniquet = response.data !== "NOK" ? response.data : [];

          this.setState({
            toruniquet: {
              ...this.state.table,
              rows: toruniquet.map((toruniquetItem) => ({
                ...toruniquetItem,
                view: (
                    <MdVisibility
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() =>
                            history.push(`/clientes/${toruniquetItem.codigo}`)
                        }
                    />
                )
              })),
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });

    axios
    .get(
        `https://eposgestofinal.pt/api/clientes/members/access/get.php?idempresa=${profile.idEmpresa}&induty=true`
    )
        .then((response) => {
        const induty = response.data !== "NOK" ? response.data : [];

        this.setState({
            induty: induty.map((indutyItem) => ({
                ...indutyItem,
                view: (
                    <MdVisibility
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() =>
                            history.push(`/clientes/${indutyItem.codigo}`)
                        }
                    />
                )
            })),
            loaded: true,
        });
        })
        .catch((error) => {
        console.error(error);
        });
  }

  indoorClients() {
    this.intervalIndoor = setInterval(() => {
        this.getIndoorClients();
    }, 10000)
  }

  exit(indutyItem) {
    const profile = this.context;

    confirmAlert({
        title: "Saída manual",
        message: `Tem certeza que pretende registar saída de ${indutyItem.nome_1}?`,
        buttons: [
          {
            label: "Sim",
            onClick: () => {
                axios
                    .get(
                        `https://eposgestofinal.pt/api/clientes/members/out.php?idempresa=${profile.idEmpresa}&codigo=${indutyItem.codigo}`
                    )
                        .then((response) => {
                            if (response.data !== "NOK") {
                                NotificationManager.success(
                                    "Saida manual",
                                    "Saida registada com sucesso",
                                    1000,
                                );
                                this.getIndoorClients();
                            } else {
                                NotificationManager.error(
                                    "Saida manual",
                                    "Erro ao registar Saida",
                                    1000,
                                );
                            }
                        })
                        .catch((error) => {
                            NotificationManager.error(
                                "Saida manual",
                                "Erro ao registar Saida",
                                1000,
                            );
                        });
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
    });
  }

  localDateChange(date) {
    this.setState({ date: [
        date[0].setHours(0 , 0 , 0),
        date[1].setHours(23 , 59 , 59),
      ]
    });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { toruniquet, induty, allMethos, method, allWays, way, allStatus, status, date } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filteredTourniquetRows = toruniquet.rows
      .filter((tourniquetRecord) =>
        status.value !== ''
          ? tourniquetRecord.status === status.value
          : true
      )
      .filter((tourniquetRecord) =>
        method.value !== ''
          ? tourniquetRecord.method === method.value
          : true
      )
      .filter((tourniquetRecord) =>
        way.value !== ''
          ? tourniquetRecord.way === way.value
          : true
      )
      .filter((tourniquetRecord) =>
        dateToTimestamp(tourniquetRecord.timestamp.split('.')[0]) >= date[0]/1000 && dateToTimestamp(tourniquetRecord.timestamp.split('.')[0]) <= date[1]/1000
      );

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Torniquete</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default" style={{ minHeight: 'inherit' }}>
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Indoor ({induty.length})
                </div>
              </div>
                  <br />
                  <div class="flex-wrapper">
                  {induty.length > 0 ? induty.map((indutyItem) => (
                        <div style={{ maxWidth: '195px', maxHeight: '255px', minWidth: '195px', minHeight: '255px' }}>
                        <div class="single_advisor_profile wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                            <div class="advisor_thumb">
                                <img style={{ maxWidth: '200px', maxHeight: '200px', padding: '0' }} src={indutyItem.fotografia || 'https://eposgestofinal.pt/site/images/dude.png'} alt=""/>
                                <div class="social-info">
                                    <a href="#" onClick={() => this.exit(indutyItem)}>Dar saída</a>
                                </div>
                            </div>
                            <div class="single_advisor_details_info">
                                <h6>
                                    {indutyItem.status !== 'SUCCESS' && (
                                      <span className="badge badge-warning">aviso</span>
                                    )}&nbsp;
                                    {indutyItem.timestamp.split(' ')[1].split('.')[0]}
                                </h6>
                                <p class="designation">{abbreviateName(indutyItem.nome_1)}</p>
                                {indutyItem.product_name && (<p style={{ fontSize: "9pt", color: 'dodgerblue' }}><b>{indutyItem.product_name}</b></p>)}
                            </div>
                        </div>
                    </div>
                      )) : (<h1>Nenhum cliente registado.</h1>)}
                  </div>
                  <br />
            </div>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Registo Torniquete ({filteredTourniquetRows.length})
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Data</th>
                    <th className="text-center">Entrada/Saída</th>
                    <th className="text-center">Método</th>
                    <th className="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <DateRangePicker maxDate={new Date()} value={date} onChange={this.localDateChange} />
                    </td>
                    <td>
                      <AutoComplete
                        name={"way"}
                        value={way} 
                        handleChange={this.handleChange}
                        options={allWays}
                      />
                    </td>
                    <td>
                      <AutoComplete
                        name={"method"}
                        value={method} 
                        handleChange={this.handleChange}
                        options={allMethos}
                      />
                    </td>
                    <td>
                      <AutoComplete
                        name={"status"}
                        value={status} 
                        handleChange={this.handleChange}
                        options={allStatus}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ReactTable
                columns={toruniquet.columns}
                sortable={true}
                loading={toruniquet.loading}
                data={filteredTourniquetRows}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
