import React, { Component, createRef } from 'react';
import { withCookies } from 'react-cookie';

import Modal from '@components/Modal';
import { MdOutlineSettings } from "react-icons/md";

import './inout.css';
import { EventBus as eventBus } from "@components/EventBus";

import UserContext from "@components/context/userContext";

const cooldownSeconds = 60;

const fraseMotivacionais = [
  "Não é apenas sobre levantar pesos, é sobre levantar-te a ti mesmo a cada desafio.",
  "Cada sessão de treino é uma oportunidade para te aproximares da melhor versão de ti mesmo.",
  "Quando te comprometes com o teu corpo, ele retribui com força e energia renovada.",
  "Supera o que pensavas ser impossível, e perceberás que os teus limites são apenas ilusões.",
  "A disciplina que constróis no ginásio reflete-se em todas as áreas da tua vida.",
  "O verdadeiro sucesso não vem da facilidade, mas da superação dos momentos difíceis.",
  "Todos os dias são uma nova oportunidade para te desafiares a ser melhor do que ontem.",
  "O esforço que colocas no treino é o espelho do respeito que tens pelo teu corpo.",
  "Quando te comprometes com o teu treino, comprometes-te com o teu bem-estar físico e mental.",
  "Não se trata apenas de ficar em forma, trata-se de te sentires mais confiante e capaz.",
  "Cada gota de suor é um passo mais próximo do teu objetivo.",
  "O mais difícil é começar, mas o mais gratificante é continuar e ver os resultados.",
  "Cada vez que te levantas e treinas, estás a investir na tua saúde futura.",
  "A consistência no treino é a chave que destranca o teu verdadeiro potencial.",
  "O caminho para a força começa com a decisão de nunca desistir.",
  "O teu corpo pode fazer muito mais do que a tua mente acredita. Não deixes que o medo te pare.",
  "Cada treino é um tijolo no edifício do teu sucesso físico e mental.",
  "O esforço pode ser duro agora, mas os resultados valem cada segundo.",
  "O progresso não é imediato, mas todos os pequenos passos contam para algo maior.",
  "A dedicação que mostras no ginásio é um reflexo da força interior que carregas.",
  "Não treines para ser perfeito, treina para ser mais forte do que as tuas desculpas.",
  "O sucesso no treino não é um destino, é uma jornada diária de superação.",
  "Cada repetição, cada série, cada esforço é um avanço no teu percurso de autossuperação.",
  "O verdadeiro teste não é o treino em si, mas a tua capacidade de regressar dia após dia.",
  "O que parece impossível hoje será a tua rotina amanhã.",
  "Quando treinas com consistência, aprendes a vencer os teus próprios limites.",
  "Não se trata de ser o mais rápido ou o mais forte, mas de nunca parar de tentar.",
  "Treinar é o espaço onde confrontas as tuas fraquezas e emerges mais forte.",
  "O que investes no teu corpo é o que vais receber em força, saúde e vitalidade.",
  "A força que procuras no treino não vem apenas dos músculos, mas da determinação dentro de ti.",
  "Cada treino é uma vitória, não importa quão pequeno seja o progresso.",
  "Treinar é uma forma de expressar gratidão ao teu corpo por tudo o que ele é capaz de fazer.",
  "A transformação começa no momento em que decides que o esforço vale mais do que as desculpas.",
  "Quando pensas em desistir, lembra-te do motivo pelo qual começaste.",
  "No final de cada treino, não apenas ficas mais forte, mas também mais resiliente.",
  "O verdadeiro poder do treino está na tua capacidade de continuar mesmo quando queres parar.",
  "A tua força física cresce na mesma medida que a tua força mental é testada.",
  "O progresso no ginásio é um reflexo direto do teu compromisso com a tua saúde.",
  "Cada treino que completas é uma prova de que estás mais perto do teu objetivo.",
  "Treinar é a forma como demonstras ao teu corpo que ele é capaz de muito mais.",
  "A tua evolução no treino não depende da perfeição, mas sim da persistência.",
  "Nunca subestimes o impacto de um treino consistente. Os resultados acumulam-se com o tempo.",
  "Cada esforço que fazes no ginásio é um investimento na tua longevidade e bem-estar.",
  "Não deixes que o medo ou a dúvida te impeçam de alcançar a tua melhor versão.",
  "A transformação mais importante acontece dentro de ti cada vez que superas um treino difícil.",
  "A resistência que encontras no treino é o que te torna mais forte no resto da tua vida.",
  "Treinar é um compromisso contigo mesmo de nunca aceitar menos do que aquilo que mereces.",
  "A cada repetição, ganhas não apenas força física, mas também mental.",
  "O maior inimigo do progresso é a inação. O maior aliado é a perseverança.",
  "Treinar é a tua forma de dizer ao mundo que estás comprometido com a tua própria evolução.",
];

const isValidSingleChar = (str) => {
  const regex = /^[0-9a-zA-Z-]$/;
  return regex.test(str);
}

const getRandomFraseMotivacional = () => {
  const randomIndex = Math.floor(Math.random() * fraseMotivacionais.length);
  return fraseMotivacionais[randomIndex];
}

const getRandomHiByeImg = (type, way) => {
  const salute = way == 'in' ? 'hi' : 'bye';
  const maxImgs = way == 'in' ? 45 : 2;
  return `https://eposgestofinal.pt/site/images/${type}/${salute}_` + (Math.floor(Math.random() * maxImgs) + 1) + '.gif';
}

const defaultState = {
  qrCode: '',
  phoneNumber: '',
  status: 'Passe o cartão pelo leitor...',
  statusClass: 'waiting',
  cooldownModal: { 
    isOpen: false,
    remainingTime: 0,
    message: ''
  },
};

class Page extends Component {
  static contextType = UserContext;

  buttonRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      cooldowns: [],
      oldqrcode: '',
    };

    this.timer = null;
    this.successTimer = null;
    this.defaultTimer = null;
    this.errorSound = new Audio('/error.mp3');
    this.successSound = new Audio('/success.mp3');
  }

  componentDidMount() {
    window.addEventListener('focus', this.focusPage);
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.focusPage);
    window.removeEventListener('keyup', this.handleKeyUp);
    clearTimeout(this.timer);
    clearTimeout(this.successTimer);
    clearTimeout(this.defaultTimer);
  }

  
  showMessage = (status) => {
    clearTimeout(this.defaultTimer);
    this.setState(status);
    this.defaultTimer = setTimeout(() => {
      this.setState(defaultState);
    }, 3000);
  }

  focusPage = () => {
    
  };

  
  performAction = async (response, cardId) => {
    if (!response.ok) {
      throw new Error('Erro na consulta à API');
    }
    
    const data = await response.json();
    
    if (data.status === 'LOGIN') {
      localStorage.removeItem('token');
      localStorage.removeItem('public_indentifier')
      localStorage.removeItem('avatar')
      window.location.reload();
      return;
    }

    if (data.status === 'SUCCESS') {    
      await this.addCooldown(cardId); 

      this.playSuccessSound();
      this.showMessage({
        status: `Bem-vindo, ${data.data.nome_1}!`,
        statusClass: 'authorized',
        member: data.data
      });
      eventBus.$emit("Modal-success-popup", { header: "", body: data.way == 'in' ? (
        <div className="success-popup">
          <img src={getRandomHiByeImg('gym', data.way)} style={{ width: '80px' }} />
          <br/>
          <br/>
          <h4>Bom treino, <b>{data.data.nome_1}</b>!</h4>
          <h3>{data.data.plan}</h3>
          {data.data.limite_semanal && (<p><b>Entradas:</b> {data.data.limite_semanal - data.data.restante_limite_semanal}/{data.data.limite_semanal}</p>)}
          <br/>
          <cite>"{getRandomFraseMotivacional()}"</cite>
        </div>
      ) : (
        <div className="success-popup">
          <img src={getRandomHiByeImg('gym', data.way)} style={{ width: '80px' }} />
          <br/>
          <br/>
          <h4>Bom descanso, <b>{data.data.nome_1}</b>!</h4>
          <br/>
          <cite>"{getRandomFraseMotivacional()}"</cite>
        </div>
      ) });

      this.successTimer = setTimeout(() => {
        eventBus.$emit("Modal-success-popup", { isOpen: false });
      }, 7000);
    } else if (data.status === 'UNAUTHORIZED') {
      this.playErrorSound();
      this.showMessage({
        status: data.message,
        statusClass: 'unauthorized',
      });
    } else if (data.status === 'WARNING') {
      this.playErrorSound();
      this.showMessage({
        status: data.message,
        statusClass: 'warning',
      });
    } else {
      this.playErrorSound();
      this.showMessage({
        status: 'Acesso Negado.',
        statusClass: 'unauthorized',
      });
    }
  }

  
  processQRCode = async (code) => {
    const profile = this.context;
    if (!code) return;

    const cardId = code.trim();

    await this.cleanCooldowns(); 

    const cooldownEntry = this.isInCooldown(cardId);
    console.log(cooldownEntry);
    if (cooldownEntry) {
      const remainingTime = Math.ceil((cooldownEntry.expiresAt - Date.now()) / 1000);
      this.showMessage({
        status: `Cartão lido recentemente, aguarde ${remainingTime} segundos.`,
        statusClass: 'warning',
      });
      return;
    }

    this.showMessage({
      status: 'A validar...',
      statusClass: 'waiting',
    });

    try {
      const response = await fetch(`https://eposgestofinal.pt/api/clientes/members/authorize.php?token=${profile.token}&idempresa=${profile.identifier}&card=${encodeURIComponent(code).toUpperCase()}`);
      await this.performAction(response, cardId);
    } catch (error) {
      this.showMessage({
        status: 'Erro ao validar cartão, tente novamente.',
        statusClass: 'unauthorized',
      });
      this.playErrorSound();
    } finally {
      this.setState({ qrCode: '' });
    }
  };

  
  processPhoneNumber = async (phoneNumber) => {
    const profile = this.context;
    if (!phoneNumber) return;

    const cardId = phoneNumber.trim();

    await this.cleanCooldowns(); 

    this.showMessage({
      status: 'A validar...',
      statusClass: 'waiting',
    });

    try {
      const response = await fetch(`https://eposgestofinal.pt/api/clientes/members/authorize.php?token=${profile.token}&idempresa=${profile.identifier}&phone=${encodeURIComponent(phoneNumber)}`);
      await this.performAction(response, cardId);
    } catch (error) {
      this.showMessage({
        status: 'Erro ao validar número de telemóvel, tente novamente.',
        statusClass: 'unauthorized',
      });
      this.playErrorSound();
    } finally {
      this.setState({ phoneNumber: '' });
    }
  };

  
  handleKeyPress = (digit) => {
    this.setState((prevState) => ({
      phoneNumber: prevState.phoneNumber + digit
    }));
  };

  
  handleKeyBackspace = () => {
    this.setState((prevState) => ({
      phoneNumber: prevState.phoneNumber.slice(0, -1)
    }));
  };

  
  handleSubmit = () => {
    const { phoneNumber } = this.state;
    this.processPhoneNumber(phoneNumber.trim());
    this.closeModal();
  };

  
  openModal = () => {
    eventBus.$emit("Modal-keypad", { header: "Insira o Número de Telemóvel" });
  };

  
  closeModal = () => {
    this.setState({ phoneNumber: '' });
    eventBus.$emit("Modal-keypad", { isOpen: false });
  };

  
  handleKeyUp = (e) => {
    const { qrCode, oldqrcode } = this.state;

    if (e.key === 'Enter') {
      this.processQRCode(qrCode.trim());
      console.log(oldqrcode);
      this.setState({ qrCode: '' });
      clearTimeout(this.timer);
    } else {
      if (isValidSingleChar(e.key)) {
        this.setState({ qrCode: qrCode + e.key, oldqrcode: qrCode + e.key });
  
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.showMessage({
            qrCode: '',
            status: 'Passe o cartão pelo leitor...',
            statusClass: 'waiting',
          });
        }, 2000);
      }
    }
  };

  
  playErrorSound = () => {
    this.errorSound.play();
  }

  playSuccessSound = () => {
    this.successSound.play();
  }

  
  isInCooldown = (cardId) => {
    const now = Date.now();
    const cooldownEntry = this.state.cooldowns.find(entry => entry.cardId === cardId && entry.expiresAt > now);
    return cooldownEntry || null;
  }

  
  addCooldown = async (cardId) => {
    console.log('addCooldown', JSON.parse(JSON.stringify(this.state.cooldowns)));
    const expiresAt = Date.now() + (cooldownSeconds * 1000); 
    return this.setState({
      cooldowns: [...this.state.cooldowns, { cardId, expiresAt }]
    });
  }

  
  cleanCooldowns = async () => {
    console.log('cleanCooldowns', JSON.parse(JSON.stringify(this.state.cooldowns)));
    const now = Date.now();
    eventBus.$emit("Modal-success-popup", { isOpen: false });
    clearTimeout(this.successTimer);
    return this.setState({
      cooldowns: this.state.cooldowns.filter(entry => {
        console.log(entry.expiresAt, now, entry.expiresAt - now);
        return entry.expiresAt > now
      })
    });
  }

  render() {
    const profile = this.context;
    const { status, statusClass, phoneNumber } = this.state;

    return (
      <div className="page">
        <div className="container">
          {/*<span style={{ position: 'relative', top: '-30px', right: '-285px', color: 'whitesmoke' }}>
            <MdOutlineSettings
              style={{ fontSize: "30px", cursor: "pointer" }}
            />
          6</span>*/}
          <header>
            <img src={profile.companyPublicAvatar.includes('data: ') ? profile.companyPublicAvatar : "https://eposgestofinal.pt/"+profile.companyPublicAvatar} alt="Logo do Club Ginásio" className="logo" />
          </header>
          <main>
            <div className={`status ${statusClass}`}>{status}</div>
            
            <button className="btn open-keypad-btn" tabIndex="-1" onFocus={(e) => e.target.blur()} onClick={this.openModal}>
              Inserir Número de Telemóvel
            </button>

            {/* Modal do Teclado Numérico */}
            <Modal
              id="keypad"
              className="modal"
            >
              <div className="phone-display">{phoneNumber || <>&nbsp;</>}</div>
              <div className="keypad">
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('1')}>1</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('2')}>2</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('3')}>3</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('4')}>4</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('5')}>5</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('6')}>6</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('7')}>7</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('8')}>8</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('9')}>9</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={this.handleKeyBackspace}>←</button>
                <button tabIndex="-1" className="btn keypad-btn" onClick={() => this.handleKeyPress('0')}>0</button>
                <button tabIndex="-1" className="btn submit-btn" onClick={this.handleSubmit}>Enviar</button>
                <button tabIndex="-1" className="btn close-btn" onClick={this.closeModal}>Voltar</button>
              </div>
            </Modal>

            {/* Modal de Sucesso */}
            <Modal
              id="success-popup"
              className="modal success-modal"
            />

            {/* Modal de Cooldown */}
            <Modal
              id="cooldown-popup"
              className="modal success-modal"
            />

            <Modal id="manegemnt" modifier="big" />
          </main>
        </div>
      </div>
    );
  }
}

export default withCookies(Page);
