import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";

import Input from "@components/Input";
import Checkbox from "@components/CheckBox";
import Barcode from "@components/BarCode";

import { MdSaveAs, MdDelete } from "react-icons/md";
import Draggable from "react-draggable";
import FontPicker from "font-picker-react";
import ColorPicker from '@uiw/react-color-sketch';
import { confirmAlert } from 'react-confirm-alert';

import "./edit.css";

import UserContext from "@components/context/userContext";

let optsElements;

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    this.state = {
      idpricetag: type === "edit" && params.idpricetag,
      edit: type === "edit",

      activeDrags: 0,
      activeElement: '',

      loaded: false,

      name: '',

      pricetag: {
        width: 10,
        height: 4,  
      },

      grid: false,
      zoom: 1,

      companyname: {
        show: true,
        font: {
          family: "Open Sans",
          color: "#000",
          size: 17,
          bold: true,
          italic: false,  
        },
        example: 'Nome empresa',
        x: 5,
        y: 0, 
      },
      description: {
        show: true,
        font: {
          family: "Open Sans",
          color: "#000",
          size: 10,
          bold: false,
          italic: false,  
        },
        example: 'Designação do Porduto',
        x: 5,
        y: 23, 
      },
      barcode: {
        show: true,
        width: 363,
        height: 65,
        font: {
          family: "Open Sans",
          color: "#000",
          size: 10,  
        },
        textShow: false,
        example: 'CODIGO DE BARRAS',
        x: 5,
        y: 41,
      },
      productid: {
        show: true,
        font: {
          family: "Open Sans",
          color: "#000",
          size: 10,
          bold: false,
          italic: false,  
        },
        example: 'Código do Porduto',
        x: 5,
        y: 110, 
      },
      price: {
        show: true,
        font: {
          family: "Open Sans",
          color: "#000",
          size: 23,
          bold: true,
          italic: false,  
        },
        example: 'Preço',
        x: 295,
        y: 110,
      },
      iva: {
        show: true,
        font: {
          family: "Open Sans",
          color: "#000",
          size: 10,
          bold: false,
          italic: false,  
        },
        example: 'IVA',
        x: 5,
        y: 128,
      }
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onControlledDrag = this.onControlledDrag.bind(this);
    this.onControlledDragStop = this.onControlledDragStop.bind(this);
    this.handleChangeLogic = this.handleChangeLogic.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.zoomInOut = this.zoomInOut.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      const { history } = this.props;
      if (this.props.type === "edit") {
        axios
          .get(
            `https://eposgestofinal.pt/api/pricetags/getById.php?idempresa=${profile.idEmpresaSec}&idpricetag=${this.state.idpricetag}`
          )
          .then((response) => {
            const pricetag = response.data !== "NOK" ? response.data[0] : {};

            const enrichedPricetag = {
              ...pricetag,
              ...JSON.parse(pricetag.config),
              pricetag: {
                ...JSON.parse(pricetag.size)
              }
            };

            this.setState({
              ...enrichedPricetag,
              companyname: {
                ...this.state.companyname,
                ...enrichedPricetag.companyname,
                example: profile.name,
              },
              loaded: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({
          companyname: {
            ...this.state.companyname,
            example: profile.name,
          },
          loaded: true,
        });
      }
    }
  }

  SaveAs = () => {
    const profile = this.context;
    const { history } = this.props;
    const {
      pricetag,
      barcode,
      companyname,
      description,
      productid,
      price,
      iva,
      name,
    } = this.state;

    let nameTemplate = name || prompt("Nome do template:");
    if (nameTemplate) {
      axios
        .post("https://eposgestofinal.pt/api/pricetags/set.php", {
          name: nameTemplate,
          size: JSON.stringify(pricetag),
          config: JSON.stringify({
            barcode,
            companyname,
            description,
            productid,
            price,
            iva,
          }),
          idempresa: profile.idEmpresaSec,
          idpricetag: this.state.edit ? this.state.idpricetag : undefined,
        })
        .then((response) => {
          history.push("/pricetags");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  delete = () => {
    const profile = this.context;
    const { history } = this.props;
    const {
      idpricetag,
    } = this.state;

    /////////pedir confirmação
    confirmAlert({
      title: 'Confirmação',
      message: `Tem a certeza que deseja apagar este template?`,
      buttons: [
          {
              label: 'Sim',
              onClick: () => {
                const profile = this.context;
                axios
                  .get(
                    `https://eposgestofinal.pt/api/pricetags/unset.php?idempresa=${profile.idEmpresaSec}&idpricetag=${idpricetag}`
                  )
                  .then(() => {
                    window.location.href = '/pricetags';
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
          },
          {
              label: 'Não',
              onClick: () => {
              }
          }
      ]
    });
  }

  hiddeOptions = () => {
    const optsIds = [
      'barcodeOpts',
      'companynameOpts',
      'descriptionOpts',
      'productidOpts',
      'priceOpts',
      'ivaOpts',
    ]
    optsIds.forEach(optsId => {
      document.getElementById(`${optsId}`).style.display = 'none'
    });
  }

  showOnlyOption = (opt) => {
    this.hiddeOptions()
    document.getElementById(`${opt}Opts`).style.display = ''
  }

  onStart = (e) => {
    const name = e.currentTarget.getAttribute("name");
    this.showOnlyOption(name);
    this.setState({ activeDrags: ++this.state.activeDrags, activeElement: name });
  }

  onStop = (e) => {
    this.setState({ activeDrags: --this.state.activeDrags });
  }

  onControlledDrag = (e, position) => {
    const {x, y} = position;
    const name = this.state.activeElement
    this.setState({
      [name]: {
        ...this.state[name],
        x,
        y,
      }
    });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };

  zoomInOut = (direction) => {
    const zoom = direction === 'out' ? this.state.zoom - .25 : this.state.zoom + .25;
    if (zoom >= 1) {
      this.setState({
        zoom,
      })
    }
  }

  gridOnOff = (onOff) => {
    const grid = !this.state.grid;
    this.setState({
      grid,
    })
  }

  handleChangeLogic(name, value) {
    let newState = this.state;
  
    if (name.includes("|")) {
      const properties = name.split("|");

      if (properties.length === 3) {
        newState[properties[0]][properties[1]][properties[2]] = value;
      } else {
        newState[properties[0]][properties[1]] = value;
      }

      this.setState({
        ...newState,
      });
    } else {
      this.setState({
        [name]: value,
      });
        
    }
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.handleChangeLogic(name, value);
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.handleChangeLogic(name, value);
  }

  handleClick(e) {
    if (
      !optsElements.barcodeOpts.contains( e.target ) &&
      !optsElements.companynameOpts.contains( e.target ) &&
      !optsElements.descriptionOpts.contains( e.target ) &&
      !optsElements.productidOpts.contains( e.target ) &&
      !optsElements.priceOpts.contains( e.target ) &&
      !optsElements.ivaOpts.contains( e.target )
    ) {
      this.hiddeOptions()
    }
  }

  componentDidUpdate() {
    optsElements = {
      barcodeOpts: document.querySelector('#barcodeOpts'),
      companynameOpts: document.querySelector('#companynameOpts'),
      descriptionOpts: document.querySelector('#descriptionOpts'),
      productidOpts: document.querySelector('#productidOpts'),
      priceOpts: document.querySelector('#priceOpts'),
      ivaOpts: document.querySelector('#ivaOpts'),  
    }
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  render() {
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };

    const {
      loaded,
      pricetag,
      barcode,
      companyname,
      description,
      productid,
      price,
      iva,
      grid,
      zoom,
    } = this.state;

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">
                <span>Etiquetas</span>
              </li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Etiquetas
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                  >
                    <tr>
                      <td onClick={() => this.delete()}>
                        <MdDelete style={{ fontSize: "20px" }} />
                      </td>
                      <td onClick={() => this.SaveAs()}>
                        <MdSaveAs style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td onClick={() => this.delete()} style={{ fontSize: "xx-small" }}>Apagar Etiqueta</td>
                      <td onClick={() => this.SaveAs()} style={{ fontSize: "xx-small" }}>Guardar Etiqueta</td>
                    </tr>
                  </table>
                </div>
              </div>
              {loaded && (
                <div>
                  <div className="col-md-3" style={{ margin: 0, padding: 0, border: '2px solid black' }}>
                    <div id="barcodeOpts" style={{ display: 'none', width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>Código de Barras</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                        <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'barcode|width'}
                                        value={barcode.width}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'barcode|height'}
                                        value={barcode.height}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Posição (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'barcode|x'}
                                        value={barcode.x}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'barcode|y'}
                                        value={barcode.y}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Mostrar Código"}
                                  position={"left"}
                                  name={"barcode|textShow"}
                                  checked={barcode.textShow}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                            <div className="input-group">
                              <label className="control-label">Texto de exemplo</label>
                              <Input
                                inputType={"text"}
                                name={'barcode|example'}
                                value={barcode.example}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho da letra</label>
                                <Input
                                  inputType={"number"}
                                  name={'barcode|font|size'}
                                  value={barcode.font.size}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tipo da letra</label><br/>
                                <FontPicker
                                  apiKey="AIzaSyDjRVCdbhq9vlvQw_x2fapfih3y51os8Pg"
                                  activeFontFamily={barcode.font.family}
                                  onChange={(nextFont) =>
                                    this.setState({
                                      barcode: {
                                        ...this.state.barcode,
                                        font: {
                                          ...this.state.barcode.font,
                                          family: nextFont.family
                                        }
                                      },
                                    })
                                  }
                                  pickerId={'barcode'}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Cor da letra</label>
                                <ColorPicker
                                  style={{ width: '205px' }}
                                  color={barcode.font.color}
                                  disableAlpha="true"
                                  onChange={(color) => {
                                    this.handleChange({
                                      target: {
                                        name: 'barcode|font|color',
                                        value: color.hex
                                      }
                                    });
                                  }}
                                  />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="companynameOpts" style={{ display: 'none', width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>Nome empresa</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                        <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Posição (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'companyname|x'}
                                        value={companyname.x}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'companyname|y'}
                                        value={companyname.y}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Negrito"}
                                  position={"left"}
                                  name={"companyname|font|bold"}
                                  checked={companyname.font.bold}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Itálico"}
                                  position={"left"}
                                  name={"companyname|font|italic"}
                                  checked={companyname.font.italic}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Texto de exemplo</label>
                                <Input
                                  inputType={"text"}
                                  name={'companyname|example'}
                                  value={companyname.example}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho da letra</label>
                                <Input
                                  inputType={"number"}
                                  name={'companyname|font|size'}
                                  value={companyname.font.size}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tipo de letra</label><br/>
                                <FontPicker
                                  apiKey="AIzaSyDjRVCdbhq9vlvQw_x2fapfih3y51os8Pg"
                                  activeFontFamily={companyname.font.family}
                                  onChange={(nextFont) =>
                                    this.setState({
                                      companyname: {
                                        ...this.state.companyname,
                                        font: {
                                          ...this.state.companyname.font,
                                          family: nextFont.family
                                        }
                                      },
                                    })
                                  }
                                  pickerId={'companyname'}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Cor da letra</label>
                                <ColorPicker
                                  style={{ width: '205px' }}
                                  color={companyname.font.color}
                                  disableAlpha="true"
                                  onChange={(color) => {
                                    this.handleChange({
                                      target: {
                                        name: 'companyname|font|color',
                                        value: color.hex
                                      }
                                    });
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="descriptionOpts" style={{ display: 'none', width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>Designação do Produto</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Posição (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'description|x'}
                                        value={description.x}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'description|y'}
                                        value={description.y}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Negrito"}
                                  position={"left"}
                                  name={"description|font|bold"}
                                  checked={description.font.bold}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Itálico"}
                                  position={"left"}
                                  name={"description|font|italic"}
                                  checked={description.font.italic}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Texto de exemplo</label>
                                <Input
                                  inputType={"text"}
                                  name={'description|example'}
                                  value={description.example}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho da letra</label>
                                <Input
                                  inputType={"number"}
                                  name={'description|font|size'}
                                  value={description.font.size}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tipo de letra</label><br/>
                                <FontPicker
                                  apiKey="AIzaSyDjRVCdbhq9vlvQw_x2fapfih3y51os8Pg"
                                  activeFontFamily={description.font.family}
                                  onChange={(nextFont) =>
                                    this.setState({
                                      description: {
                                        ...this.state.description,
                                        font: {
                                          ...this.state.description.font,
                                          family: nextFont.family,
                                        }
                                      }
                                    })
                                  }
                                  pickerId={'description'}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Cor da letra</label>
                                <ColorPicker
                                  style={{ width: '205px' }}
                                  color={description.font.color}
                                  disableAlpha="true"
                                  onChange={(color) => {
                                    this.handleChange({
                                      target: {
                                        name: 'description|font|color',
                                        value: color.hex
                                      }
                                    });
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="productidOpts" style={{ display: 'none', width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>Código do Produto</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Posição (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'productid|x'}
                                        value={productid.x}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'productid|y'}
                                        value={productid.y}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Negrito"}
                                  position={"left"}
                                  name={"productid|font|bold"}
                                  checked={productid.font.bold}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Itálico"}
                                  position={"left"}
                                  name={"productid|font|italic"}
                                  checked={productid.font.italic}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Texto de exemplo</label>
                                <Input
                                  inputType={"text"}
                                  name={'productid|example'}
                                  value={productid.example}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho da letra</label>
                                <Input
                                  inputType={"number"}
                                  name={'productid|font|size'}
                                  value={productid.font.size}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tipo de letra</label><br/>
                                <FontPicker
                                  apiKey="AIzaSyDjRVCdbhq9vlvQw_x2fapfih3y51os8Pg"
                                  activeFontFamily={productid.font.family}
                                  onChange={(nextFont) =>
                                    this.setState({
                                      productid: {
                                        ...this.state.productid,
                                        font: {
                                          ...this.state.productid.font,
                                          family: nextFont.family,
                                        }
                                      }
                                    })
                                  }
                                  pickerId={'productid'}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Cor da letra</label>
                                <ColorPicker
                                  style={{ width: '205px' }}
                                  color={productid.font.color}
                                  disableAlpha="true"
                                  onChange={(color) => {
                                    this.handleChange({
                                      target: {
                                        name: 'productid|font|color',
                                        value: color.hex
                                      }
                                    });
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="priceOpts" style={{ display: 'none', width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>Preço</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Posição (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'price|x'}
                                        value={price.x}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'price|y'}
                                        value={price.y}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Negrito"}
                                  position={"left"}
                                  name={"price|font|bold"}
                                  checked={price.font.bold}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Itálico"}
                                  position={"left"}
                                  name={"price|font|italic"}
                                  checked={price.font.italic}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Texto de exemplo</label>
                                <Input
                                  inputType={"text"}
                                  name={'price|example'}
                                  value={price.example}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho da letra</label>
                                <Input
                                  inputType={"number"}
                                  name={'price|font|size'}
                                  value={price.font.size}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tipo de letra</label><br/>
                                <FontPicker
                                  apiKey="AIzaSyDjRVCdbhq9vlvQw_x2fapfih3y51os8Pg"
                                  activeFontFamily={price.font.family}
                                  onChange={(nextFont) =>
                                    this.setState({
                                      price: {
                                        ...this.state.price,
                                        font: {
                                          ...this.state.price.font,
                                          family: nextFont.family,
                                        }
                                      }
                                    })
                                  }
                                  pickerId={'price'}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Cor da letra</label>
                                <ColorPicker
                                  style={{ width: '205px' }}
                                  color={price.font.color}
                                  disableAlpha="true"
                                  onChange={(color) => {
                                    this.handleChange({
                                      target: {
                                        name: 'price|font|color',
                                        value: color.hex
                                      }
                                    });
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="ivaOpts" style={{ display: 'none', width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>IVA</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Posição (px)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'iva|x'}
                                        value={iva.x}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'iva|y'}
                                        value={iva.y}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Negrito"}
                                  position={"left"}
                                  name={"iva|font|bold"}
                                  checked={iva.font.bold}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Itálico"}
                                  position={"left"}
                                  name={"iva|font|italic"}
                                  checked={iva.font.italic}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Texto de exemplo</label>
                                <Input
                                  inputType={"text"}
                                  name={'iva|example'}
                                  value={iva.example}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho da letra</label>
                                <Input
                                  inputType={"number"}
                                  name={'iva|font|size'}
                                  value={iva.font.size}
                                  handleChange={this.handleChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tipo de letra</label><br/>
                                <FontPicker
                                  apiKey="AIzaSyDjRVCdbhq9vlvQw_x2fapfih3y51os8Pg"
                                  activeFontFamily={iva.font.family}
                                  onChange={(nextFont) =>
                                    this.setState({
                                      iva: {
                                        ...this.state.iva,
                                        font: {
                                          ...this.state.iva.font,
                                          family: nextFont.family,
                                        }
                                      }
                                    })
                                  }
                                  pickerId={'iva'}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Cor da letra</label>
                                <ColorPicker
                                  style={{ width: '205px' }}
                                  color={iva.font.color}
                                  disableAlpha="true"
                                  onChange={(color) => {
                                    this.handleChange({
                                      target: {
                                        name: 'iva|font|color',
                                        value: color.hex
                                      }
                                    });
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="pricetagOpts" style={{ width: '100%', border: '1px solid black', borderRadius: '3px', padding: '10px' }}>
                      <h3><b>Etiqueta</b></h3>
                      <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left', padding: 0 }}>
                        <div className="form-group">
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <label className="control-label">Tamanho (cm)</label>
                                <table>
                                  <tr>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'pricetag|width'}
                                        value={pricetag.width}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td>
                                      <Input
                                        inputType={"number"}
                                        name={'pricetag|height'}
                                        value={pricetag.height}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Nome da Empresa"}
                                  position={"left"}
                                  name={"companyname|show"}
                                  checked={companyname.show}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Designação"}
                                  position={"left"}
                                  name={"description|show"}
                                  checked={description.show}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Código de Barras"}
                                  position={"left"}
                                  name={"barcode|show"}
                                  checked={barcode.show}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Código do Produto"}
                                  position={"left"}
                                  name={"productid|show"}
                                  checked={productid.show}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"Preço"}
                                  position={"left"}
                                  name={"price|show"}
                                  checked={price.show}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                          <div className="col-md-12 inputGroupContainer">
                              <div className="input-group">
                                <Checkbox
                                  title={"IVA"}
                                  position={"left"}
                                  name={"iva|show"}
                                  checked={iva.show}
                                  handleChange={this.handleCheckboxChange}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" style={{ padding: 0 }}>
                    <div style={{ padding: '10px', border: '2px solid black' }}>
                      <h3 style={{ textAlign: 'left', marginTop: 0 }}>Ferramentas</h3>
                      <div style={{ textAlign: 'left', fontSize: '17px', display: 'flex', width: '200px' }}>
                        <div style={{ flex: 1, border: '1px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div onClick={ () => this.zoomInOut('out') } style={{ flex: 1, textAlign: 'center', border: '1px solid black', cursor: 'pointer' }}>
                            <i class="fas fa-search-minus" style={{}}></i>
                          </div>
                          <div style={{ flex: 1, fontSize: '12px', fontWeight: 900, textAlign: 'center' }}>
                            {zoom * 100}%
                          </div>
                          <div onClick={ () => this.zoomInOut('in') } style={{ flex: 1, textAlign: 'center', border: '1px solid black', cursor: 'pointer' }}>
                            <i class="fas fa-search-plus"></i>
                          </div>
                        </div>
                        <div onClick={this.gridOnOff} style={{ flex: 1, border: grid ? '5px inset rgba(0,0,0,0.65)' : '1px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }}>
                          <div  style={{ flex: 1, textAlign: 'center', cursor: 'pointer' }}>
                            <i class="fas fa-border-style"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="box"
                        style={{
                          height: `${pricetag.height}cm`,
                          width: `${pricetag.width}cm`,
                          position: "relative",
                          overflow: "hidden",
                          padding: "0",
                          margin: "10px",
                          border: "1px solid #999",
                          transform: `scale(${zoom})`,
                          transformOrigin: "0% 0% 0px",
                        }}
                      >
                        <Draggable {...dragHandlers} bounds="parent" name="companyname" position={{ x: companyname.x, y: companyname.y }} onDrag={this.onControlledDragStop} >
                            <div
                              name="companyname"
                              className={{
                                box: true,
                                'apply-font-companyname': true,
                                grid, 
                              }}
                              style={{
                                fontSize: `${companyname.font.size}px`,
                                color: companyname.font.color,
                                fontWeight: companyname.font.bold ? 'bold' : 'normal',
                                fontStyle: companyname.font.italic ? 'italic' : 'normal',
                                visibility: companyname.show ? '' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                            >{companyname.example}</div>
                        </Draggable>
                        <Draggable {...dragHandlers} bounds="parent" name="description" position={{ x: description.x, y: description.y }} onDrag={this.onControlledDragStop} >
                            <div
                              name="description"
                              className={{
                                box: true,
                                'apply-font-description': true,
                                grid, 
                              }}
                              style={{
                                fontSize: `${description.font.size}px`,
                                color: description.font.color,
                                fontWeight: description.font.bold ? 'bold' : 'normal',
                                fontStyle: description.font.italic ? 'italic' : 'normal',
                                visibility: description.show ? '' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                            >{description.example}</div>
                        </Draggable>
                        <Draggable {...dragHandlers} bounds="parent" name="barcode" position={{ x: barcode.x, y: barcode.y }} onDrag={this.onControlledDragStop} >
                            <div name="barcode"
                              className={{
                                box: true,
                                grid, 
                              }}
                              style={{
                                visibility: barcode.show ? '' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}>
                              <Barcode
                                value={barcode.example}
                                width={barcode.width}
                                height={barcode.height}
                                font={barcode.font}
                                lineColor={barcode.font.color}
                                fontSize={barcode.font.size}
                                displayValue={barcode.textShow}
                              />
                            </div>
                        </Draggable>
                        <Draggable {...dragHandlers} bounds="parent" name="productid" position={{ x: productid.x, y: productid.y }} onDrag={this.onControlledDragStop} >
                            <div
                              name="productid"
                              className={{
                                box: true,
                                'apply-font-productid': true,
                                grid, 
                              }}
                              style={{
                                fontSize: `${productid.font.size}px`,
                                color: productid.font.color,
                                fontWeight: productid.font.bold ? 'bold' : 'normal',
                                fontStyle: productid.font.italic ? 'italic' : 'normal',
                                visibility: productid.show ? '' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                            >{productid.example}</div>
                        </Draggable>
                        <Draggable {...dragHandlers} bounds="parent" name="price" position={{ x: price.x, y: price.y }} onDrag={this.onControlledDragStop} >
                            <div
                              name="price"
                              className={{
                                box: true,
                                'apply-font-price': true,
                                grid, 
                              }}
                              style={{
                                fontSize: `${price.font.size}px`,
                                color: price.font.color,
                                fontWeight: price.font.bold ? 'bold' : 'normal',
                                fontStyle: price.font.italic ? 'italic' : 'normal',
                                visibility: price.show ? '' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                            >{price.example}</div>
                        </Draggable>
                        <Draggable {...dragHandlers} bounds="parent" name="iva" position={{ x: iva.x, y: iva.y }} onDrag={this.onControlledDragStop} >
                            <div
                              name="iva"
                              className={{
                                box: true,
                                'apply-font-iva': true,
                                grid, 
                              }}
                              style={{
                                fontSize: `${iva.font.size}px`,
                                color: iva.font.color,
                                fontWeight: iva.font.bold ? 'bold' : 'normal',
                                fontStyle: iva.font.italic ? 'italic' : 'normal',
                                visibility: iva.show ? '' : 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                            >{iva.example}</div>
                        </Draggable>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
