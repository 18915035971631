import React from "react";
import classNames from 'classnames';

const Input = props => {
  const { inputType, handleChange, className, form, title, icon, name, value, placeholder, ...rest } = props;
  const classname = form ? "form-group" : "";

  return (
    <div className={classname}>
      {title ? (<label htmlFor={name} className="form-label">{title}</label>) : null}
      <div className="input-wrapper">
        {icon && (
          <div className="input-icon">
            {icon}
          </div>
        )}
        <input
          className={classNames(
            'form-control',
            { 'with-icon': icon },
            className
          )}
          id={name}
          name={name}
          type={inputType}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Input;