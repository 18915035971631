import React, { useState } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

const DateTime = (props) => {
  const [value, onChange] = useState([props.value[0] || new Date(), props.value[1] || new Date()]);

  const localOnChange = (value) => {
    if (typeof props.onChange == 'function') {
        props.onChange(value)
    }
    onChange(value)
  }

  return (<DateRangePicker maxDate={props.maxDate} clearIcon={null} onChange={localOnChange} value={value} />);
};

export default DateTime;
