import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { confirmAlert } from 'react-confirm-alert';

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import ReactTable from "@components/ReactTable";
import { MdVisibility, MdPrint, MdCheck } from "react-icons/md";

import { EventBus as eventBus } from "@components/EventBus";

import UserContext from "@components/context/userContext";

import "./list.css";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  
  constructor(props) {
    super(props);

    this.intervalDocs = null;

    this.state = {
      loaded: false,
      search: '',
      board: {
        columns: []
      },
      search: "",
      posto: {},
      postos: [],
      lastTask: 0,
      apiResponse: true,
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleCardMove = this.handleCardMove.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.taskCard = this.taskCard.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.intervalDocs);
    this.intervalDocs = null;
  }

  taskCard(task, allowClose) {
    return({
        id: task.idtask,
        task: task,
        title:
            (this.state.posto.value == 0 ?
            `[${(this.state.postos.find(({value}) => value == task.posto) || {}).label}] FT ${task.posto}${task.tpv}${task.serie}/${task.talao}` :
            `FT ${task.posto}${task.tpv}${task.serie}/${task.talao}`) + (task.doc && task.doc.conta > 1 ? ` (${task.linha}/${task.doc.conta})` : ''),
        description: (
        <table style={{ width: '100%', textAlign: 'center' }}>
            {allowClose ? (
                <tr>
                    <td colspan="2" style={{ textAlign:'right'}}>
                        <table
                            style={{ cursor: "pointer", width: '100%', textAlign: 'center', color: 'green' }}
                            onClick={() => { this.finishTask(task.idtask) }}
                        >
                            <tr>
                                <td>
                                    <MdCheck style={{ fontSize: "20px" }} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "xx-small" }}>Concluir tarefa</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            ) : null}
            {task.doc && task.doc.cliente && (
                <tr>
                    <td colspan="2" style={{ textAlign:'left'}}><b>Cliente:</b> {task.doc.cliente}</td>
                </tr>
            )}
            {task.doc && task.doc.total && (
                <tr>
                    <td colspan="2" style={{ textAlign:'left'}}><b>Total:</b> {parseFloat(task.doc.total).toFixed(2)} €</td>
                </tr>
            )}
            {task.doc && task.doc.quantidade && task.doc.designacao && (
                <>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="2" style={{ textAlign:'left'}}>{task.doc.quantidade}x {task.doc.designacao}</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                </>
            )}
            {task.doc && (
                <tr>
                    {task.doc.payment ? (
                        <td colspan="2" style={{ textAlign:'left' }}>PAGO</td>
                    ) : (
                        <td colSpan="2" style={{ textAlign:'left', color: 'red' }}>NÃO PAGO</td>
                    )}
                </tr>
            )}
        </table>
        )
    });
  }

  loadTasks({ forceUpdate } = { forceUpdate: true }) {
    if (!this.state.apiResponse) {
        return;
    }
    this.setState({
        apiResponse: false
    })
    const profile = this.context;
    axios
        .get(
            `https://eposgestofinal.pt/api/tasks/get.php?idempresa=${profile.idEmpresaSec}&posto=${this.state.posto.value}`
        )
        .then((response) => {
            const tasks = response.data !== "NOK" ? response.data : [];
            const lastTask = tasks[0] || {};

            if (forceUpdate || this.state.lastTask != lastTask.idtask ) {
                if (!forceUpdate) {
                    eventBus.$emit("Loading", { active: true });
                    
                }
                const todo = tasks.filter((task) => task.status === 'TODO');
                const ready = tasks.filter((task) => task.status === 'READY');
                const deliver = tasks.filter((task) => task.status === 'DELIVERED');

                this.setState({
                    board: {
                        columns: [
                        {
                            id: 1,
                            title: `Por fazer (${todo.length})`,
                            backgroundColor: "#fff",
                            cards: todo.map((task) => this.taskCard(task))
                        },
                        {
                            id: 2,
                            title: `Pronto (${ready.length})`,
                            cards: ready.map((task) => this.taskCard(task))
                        },
                        {
                            id: 3,
                            title: `Entregue (${deliver.length})`,
                            cards: deliver.map((task) => this.taskCard(task, true))
                        }
                        ]
                    },
                    lastTask: lastTask.idtask,
                    loaded: true,
                });
            }

            eventBus.$emit("Loading", { active: false });
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            this.setState({
                apiResponse: true
            })
        });
  }

  loadFunction() {
    if (!this.state.loaded) {
      const { history } = this.props;
      const profile = this.context;

      axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresaSec}`)
      .then(response => {
          const postos = response.data !== 'NOK' ? response.data : [];

          const enrichedPostos = postos.map((posto) => ({ value: posto.posto, label: posto.nome === 'Backoffice' ? 'TODOS' : posto.nome }));

          this.setState({ postos: enrichedPostos, posto: enrichedPostos[0], loaded: true }, () => {
            this.loadTasks();
            this.getSyncTasks();
          });
          
      })
      .catch(error => {
          console.error(error);
      });
    }
  }

  getSyncTasks() {
    this.intervalDocs = setInterval(() => {
        this.loadTasks({ forceUpdate: false });
    }, 2000)
  }

  filterData(searchTerm) {
    this.setState({ search: searchTerm });
  }

  finishTask(id) {
    confirmAlert({
        title: 'Confirmação',
        message: 'Tem a certeza que deseja concluir a tarefa?',
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                 this.updateTask(id, 0)
              }
          },
          {
              label: 'Não',
              onClick: () => { }
          }
      ]
    });
  }

  updateTask(id, column) {
    const profile = this.context;

    let status;
    switch (column) {
        case 0:
            status = 'CLOSED';
            break;
        case 1:
            status = 'TODO';
            break;
        case 2:
            status = 'READY';
            break;
        case 3:
            status = 'DELIVERED';
            break;
        default:
            return;
    }
    axios.post('https://eposgestofinal.pt/api/tasks/set.php', {
        idtask: id,
        idempresa: profile.idEmpresa,
        status,
    })
    .then(() => {
        const todo = this.state.board.columns.find((column) => column.id === 1).cards.filter((card) => (card.id !== id) || (card.id === id && status !== 'CLOSED'));
        const ready = this.state.board.columns.find((column) => column.id === 2).cards.filter((card) => (card.id !== id) || (card.id === id && status !== 'CLOSED'));
        const deliver = this.state.board.columns.find((column) => column.id === 3).cards.filter((card) => (card.id !== id) || (card.id === id && status !== 'CLOSED'));

        this.setState({
            board: {
                columns: [
                  {
                    id: 1,
                    title: `Por fazer (${todo.length})`,
                    backgroundColor: "#fff",
                    cards: todo.map((card) => this.taskCard(card.task))
                  },
                  {
                    id: 2,
                    title: `Pronto (${ready.length})`,
                    cards: ready.map((card) => this.taskCard(card.task))
                  },
                  {
                    id: 3,
                    title: `Entregue (${deliver.length})`,
                    cards: deliver.map((card) => this.taskCard(card.task, true))
                  }
                ]
            },
            loaded: true,
          });
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleCardMove(card, source, destination) {
    const { board } = this.state;
  
    const updatedBoard = moveCard(board, source, destination);

    this.setState({
        board: updatedBoard,
    });

    this.updateTask(card.id, destination.toColumnId);
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    }, () => {
        switch (name) {
            case "posto":
                eventBus.$emit("Loading", { active: true });
                this.loadTasks()
                break;
            default:
                break;
            }
    });
  }

  render() {
    const { board, search, posto, postos } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterBoard = {
        columns: board.columns.map((column) => {
            return {
                ...column,
                cards: column.cards.filter(({ title, task }) =>
                    title.toLowerCase().includes(search.toLowerCase()) ||
                    (task.doc.designacao || '').toLowerCase().includes(search.toLowerCase()) ||
                    (task.doc.cliente || '').toLowerCase().includes(search.toLowerCase())
                )
            }
        }) 
    }

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Tarefas</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />

        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Tarefas
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Posto</th>
                    <th className="text-center">Pesquisa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>
                        <AutoComplete
                            name={"posto"}
                            value={posto}
                            handleChange={this.handleChange}
                            options={postos}
                        />
                    </td>
                    <td style={{ width: "200px" }}>
                        <Input
                            inputType={"text"}
                            name={"search"}
                            value={search}
                            placeholder="pesquisa..."
                            handleChange={(e) => this.filterData(e.target.value)}
                        />
                    </td>
                  </tr>
                </tbody>
              </table>
                <div className="board">
                    <Board
                        onCardDragEnd={this.handleCardMove}
                        disableColumnDrag
                    >
                        {filterBoard}
                    </Board>
                </div>
            </div>
        </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
