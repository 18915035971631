import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { MdVisibility } from "react-icons/md";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import ReactTable from "@components/ReactTable";

import Print from './print'

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import DateRangePicker from "@components/DateRangePicker";

import { TimestampToDate, dateToTimestamp } from "@utils/date";
import { threeDecimal } from "@utils/format";

import UserContext from "@components/context/userContext";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      table: {
        columns: [
          {
            Header: "Data",
            accessor: "date",
          },
          {
            Header: "Posto",
            id: "posto",
            accessor: (d) => d.posto_name || d.posto,
          },
          {
            Header: "Serie",
            accessor: "serie",
          },
          {
            Header: "TPV",
            accessor: "tpv",
          },
          {
            Header: "Talao",
            accessor: "talao",
          },
          {
            Header: "Cliente",
            accessor: "customer_name",
          },
          {
            Header: "Matricula",
            accessor: "matricula",
          },
          {
            Header: "Km Atuais",
            accessor: "kilometros",
          },
          {
            Header: "Requisição",
            accessor: "requisicao",
          },
          {
            Header: "Quantidade",
            accessor: "total",
            excel: false,
          },
          {
            Header: "",
            accessor: "view",
            excel: false,
          },
        ],
        rows: [],
        loading: true,
      },
      search: JSON.parse(localStorage.getItem("guias.search")) || "",
      posto: JSON.parse(localStorage.getItem("guias.posto")) || {
        value: "",
        label: "TODOS",
      },
      serie: JSON.parse(localStorage.getItem("guias.serie")) || {
        value: "",
        label: "TODOS",
      },
      tpv: JSON.parse(localStorage.getItem("guias.tpv")) || {
        value: "",
        label: "TODOS",
      },
      cliente: JSON.parse(localStorage.getItem("guias.cliente")) || {
        value: "",
        label: "TODOS",
      },
      date: [(new Date()).setHours(0 , 0 , 0) , (new Date()).setHours (23 , 59 , 59)],
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.localDateChange = this.localDateChange.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      const { history } = this.props;
      const { date } = this.state;

      axios
        .get(
          `https://eposgestofinal.pt/api/guias/get.php?idempresa=${profile.idEmpresaSec}&start_date=${date[0]/1000}&end_date=${date[1]/1000}`
        )
        .then((response) => {
          const guias = response.data !== "NOK" ? response.data : [];
          const postos = [];
          const tpvs = [];
          const series = [];
          const clientes = [];

          const enrichedGuias = guias.map((guia) => {
            if (!postos.find((posto) => posto.value === guia.posto)) {
              postos.push({ value: guia.posto, label: guia.posto_name },);
            }
            if (!series.find((serie) => serie.value === guia.serie)) {
              series.push({ value: guia.serie, label: guia.serie },);
            }
            if (!tpvs.find((tpv) => tpv.value === guia.tpv)) {
              tpvs.push({ value: guia.tpv, label: guia.tpv },);
            }
            if (!clientes.find((cliente) => cliente.value === guia.cliente) && (guia.customer_name || guia.nome || guia.cliente) && !!guia.cliente) {
              clientes.push({ value: guia.cliente, label: guia.customer_name || guia.nome || guia.cliente });
            }
            return {
              ...guia,
              date: TimestampToDate(guia.date_time),
              doc: guia.doc,
              customer_name: guia.customer_name || guia.nome,
              kilometros: guia.kilometros === '-2147483648' ? '' : guia.kilometros,
              requisicao: guia.requisicao === '-2147483648' ? '' : guia.requisicao,
              total: threeDecimal(guia.total),
              view: (
                <MdVisibility
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={() => history.push(`/guias/${guia.doc}`)}
                />
              ),
            };
          });

          this.setState({
            postos: [
              { value: "", label: "TODOS" },
              ...postos,
            ],
            series: [
              { value: "", label: "TODOS" },
              ...series,
            ],
            tpvs: [
              { value: "", label: "TODOS" },
              ...tpvs,
            ],
            clientes: [
              { value: "", label: "TODOS" },
              ...clientes,
            ],
            table: {
              ...this.state.table,
              rows: enrichedGuias,
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    }, () => {
        switch (name) {
            case "posto":
            case "serie":
            case "tpv":
            case "cliente":
            case "search":
                localStorage.setItem(`guias.${name}`, JSON.stringify(value));
                break;
            default:
                break;
            }
    });
  }

  localDateChange(date) {
    this.setState({ date: [
        date[0].setHours(0 , 0 , 0),
        date[1].setHours(23 , 59 , 59),
      ],
      loaded: false,
      table: {
        ...this.state.table,
        loading: true,
      }
    }, this.loadFunction());
  }

  render() {
    const { table, posto, postos, serie, series, tpv, tpvs, cliente, clientes, search, date, posto_name } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

    const filteredTable = {
      ...table,
      rows: table.rows
        .filter((guia) =>
          posto.value
            ? guia.posto === posto.value
            : true
        )
        .filter((guia) =>
          serie.value
            ? guia.serie === serie.value
            : true
        )
        .filter((guia) =>
          tpv.value
            ? guia.tpv === tpv.value
            : true
        )
        .filter((guia) =>
          cliente.value
            ? guia.cliente === cliente.value
            : true
        )
        .filter((guia) =>
          filterSearch.every(
            (eachSearch) =>
              (guia.doc || '')
                .toLowerCase()
                .includes(eachSearch.toLowerCase()) ||
              (guia.customer_name || '')
                .toLowerCase()
                .includes(eachSearch.toLowerCase()) ||
              (guia.matricula || '')
                .toLowerCase()
                .includes(eachSearch.toLowerCase())
          )
        )
    };

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Guias</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Guias
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Data</th>
                    <th className="text-center">Posto</th>
                    <th className="text-center">Serie</th>
                    <th className="text-center">TPV</th>
                    <th className="text-center">Cliente</th>
                    <th className="text-center">Pesquisa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>
                      <DateRangePicker maxDate={new Date()} value={date} onChange={this.localDateChange} />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"posto"}
                        value={posto}
                        handleChange={this.handleChange}
                        options={postos}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"serie"}
                        value={serie}
                        handleChange={this.handleChange}
                        options={series}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"tpv"}
                        value={tpv}
                        handleChange={this.handleChange}
                        options={tpvs}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"cliente"}
                        value={cliente}
                        handleChange={this.handleChange}
                        options={clientes}
                      />
                    </td>
                    <td>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        handleChange={this.handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                  <tbody>
                      <tr>
                          <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                            <Print
                                table={ filteredTable }
                                date={ date }
                                posto={ posto }
                                serie={ serie }
                                tpv={ tpv }
                                cliente={ cliente }
                                search={ search }
                            />
                          </td>
                      </tr>
                  </tbody>
              </table>
              <table style={{ marginBottom: 0 }} className="table">
                  <tbody>
                      <tr>
                          <td  style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                          Total
                          <h4>{threeDecimal(filteredTable.rows.reduce((acc, row) => acc + parseFloat(row.total), 0))}</h4>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <ReactTable
                columns={filteredTable.columns}
                sortable={true}
                loading={filteredTable.loading}
                data={filteredTable.rows}
                excelDownload={true}
                excelTotal={"quantity"}
                excelType={"lines"}
                excelLinesExtraDetails={[
                  {
                    label: "Produto",
                    value: "product_name"
                  },
                  {
                    label: "Quantidade",
                    value: "quantity"
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
