import axios from "axios";

const ePOSCookieName = "ePOS"; // Replace with the actual cookie name

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "X-Epos-Auth": getEPOSCookie(), // Add custom header
  },
});

// Add a request interceptor to update the header before each request
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["X-Epos-Auth"] = getEPOSCookie();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response; // Pass through successful responses
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);

function getEPOSCookie() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // Trim leading/trailing whitespace
    const [name, value] = cookie.split("=");
    if (name === ePOSCookieName) {
      return value;
    }
  }
  return null; // Return null if cookie not found
}

function handleUnauthorized() {
  clearCookies();
  window.location.href = "https://eposgestofinal.pt/index.php?logout=1";
}

function clearCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" +
      window.location.hostname;
  }
}

export default axiosInstance;