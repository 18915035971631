import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { addDays, getMonthData, getMonthName } from './dateUtils';

const DatePickerWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '16px',
  width: 'fit-content',
  minWidth: '280px',
}));

const CalendarHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

const MonthYearDisplay = styled('div')({
  fontSize: '1rem',
  fontWeight: 500,
});

const WeekDaysContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gap: '4px',
  marginBottom: '8px',
});

const WeekDay = styled('div')({
  textAlign: 'center',
  fontSize: '0.75rem',
  color: '#666',
  padding: '4px',
});

const DaysGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gap: '4px',
});

const DayButton = styled('button')(({ isSelected, isToday, isDisabled }) => ({
  width: '100%',
  aspectRatio: '1',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: isSelected ? '#1976d2' : isToday ? '#e3f2fd' : 'transparent',
  color: isSelected ? '#fff' : isDisabled ? '#ccc' : '#000',
  cursor: isDisabled ? 'default' : 'pointer',
  '&:hover': {
    backgroundColor: isDisabled ? undefined : isSelected ? '#1565c0' : '#f5f5f5',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.2)',
  },
}));

const PeriodSelector = styled(FormControl)({
  marginBottom: '16px',
  '.MuiFormControlLabel-root': {
    marginLeft: 0,
    marginRight: 0,
  },
  '.MuiFormControlLabel-label': {
    fontSize: '0.875rem',
  },
});

const IconButton = styled('button')({
  background: 'none',
  border: 'none',
  padding: '8px',
  cursor: 'pointer',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#666',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: '#f5f5f5',
  },
});

const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

const Vencimento = ({ value, onChange }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('0');
  const [currentDate, setCurrentDate] = useState(new Date());
  
  const handlePeriodChange = (event) => {
    const days = parseInt(event.target.value);
    setSelectedPeriod(event.target.value);
    onChange(addDays(new Date(), days));
  };

  const handlePrevMonth = (e) => {
    e.stopPropagation();
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    setCurrentDate(newDate);
  };

  const handleNextMonth = (e) => {
    e.stopPropagation();
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  const handleDateClick = (date) => {
    if (date < new Date()) return;
    onChange(date);
  };

  const monthData = getMonthData(currentDate);
  const today = new Date();

  return (
    <DatePickerWrapper onClick={(e) => e.stopPropagation()}>
      <PeriodSelector>
        <RadioGroup
          row
          value={selectedPeriod}
          onChange={handlePeriodChange}
        >
          <FormControlLabel value="0" control={<Radio size="small" />} label="Pronto" />
          <FormControlLabel value="7" control={<Radio size="small" />} label="7 dias" />
          <FormControlLabel value="14" control={<Radio size="small" />} label="14 dias" />
          <FormControlLabel value="30" control={<Radio size="small" />} label="30 dias" />
          <FormControlLabel value="90" control={<Radio size="small" />} label="90 dias" />
        </RadioGroup>
      </PeriodSelector>

      <CalendarHeader>
        <IconButton onClick={handlePrevMonth}>
          <MdChevronLeft size={24} />
        </IconButton>
        <MonthYearDisplay>
          {getMonthName(currentDate)} {currentDate.getFullYear()}
        </MonthYearDisplay>
        <IconButton onClick={handleNextMonth}>
          <MdChevronRight size={24} />
        </IconButton>
      </CalendarHeader>

      <WeekDaysContainer>
        {weekDays.map(day => (
          <WeekDay key={day}>{day}</WeekDay>
        ))}
      </WeekDaysContainer>

      <DaysGrid>
        {monthData.map((date, index) => {
          if (!date) return <div key={index} />;
          
          const isSelected = value && 
            date.getDate() === value.getDate() &&
            date.getMonth() === value.getMonth() &&
            date.getFullYear() === value.getFullYear();
          
          const isToday = 
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
          
          const isDisabled = date < today;

          return (
            <DayButton
              key={index}
              onClick={() => handleDateClick(date)}
              isSelected={isSelected}
              isToday={isToday}
              isDisabled={isDisabled}
            >
              {date.getDate()}
            </DayButton>
          );
        })}
      </DaysGrid>
    </DatePickerWrapper>
  );
};

export default Vencimento;