import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import Menu from "@components/Menu";
import Button from "@components/Button";
import Checkbox from "@components/CheckBox";
import HorizontalTabs from "@components/HorizontalTabs";

import { currentDateTime } from "@utils/date";

import UserContext from "@components/context/userContext";

const defaultDate = currentDateTime();

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      warehouses: [],
      date: defaultDate,
      samePrices: false,
      products: [],
      values: {
        columns: [
          {
            label: "Data",
            field: "date",
            width: 200,
          },
          {
            label: "Produto",
            field: "product",
            width: 300,
          },
          {
            label: "Price",
            field: "price",
            width: 200,
          },
        ],
        rows: [],
      },
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const profile = this.context;

      Promise.all([
        axios
          .get(
            `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`
          )
          .then(async (response) => {
            const warehouses =
              response.data !== "NOK"
                ? response.data.reduce(
                    (accum, warehouse) => ({
                      ...accum,
                      [warehouse.posto]: warehouse,
                    }),
                    {}
                  )
                : {};

            await axios
              .get(
                `https://eposgestofinal.pt/api/products/posto/get.php?idempresa=${profile.idEmpresa}&combustivel=true&where=pv.sync='TRUE'`
              )
              .then(async (response) => {
                const postosWarehouses =
                  response.data !== "NOK" ? response.data : {};

                await this.setState({
                  warehouses: Object.keys(warehouses).map((warehouse) => ({
                    ...warehouses[warehouse],
                    date: defaultDate,
                    idposto: warehouse,
                    total: postosWarehouses[warehouse].total,
                    products: postosWarehouses[warehouse].items.map(
                      (product) => ({
                        ...product,
                      })
                    ),
                  })),
                });
              });
          }),
        axios
          .get(
            `https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}&combustivel=true`
          )
          .then(async (response) => {
            const products = response.data !== "NOK" ? response.data : [];

            await this.setState({
              products: products.map((product) => ({
                ...product,
                price: "",
              })),
            });
          }),
      ]).then(() => {
        this.setState({
          loaded: true,
        });
      });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      if (properties[4]) {
        newState[properties[0]][properties[1]][properties[2]][properties[3]][
          properties[4]
        ] = value;
      } else if (properties[2]) {
        newState[properties[0]][properties[1]][properties[2]] = value;
      }

      this.setState({
        ...newState,
      });
    } else {
      if (name === "samePrices") {
        this.setState({
          [name]: !newState[name],
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    }
  }

  executeSequentially(promiseLikeArray) {
    var result = Promise.resolve();
    promiseLikeArray.forEach(function (promiseLike) {
      result = result.then(promiseLike);
    });
    return result;
  }

  handleFormSubmit() {
    const { history } = this.props;

    const profile = this.context;
    const { warehouses } = this.state;

    if (this.state.samePrices) {
      this.state.products
        .filter(({ price }) => price !== "")
        .forEach((product) => {
          const existsInWarehouses = Object.keys(this.state.warehouses).reduce(
            (acc, warehouse) => {
              if (
                warehouses[warehouse].products.find(
                  (warehouseProduct) =>
                    warehouseProduct.idproduct === product.idproduct
                )
              ) {
                return [...acc, warehouse];
              }
              return acc;
            },
            []
          );
          if (existsInWarehouses.length) {
            axios
              .post("https://eposgestofinal.pt/api/products/price/set.php", {
                idempresa: profile.idEmpresa,
                idproduct: product.idproduct,
                values: product.values.map((value) => ({
                  ...value,
                  price: product.price,
                })),
              })
              .then(() => {
                history.push("/products/sync");
              });
          }
        });
    } else {
      const requestProducts = Object.keys(this.state.warehouses).reduce(
        (acc, warehouse) => {
          warehouses[warehouse].products.forEach((warehouseProduct) => {
            if (acc[warehouseProduct.idproduct]) {
              acc[warehouseProduct.idproduct] = [
                ...acc[warehouseProduct.idproduct],
                warehouseProduct,
              ];
            } else {
              acc = {
                ...acc,
                [warehouseProduct.idproduct]: [warehouseProduct],
              };
            }
          });
          return acc;
        },
        {}
      );

      const allRequests = [];

      Object.keys(requestProducts).map(async (productId) =>
        allRequests.push(
          () =>
            new Promise(function (myResolve, myReject) {
              axios
                .post("https://eposgestofinal.pt/api/products/price/set.php", {
                  idempresa: profile.idEmpresa,
                  idproduct: productId,
                  values: requestProducts[productId],
                })
                .then((result) => myResolve(result))
                .catch((err) => myReject(err));
            })
        )
      );

      new Promise(async (myResolve, myReject) => {
        await this.executeSequentially(allRequests);
        myResolve();
      }).then(() => {
        history.push("/products/sync");
      });
    }
  }

  render() {
    const { loaded, warehouses, samePrices, products } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const hydratedTabs = warehouses.reduce((acc, warehouse, index) => {
      acc[warehouse.posto] = {
        label: `[${warehouse.posto}] ${warehouse.nome}`,
        page: (
          <div style={{ textAlign: "left" }}>
            <table
              className="table table-bordered table-hover table-sortable"
              style={{ width: "100%", textAlign: "center" }}
            >
              <thead>
                <th style={{ width: "50%" }}>Produto</th>
                <th style={{ width: "10%" }}>Preço</th>
              </thead>
              <tbody>
                {warehouse.products.map((product, indexP) => (
                  <tr>
                    <td>{`[${product.idproduct}] ${product.name}`}</td>
                    <td>
                      <input
                        type="number"
                        key={`warehouses|${index}|products|${indexP}|price`}
                        name={`warehouses|${index}|products|${indexP}|price`}
                        value={product.price}
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ),
      };

      return acc;
    }, {});

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>Preços</li>
              <li className="active">Combustiveis</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            {loaded && (
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  style={{
                    fontWeight: "bold",
                    fontSize: "15pt",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: '15px'
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Preços Combustiveis
                  </div>
                </div>
                <table className="table table-bordered table-hover table-sortable">
                  <tbody>
                    <tr>
                      <td>
                        <Checkbox
                          key={"samePrices"}
                          name={"samePrices"}
                          title={"Aplicar preços iguais em todos os postos"}
                          checked={samePrices}
                          handleChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  {samePrices ? (
                    <div style={{ textAlign: "left" }}>
                      <table
                        className="table table-bordered table-hover table-sortable"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <thead>
                          <th style={{ width: "50%" }}>Produto</th>
                          <th style={{ width: "10%" }}>Preço</th>
                        </thead>
                        <tbody>
                          {products.map((product, indexP) => (
                            <tr>
                              <td>{`[${product.idproduct}] ${product.nome}`}</td>
                              <td>
                                <input
                                  type="number"
                                  key={`products|${indexP}|price`}
                                  name={`products|${indexP}|price`}
                                  value={product.price}
                                  onChange={this.handleChange}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <HorizontalTabs
                      tabs={{
                        ...hydratedTabs,
                      }}
                    />
                  )}
                </div>
                <table className="table table-bordered table-hover table-sortable">
                  <tbody>
                    <tr>
                      <td>
                        <Button
                          action={this.handleFormSubmit}
                          type={"success"}
                          title={"Guardar e Sincronizar"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
