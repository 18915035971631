import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import "react-confirm-alert/src/react-confirm-alert.css";
import { MdAddCircleOutline, MdVisibility, MdError } from "react-icons/md";
import ReactTable from "@components/ReactTable";

// Components
import Menu from "@components/Menu";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import Modal from "@components/Modal";
import DateRangePicker from "@components/DateRangePicker";
import Checkbox from "@components/CheckBox";

import { currentDate, TimestampToDate } from '@utils/date'
import { threeDecimal } from "@utils/format";
import { totals } from "./utils/totals";

import { EventBus as eventBus } from "@components/EventBus";

const subDays = (date, days) => {
  date.setDate(date.getDate() - days);
};

class Page extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      doctype: JSON.parse(localStorage.getItem("orders.doctype")) || {
        value: '',
        label: 'TODOS',
      },
      doctypes: [
        {
          value: "",
          label: "TODOS",
        },
        {
          value: "orders",
          label: "Compras",
        },
        {
          value: "returns",
          label: "Devoluções",
        },
        {
          value: "transfer",
          label: "Transferências",
        },
        {
          value: "inventory",
          label: "Inventário",
        },
        {
          value: "invoices",
          label: "Faturas",
        },
      ],
      table: {
        columns: [
          {
            Header: "Data",
            accessor: "date",
          },
          {
            Header: "Fornecedor",
            accessor: "provider_name",
          },
          {
            Header: "Nº Documento",
            accessor: "doc",
          },
          {
            Header: "Tipo Doc",
            accessor: "doctypelabel",
          },
          {
            Header: "Total",
            accessor: "total",
          },
          {
            Header: "Anulada",
            accessor: "deleted",
          },
          {
            Header: "",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      search: JSON.parse(localStorage.getItem("orders.search")) || "",
      idprovider: JSON.parse(localStorage.getItem("orders.idprovider")) || {
        value: "",
        label: "TODOS",
      },
      date: [new Date().setHours(0 , 0 , 0) , new Date().setHours (23 , 59 , 59)],
      useDate: false,
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.localDateChange = this.localDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const { history } = this.props;
      const { date, useDate } = this.state;

      axios.get(`https://eposgestofinal.pt/api/providers/get.php?idempresa=${this.props.cookies.get(
        "member_idempresa"
      )}`)
        .then(response => {
            const providers = response.data !== 'NOK' ? response.data : [];

            this.setState({ providers: [
                {
                  value: "",
                  label: "TODOS",
                },
                {
                    value: '-1',
                    label: 'Indiferenciado'
                },
                ...providers.map((provider) => ({ ...provider, value: provider.idprovider, label: provider.name }))
            ], loaded: true });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          useDate ? `https://eposgestofinal.pt/api/orders/get.php?idempresa=${this.props.cookies.get(
            "member_idempresa"
          )}&start_date=${date[0]/1000}&end_date=${date[1]/1000}` : `https://eposgestofinal.pt/api/orders/get.php?idempresa=${this.props.cookies.get(
            "member_idempresa"
          )}`
        )
        .then((response) => {
          const orders = response.data !== "NOK" ? response.data : [];

          const enrichedOrders = orders.map((order) => {
            const results = totals(order.lines, order.doctype);
            const total = results.total;

            return {
              ...order,
              date: TimestampToDate(order.date_time),
              doctypelabel: (
                this.state.doctypes.find(
                  (docType) => docType.value === order.doctype
                ) || {}
              ).label,
              total: threeDecimal(total),
              view: (
                <>
                  {(order.errors || []).length > 0 ? <MdError
                    style={{ fontSize: "15px", color: '#CCCC00', cursor: "pointer" }}
                    onClick={() => eventBus.$emit("Modal-errors", { header: "Erros", body: order.errors.map((error) => (<p>- {error}</p>)) })}
                  /> : null}
                  <MdVisibility
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => history.push(`/orders/${order.idorder}`)}
                  />
                </>
              ),
              deleted: order.valid ? "" : "TRUE",
            };
          });

          this.setState({
            table: {
              ...this.state.table,
              rows: enrichedOrders,
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    }, () => {
        switch (name) {
            // case "date":
            case "doctype":
            case "idprovider":
            case "search":
                localStorage.setItem(`orders.${name}`, JSON.stringify(value));
                break;
            default:
                break;
            }
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
      [name]: value,
      loaded: false,
      table: {
        ...this.state.table,
        loading: true,
      }
    }, this.loadFunction());
  }

  localDateChange(date) {
    this.setState({ date: [
        date[0].setHours(0 , 0 , 0),
        date[1].setHours(23 , 59 , 59),
      ],
      useDate: true,
      loaded: false,
      table: {
        ...this.state.table,
        loading: true,
      }
    }, this.loadFunction());
  }

  render() {
    const { table, search, providers, idprovider, doctypes, doctype, useDate, date } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

console.log(date);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Compras</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Compras
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/orders/new")}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Criar documento</td>
                    </tr>
                  </table>
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    {/* <th className="text-center">Data</th> */}
                    <th className="text-center">Fornecedor</th>
                    <th className="text-center">Tipo doc</th>
                    <th className="text-center">Data</th>
                    <th className="text-center">Pesquisa doc</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "400px" }}>
                      <AutoComplete
                        name={"idprovider"}
                        value={idprovider}
                        handleChange={this.handleChange}
                        options={providers}
                      />
                    </td>
                    <td style={{ width: "400px" }}>
                      <AutoComplete
                        name={"doctype"}
                        value={doctype}
                        handleChange={this.handleChange}
                        options={doctypes}
                      />
                    </td>
                    <td style={{ width: "260px" }}>
                      <Checkbox
                        name={"useDate"}
                        checked={useDate}
                        position={"left"}
                        title={(<DateRangePicker maxDate={new Date()} value={date} onChange={this.localDateChange} />)}
                        handleChange={this.handleCheckboxChange}
                      />
                      
                    </td>
                    <td>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        handleChange={this.handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={table.rows.filter((order) =>
                  doctype.value
                    ? doctype.value == order.doctype
                    : true
                )
                .filter((order) =>
                  idprovider.value
                    ? [idprovider.value, idprovider.idapollo].includes(order.idprovider)
                    : true
                )
                .filter((order) =>
                  filterSearch.every(
                    (eachSearch) =>
                      order.doc
                        .toLowerCase()
                        .includes(eachSearch.toLowerCase())
                  )
                )}
              />
            </div>
          </div>
        </div>
        <Modal id="errors" />
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
