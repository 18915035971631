import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import axios from 'axios';

import 'react-confirm-alert/src/react-confirm-alert.css';

// Components
import Menu from '@components/Menu'
import ReactTable from '@components/ReactTable';
import { Home } from '@material-ui/icons';
import { MdEdit, MdViewList } from "react-icons/md";

const pastSeconds = (seconds) => {
    var t = new Date();
    t.setSeconds(t.getSeconds() - seconds);

    return t.getTime();
}

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            adminProfile: false,
            table: {
                columns: [
                    {
                        Header: 'Mac',
                        accessor: 'macaddress',
                        show: this.props.profile.adminProfile,
                    },
                    {
                        Header: 'IP Privado',
                        accessor: 'private_ip',
                        show: this.props.profile.adminProfile,
                    },
                    {
                        Header: 'IP Público',
                        accessor: 'public_ip',
                        show: this.props.profile.adminProfile,
                    },
                    {
                        Header: 'Empresa',
                        accessor: 'empresa',
                        show: this.props.profile.adminProfile,
                    },
                    {
                      Header: 'Posto',
                      accessor: 'posto_nome',
                    },
                    {
                        Header: "Estado",
                        accessor: "status",
                    },
                    {
                        Header: '',
                        accessor: 'actions',
                    }
                ],
                rows: [],
                loading: true,
            },
        };
        this.loadFunction = this.loadFunction.bind(this);
        this.getRaspberry = this.getRaspberry.bind(this);
    }
  
    getRaspberry({ idEmpresa, adminProfile }) {
        const { history } = this.props;

        const filter = adminProfile ? 'api/psa/get.php' : `api/psa/empresa/get.php?idempresa=${idEmpresa}`;

        return axios.get(`https://eposgestofinal.pt/${filter}`)
            .then(response => {
                const stations = response.data !== 'NOK' ? response.data : [];

                const lastSeconds = pastSeconds(10);

                const enrichedStations = stations.map((station) => ({
                    ...station,
                    empresa: (this.state.empresas.find((empresa) => empresa.idempresa === station.idempresa) || {}).nomeempresa,
                    posto_nome: station.idposto ? `[${station.idposto}] ${station.posto_nome}` : '',
                    status: (new Date(station['checked_at']).getTime() > lastSeconds ? (
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            color: 'green',
                            justifyContent: 'center'
                        }}><Home /> <span >Online</span></div>
                    ) : (
                        <div style={{ color: 'red' }}><Home /> <span>Offline</span></div>
                    )),
                    actions: (
                        <div className="actions">
                            <MdViewList style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => history.push(`/psa/${station.idpsa}/logs`) } />
                            {/* <MdVisibility style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => history.push(`/stations/${station.id}`) } /> */}
                            <MdEdit style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => history.push(`/psa/${station.idpsa}/config`) } />
                        </div>
                    ),
                }))

                this.setState({ adminProfile, table: { ...this.state.table , rows: enrichedStations, loading: false }, loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
    }

    loadFunction(profile) {
        if (!this.state.loaded) {
            if (window.autoRefresh) {
                clearInterval(window.autoRefresh);
            }
            axios.get('https://eposgestofinal.pt/api/empresas/get.php?combustivel=true')
            .then(response => {
                const empresas = response.data !== 'NOK' ? response.data : [];

                this.setState({ empresas, loaded: true });

                this.getRaspberry(profile)
                    .then(() => {
                        window.autoRefresh = setInterval(() => {
                            this.getRaspberry(profile);
                        }, 5000);
                    })
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
    
    render () {
        const { table } = this.state;
        const { location, history } = this.props;

        return (
            <div>
                <Menu loadFunction={ this.loadFunction } location={ location } history={ history } />
                <div className="fullContainer" style={{ maxWidth: '100%' }}>
                    <div className="col-xs-12">
                        <ol
                            style={{
                                textAlign: 'left',
                                'marginTop': '75px',
                            }}
                            className="breadcrumb"
                        >
                            <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                            <li className="active">Postos de Combustível</li>
                        </ol>
                    </div>
                </div>
                <div
                    className="fullContainer" style={{ maxWidth: '100%' }}
                >
                    <div
                        className="col-sm-12"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '15pt',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                    }}
                                >
                                    Postos de Combustível
                                </div>
                            </div>
                            <ReactTable
                                columns={ table.columns }
                                sortable={ true }
                                loading={ table.loading }
                                data={ table.rows }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Page);