import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Menu from '@components/Menu';
import { MdPrint, MdSend } from "react-icons/md";
import PDFPrint from "@components/PdfPrint";
// import InvoiceTemplate from '@components/InvoiceTemplate';
import UserContext from '@components/context/userContext';

const InvoiceDetails = () => {
  const [hasIsp, setHasIsp] = useState(false);
  const [isps, setIsps] = useState([]);
  const [sc, setSc] = useState([]);
  const [redIsp, setRedIsp] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [posto, setPosto] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { doctype, serie, doc } = useParams();
  const history = useHistory();
  const profile = useContext(UserContext);

  const printerRef = useRef(null);

  const payments = [
    { value: 1, label: "Dinheiro" },
    { value: 2, label: "Crédito" },
    { value: 3, label: "Cheque" },
    { value: 4, label: "TPA" },
    { value: 8, label: "Frota" },
    { value: 14, label: "Serviços Internos" },
  ];

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        // Get the invoice details directly using the document information
        const response = await axios.get(
          `https://eposgestofinal.pt/api/facturas/getById.php?idempresa=${profile.idEmpresa}&type=${doctype}&serie=${serie}&numero=${doc}`
        );

        if (!response.data || response.data === 'NOK' || Object.keys(response.data) === 0) {
          throw new Error('Failed to find invoice');
        }

        const invoiceData = response.data;

        invoiceData.idcustomer = invoiceData.cliente;
        invoiceData.idposto = invoiceData.armazem;
        invoiceData.payment = invoiceData['cod_forma_pag'] ? parseInt(invoiceData['cod_forma_pag']) : 2;
        invoiceData.payment_method = payments.find(p => p.value === invoiceData.payment);

        if (invoiceData.isp.isp) {
          setHasIsp(true);
          setIsps(invoiceData.isp.isp);
          setSc(invoiceData.isp.sobrecusto_biocombustivel);
          setRedIsp(invoiceData.isp.reducao_isp);
        }

        // Fetch customer details
        if (invoiceData.cliente) {
          const customerResponse = await axios.get(
            `https://eposgestofinal.pt/api/customers/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${invoiceData.cliente}`
          );
          if (customerResponse.data && customerResponse.data.length > 0) {
            setCustomer(customerResponse.data[0]);
          }
        }

        // Fetch posto details
        if (invoiceData.armazem) {
          const postoResponse = await axios.get(
            `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`
          );
          if (postoResponse.data && postoResponse.data.length > 0) {

            setPosto(postoResponse.data.find(({posto}) => posto == invoiceData.armazem));
          }
        }

        setInvoice(invoiceData);
      } catch (err) {
        console.error('Error fetching invoice details:', err);
        setError(err.message || 'Error fetching invoice details');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceDetails();
  }, [profile.idEmpresa, doctype, serie, doc]);

  const handlePrint = async () => {
    await setPreviewUrl(`https://eposgestofinal.pt/api/facturas/pdf.php?idempresa=${profile.idEmpresa}&type=${invoice.type}&serie=${invoice.serie}&numero=${invoice.numero}`);
    // eslint-disable-next-line no-unused-expressions
    printerRef.current?.handlePrint();
  };

  const handleEmail = async () => {
    let email = prompt("Email:", customer.email);
    
    if (email) {
      //post
      const response = await axios.post(
        `https://eposgestofinal.pt/api/facturas/email.php`,
        {
          idempresa: profile.idEmpresa,
          type: invoice.type,
          serie: invoice.serie,
          numero: invoice.numero,
          email: email
        }
      );
      if (response.data === 'OK') {
        alert('Email enviado com sucesso');
      } else {
        alert('Erro ao enviar email');
      }
    }
  };

  if (loading) {
    return (
      <div>
        <Menu />
        <div className="container">
          <div className="text-center">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Menu />
        <div className="container">
          <div className="alert alert-danger">{error}</div>
        </div>
      </div>
    );
  }

  if (!invoice) {
    return (
      <div>
        <Menu />
        <div className="container">
          <div className="alert alert-warning">Fatura não encontrada</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Menu />
      <div className="fullContainer" style={{ maxWidth: '100%' }}>
        <div className="col-xs-12">
          <ol
            style={{
              textAlign: 'left',
              marginTop: '75px',
            }}
            className="breadcrumb"
          >
            <li>
              <a href="http://eposgestofinal.pt/index.php">Portal</a>
            </li>
            <li>
              <a href="/sales">Faturação</a>
            </li>
            <li className="active">
              <span>Detalhes da Fatura</span>
            </li>
          </ol>
        </div>
      </div>

      <div className="fullContainer" style={{ marginTop: '25px' }}>
        <div className="col-sm-12" style={{ textAlign: 'center' }}>
          <div className="panel panel-default">
            <div className="container-fluid">
              <div className="invoice-panel">
                <div className="header">
                  <h1 className="title">Detalhes da Fatura</h1>
                </div>

                <div className="form-grid">
                  <div className="form-group">
                    <label className="form-label">Cliente</label>
                    {(customer?.name || 'Cliente Final') + ' ' + (invoice?.nif != 999999990 ? `[NIF: ${invoice?.nif}]` : '') }
                  </div>

                  <div className="form-group">
                    <label className="form-label">Documento</label>
                    {invoice.type == 'facturas' ? 'FT' : 'FR'} {invoice.serie}/{invoice.numero}
                  </div>

                  {invoice.anulado && (<div className="form-group">
                    <label className="form-label">Estado</label>
                    ANULADO
                  </div>)}

                  <div className="form-group">
                    <label className="form-label">Vencimento</label>
                    {invoice.data_vencimento ? invoice?.data_vencimento || '' : new Date(invoice.date_time * 1000).toLocaleString('pt-PT', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    }).slice(0, 10)}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Data e Hora</label>
                    {new Date(invoice.date_time * 1000).toLocaleString('pt-PT', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Método de Pagamento</label>
                    {invoice.payment_method.label}
                  </div>
                </div>

                <div className="products-section">
                  <h2 className="products-title">Produtos</h2>

                  <div className="product-grid" style={{ gridTemplateColumns: '1.5fr repeat(6, 1fr)' }}>
                    <div className="form-label">Posto</div>
                    <div className="form-label">Produto</div>
                    <div className="form-label">Quantidade</div>
                    <div className="form-label">Preço</div>
                    <div className="form-label">Desc %</div>
                    <div className="form-label">Desc €</div>
                    <div className="form-label">IVA</div>
                    <div className="form-label">Total</div>
                  </div>

                  {invoice.lines.map((line, index) => (
                    <div key={index} className="product-grid" style={{ gridTemplateColumns: '1.5fr repeat(6, 1fr)' }}>
                      <div>[{line.armazem}] {line.posto_name}</div>
                      <div>[{line.produto}] {line.designacao}</div>
                      <div>{line.quantidade}</div>
                      <div>{parseFloat(line['preco_sem_iva'])?.toFixed(3)}</div>
                      <div>{parseFloat(line['desconto_per'])?.toFixed(2) || '0.00'}</div>
                      <div>{parseFloat(line.valor_desconto)?.toFixed(3) || '0.00'}</div>
                      <div>{line.taxa_iva}%</div>
                      <div>{parseFloat(line.valor_liquido)?.toFixed(2)} €</div>
                    </div>
                  ))}
                </div>

                <div className="totals">
                  <div className="total-row">
                    Subtotal:{' '}
                    <span style={{ fontWeight: 500 }}>
                        {(invoice?.lines || []).reduce((subtotal, line) => subtotal + (parseFloat(line['preco_sem_iva']) * line['quantidade']), 0).toFixed(2)} €
                    </span>
                  </div>
                  <div className="total-row">
                    Total Descontos:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {parseFloat(invoice.valor_desconto)?.toFixed(2)} €
                    </span>
                  </div>
                  <div className="total-row">
                    Total IVA:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {parseFloat(invoice.valor_iva)?.toFixed(2)} €
                    </span>
                  </div>
                  <div className="total-row">
                    Total: {parseFloat(invoice.valor_liquido)?.toFixed(2)} €
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'flex-end',
                    marginTop: '2rem'
                  }}
                >
                  <button
                    type="button"
                    onClick={() => history.push('/sales')}
                    className="preview-button"
                  >
                    Voltar
                  </button>
                  <button type="button" onClick={handleEmail} className="submit-button">
                    <MdSend size={28} />&nbsp;&nbsp;Enviar por Email
                  </button>
                  <button type="button" onClick={handlePrint} className="submit-button">
                    <MdPrint size={28} />&nbsp;&nbsp;Imprimir
                  </button>
                </div>
                <PDFPrint ref={printerRef} pdfUrl={previewUrl} />

                {/* <InvoiceTemplate
                  open={previewOpen}
                  onClose={() => setPreviewOpen(false)}
                  invoice={invoice}
                  hasIsp={hasIsp}
                  isps={isps}
                  sc={sc}
                  redIsp={redIsp}
                  customer={customer}
                  posto={posto}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;