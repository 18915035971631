import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import axios from "axios";

import EditableTable from '@components/EditableTable'
import Input from "@components/Input";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import EmpresaSelect from "@components/EmpresaSelect";

// Components
import Menu from "@components/Menu";

import UserContext from '@components/context/userContext';

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      reducao_isp: '',
      isps: [],
      biofuels: [],
      loading: false,
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.onTableIspSubmit = this.onTableIspSubmit.bind(this);
    this.onTableBiofuelSubmit = this.onTableBiofuelSubmit.bind(this);
    this.onTableIspChange = this.onTableIspChange.bind(this);
    this.onTableBiofuelChange = this.onTableBiofuelChange.bind(this);
    this.uploadFunction = this.uploadFunction.bind(this);
    this.internalHandleChange = this.internalHandleChange.bind(this);
    this.internalHandleBlur = this.internalHandleBlur.bind(this);
  }

  onTableIspSubmit(id) {
    const profile = this.context;

    this.state.isps.map((value) => {
      if (value.id === id) {
        axios.post('https://eposgestofinal.pt/api/isp/set.php', {
          idempresa: profile.idEmpresaSec,
          ...value,
        })
      }
      return value;
    });
  }

  onTableBiofuelSubmit(id) {
    const profile = this.context;

    this.state.biofuels.map((value) => {
      if (value.id === id) {
        axios.post('https://eposgestofinal.pt/api/sobrecusto_biocombustivel/set.php', {
          idempresa: profile.idEmpresaSec,
          ...value,
        })
      }
      return value;
    });
  }

  loadFunction() {
    if (!this.state.loaded) {
      const profile = this.context;

      axios.get(`https://eposgestofinal.pt/api/reducao_isp/get.php?idempresa=${profile.idEmpresaSec}`)
        .then(response => {
            const reducao_isp = response.data !== 'NOK' ? response.data : [];

            this.setState({ reducao_isp: reducao_isp.length > 0 ? reducao_isp[0].reducao_isp : '', loaded: true });
        })
        .catch(error => {
          console.error(error);
        });
      axios.get(`https://eposgestofinal.pt/api/isp/get.php?idempresa=${profile.idEmpresaSec}`)
        .then(response => {
            const isps = response.data !== 'NOK' ? response.data : [];

            this.setState({ isps: isps.map((isp) => ({
                ...isp,
                id: isp.idisp,
            })), loaded: true });
        })
        .catch(error => {
          console.error(error);
        });
      axios.get(`https://eposgestofinal.pt/api/sobrecusto_biocombustivel/get.php?idempresa=${profile.idEmpresaSec}`)
        .then(response => {
            const biofuels = response.data !== 'NOK' ? response.data : [];

            this.setState({ biofuels: biofuels.map((biofuel) => ({
                ...biofuel,
                id: biofuel.idsobrecustobiocombustivel,
            })), loaded: true });
        })
        .catch(error => {
          console.error(error);
        });
      
    }
  }

  onTableIspChange(newValue, row, column) {
    const { isps } = this.state;
    const result = isps.map((value) => {
        if (value.id === row.id) {
            const newRow = { ...value };
            newRow[column.dataField] = newValue;
            return newRow;
        }
        return value;
    });
    this.setState({ isps: result }, () => this.onTableIspSubmit(row.id))
    return true;
  }

  onTableBiofuelChange(newValue, row, column) {
    const { biofuels } = this.state;
    const result = biofuels.map((value) => {
        if (value.id === row.id) {
            const newRow = { ...value };
            newRow[column.dataField] = newValue;
            return newRow;
        }
        return value;
    });
    this.setState({ biofuels: result }, () => this.onTableBiofuelSubmit(row.id))
    return true;
  }

  uploadFunction() {
    const profile = this.context;

    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja enviar todos os impostos para o Apollo?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            axios.post("https://eposgestofinal.pt/api/apollo/sync/taxes.php", {
              idempresa: profile.idEmpresaSec,
              isps: this.state.isps,
              biofuels: this.state.biofuels,
              reducao_isp: this.state.reducao_isp,
            })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  internalHandleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
        [name]: value,
    });
  }

  internalHandleBlur() {
    const profile = this.context;

    axios.post('https://eposgestofinal.pt/api/reducao_isp/set.php', {
      idempresa: profile.idEmpresaSec,
      reducao_isp: this.state.reducao_isp,
    });
  }

  render() {
    const { isps, biofuels, reducao_isp } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Impostos</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Impostos
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={this.uploadFunction}
                  >
                    <tr>
                      <td>
                        <i className="fas fa-upload"></i>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>
                        Enviar Impostos
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div style={{ margin: '30px' }}>
                <h2 style={{ textAlign: 'left' }}>ISP</h2>
                <EditableTable
                  columns={[
                      {
                        dataField: 'codigo',
                        text: 'Código',
                        editable: false,
                      }, {
                        dataField: 'taxa_isp',
                        text: 'Taxa ISP',
                        editable: true,
                        validator: this.onTableIspChange,
                      }, {
                        dataField: 'descricao_isp',
                        text: 'Descrição ISP',
                        editable: true,
                        validator: this.onTableIspChange,
                      }, {
                        dataField: 'motivo_isencao',
                        text: 'Motivo Isenção',
                        editable: true,
                        validator: this.onTableIspChange,
                      }
                  ]}
                  data={isps}
                />
              </div>
              <div style={{ margin: '30px' }}>
                <h2 style={{ textAlign: 'left' }}>Redução ISP</h2>
                <Input
                  title={ '' }
                  inputType={"text"}
                  name={"reducao_isp"}
                  value={reducao_isp}
                  handleChange={this.internalHandleChange}
                  onBlur={this.internalHandleBlur}
                  placeholder="redução do isp+IVA gasolinas xxxx gasoleos xxxx"
                />
              </div>
              <div style={{ margin: '30px' }}>
                <h2 style={{ textAlign: 'left' }}>Sobrecusto Biocombustivel</h2>
                <EditableTable
                  columns={[
                      {
                        dataField: 'codigo',
                        text: 'Código',
                        editable: false,
                      }, {
                        dataField: 'combustivel',
                        text: 'Combustível',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }, {
                        dataField: 'descricao',
                        text: 'Descrição',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }, {
                        dataField: 'energia_fossil',
                        text: 'Energia Fóssil',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }, {
                        dataField: 'energia_renovavel',
                        text: 'Energia Renovável',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }, {
                        dataField: 'co2',
                        text: 'CO2',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }, {
                        dataField: 'un',
                        text: 'UN',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }, {
                        dataField: 'sobrecusto',
                        text: 'Sobrecusto',
                        editable: true,
                        validator: this.onTableBiofuelChange,
                      }
                  ]}
                  data={biofuels}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
