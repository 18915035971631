import React, { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdClose } from "react-icons/md";
import ReactTable from '@components/ReactTable';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Components
import Menu from '@components/Menu'
import AtLogin from "@components/AtLogin";
import EmpresaSelect from "@components/EmpresaSelect";

import UserContext from "@components/context/userContext";

function getDocType(doctype) {
    switch (doctype) {
        case 'FT':
        case 'FT_epos':
            return 'Faturas';
        case 'FR':
        case 'FR_epos':
            return 'Vendas a Dinheiro';
        case 'NC':
            return 'Notas de Crédito';
        case 'RG':
        case 'RG_epos':
            return 'Recibos';
        case 'OU':
            return 'Serviços Internos';
        case 'GT':
            return 'Guias Transporte';
        case 'GA':
            return 'Guias de Ativos';
        default:
            return 'Talões';
    }
}

function getATMapDocType(doctype) {
    switch (doctype) {
        case 'FT':
        case 'FT_epos':
            return {
                tipo: 'FT',
                classe: 'SI',
            };
        case 'FR':
        case 'FR_epos':
            return {
                tipo: 'FR',
                classe: 'SI',
            };
        case 'NC':
            return {
                tipo: 'NC',
                classe: 'SI',
            };
        case 'RG':
        case 'RG_epos':
            return {
                tipo: 'RG',
                classe: 'PY',
            };
        case 'OU':
            return {
                tipo: 'OU',
                classe: 'WD',
            };
        case 'GT':
            return {
                tipo: 'GT',
                classe: 'MG',
            };
        case 'GA':
            return {
                tipo: 'GA',
                classe: 'MG',
            };
        default:
            return {
                tipo: 'FT',
                classe: 'SI',
            };
    }
}

class Page extends Component {
    static contextType = UserContext;
    
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    constructor(props) {
        super(props);


        this.state = {
            loaded: false,
            table: {
                columns: [
                    {
                        Header: 'Série',
                        accessor: 'sequence',
                    },
                    {
                        Header: 'Inicial',
                        accessor: 'initial',
                    },
                    {
                        Header: 'Documento',
                        id: 'doctype',
                        accessor: d => getDocType(d.doctype),
                    },
                    {
                        Header: 'TPV',
                        accessor: 'tpv',
                    },
                    {
                        Header: 'Posto',
                        accessor: 'posto',
                    },
                    {
                        Header: 'Nome Posto',
                        accessor: 'nome',
                    },
                    {
                        Header: 'Data Inicial',
                        accessor: 'start_at',
                    },
                    {
                        Header: 'ATCUD',
                        accessor: 'atcud',
                    },
                    {
                        Header: '',
                        accessor: 'delete',
                        show: false,
                    },
                ],
                rows: [],
                loading: true,
            },
            atlogin: false,
            environment: 'production',
        };
        this.loadFunction = this.loadFunction.bind(this);
        this.deleteSequence = this.deleteSequence.bind(this);
        this.onLogin = this.onLogin.bind(this);
    }
  
    loadFunction() {
        if (!this.state.loaded) {
            const profile = this.context;
            const { history } = this.props;

            axios.get(`https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresaSec}&environment=${this.state.environment}`)
            .then(response => {
                const sequences = response.data !== 'NOK' ? response.data : [];

                const enrichedSequences = sequences.map((sequence) => ({
                    ...sequence,
                    delete: (
                        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.deleteSequence(sequence) } className="fas fa-trash"></i>),
                }))

                this.setState({ table: { ...this.state.table , rows: enrichedSequences, loading: false }, loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }

    deleteSequence(sequence) {
        confirmAlert({
            title: 'Confirmação',
            message: `Tem a certeza que deseja anular esta série?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        const profile = this.context;
                        const { tipo, classe } = getATMapDocType(sequence.doctype);

                        axios.post('https://eposgestofinal.pt/api/at/webservice/series/anularSerie.php', {
                            idempresa: profile.idEmpresaSec,
                            environment: this.state.environment,
                            idsequence: sequence.idsequence,
                            data: {
                                serie: `${sequence.posto || ''}${sequence.tpv || ''}${sequence.sequence}`,
                                classeDoc: classe,
                                tipoDoc: tipo,
                                codValidacaoSerie: sequence.atcud,
                                motivo: 'ER',
                                declaracaoNaoEmissao: true,
                            },
                        })
                        .then(response => {
                            if (response.data !== 'NOK' && response.data.success) {
                                NotificationManager.success('Anulação submetida com sucesso para a AT', `${sequence.posto}${sequence.tpv}${sequence.sequence}`);
                                this.setState({ table: { ...this.state.table , rows: this.state.table.rows.filter(({idsequence}) => sequence.idsequence !== idsequence) } });
                            } else {
                                NotificationManager.error('Anulação não submetida para a AT', `${sequence.posto}${sequence.tpv}${sequence.sequence}`)
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    },
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        });
    }

    onLogin(loginResult) {
        this.setState({
            atlogin: true,
            environment: loginResult.environment,
            table: {
                ...this.state.table,
                columns: this.state.table.columns.map((column) => ({
                    ...column,
                    show: true,
                }))
            }
        })
        this.loadFunction();
    }
    
    render () {
        const { table, atlogin } = this.state;
        const { location, history } = this.props;
        const cookies = new URLSearchParams(location.search);

        return (
            <div>
                <Menu location={ location } history={ history } newCookies={ cookies } />
                <div className="fullContainer" style={{ maxWidth: '100%' }}>
                    <div className="col-xs-12">
                        <ol
                            style={{
                                textAlign: 'left',
                                'marginTop': '75px',
                            }}
                            className="breadcrumb"
                        >
                            <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                            <li className="active">Séries</li>
                        </ol>
                    </div>
                </div>
                <EmpresaSelect />
                <AtLogin onLogin={this.onLogin} selectedEmpresas={true} />
                <div
                    className="fullContainer" style={{ maxWidth: '100%' }}
                >
                    <div
                        className="col-sm-12"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '15pt',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                    }}
                                >
                                    Séries
                                </div>
                                <div>
                                    <table
                                        style={{ cursor: "pointer" }}
                                        onClick={() => window.location.href = 'https://eposgestofinal.pt/index.php?page=downloads&path=EnviadoServidor'}
                                    >
                                        <tr>
                                        <td>
                                            <i className="fas fa-history"></i>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ fontSize: "xx-small" }}>
                                            Recuperar ficheiros
                                        </td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    {atlogin && <table
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push("/series/bulk")}
                                    >
                                        <tr>
                                        <td>
                                            <i className="fas fa-magic"></i>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ fontSize: "xx-small" }}>
                                            Séries automáticas
                                        </td>
                                        </tr>
                                    </table>}
                                    </div>
                                    {/* <div>
                                    <table
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push("/series/new")}
                                    >
                                        <tr>
                                        <td>
                                            <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ fontSize: "xx-small" }}>
                                            Adicionar Série
                                        </td>
                                        </tr>
                                    </table>
                                    </div> */}
                            </div>
                            <ReactTable
                                columns={ table.columns }
                                sortable={ true }
                                loading={ table.loading }
                                data={ table.rows }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(withRouter(Page));