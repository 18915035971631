import React, { Component } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";
import { NotificationManager, NotificationContainer } from "react-notifications";
import 'react-notifications/lib/notifications.css';
import Input from "@components/Input";
import UserContext from "@components/context/userContext";
import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const codigo = this.props.codigo;

    this.state = {
      nome_1: "",
      nome_2: "",
      data_de_nascimento: "",
      nif: "",
      email: "",
      telefone: "",
      telefone_2: "",
      fax: "",
      telemovel: "",
      morada_1: "",
      morada_2: "",
      codigo_postal: "",
      credito_concedido: 0.00,
      edit: codigo,
      loaded: false,
      isSubmitting: false
    };

    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const profile = this.context;
    const { edit } = this.state;

    const validacp = /^\d{4}(-\d{3})?$/.test(this.state.codigo_postal);

    if (!validacp) {
      NotificationManager.error("Código Postal inválido! Apenas formato xxxx-xxx ou xxxx permitidos.", "Erro");
      return;
    }

    this.setState({ isSubmitting: true });

    axios
      .post("https://eposgestofinal.pt/api/clientes/set.php", {
        idempresa: profile.idEmpresa,
        nome_1: this.state.nome_1,
        nome_2: this.state.nome_2,
        data_de_nascimento: this.state.data_de_nascimento,
        nif: this.state.nif,
        email: this.state.email,
        telefone: this.state.telefone,
        telefone_2: this.state.telefone_2,
        fax: this.state.fax,
        telemovel: this.state.telemovel,
        morada_1: this.state.morada_1,
        morada_2: this.state.morada_2,
        codigo_postal: this.state.codigo_postal,
        credito_concedido: this.state.credito_concedido,
        ativo: 't',
      })
      .then((response) => {
        NotificationManager.success("Cliente submetido com sucesso!", "Cliente");
        eventBus.$emit("Modal-newcustomer", { isOpen: false });
        window.location.href = "/clientes";
      })
      .catch((error) => {
        NotificationManager.error(error.message, "Cliente");
        this.setState({ isSubmitting: false });
      });
  }

  render() {
    const {
      nome_1,
      nome_2,
      data_de_nascimento,
      nif,
      email,
      telefone,
      telefone_2,
      fax,
      telemovel,
      morada_1,
      morada_2,
      codigo_postal,
      credito_concedido,
      edit,
      isSubmitting
    } = this.state;

    this.loadFunction();

    return (
      <>
        <form onSubmit={this.handleFormSubmit} className="customer-form">
          <div style={{
            display: 'grid',
            gap: '24px'
          }}>
            {/* Personal Information Card */}
            <div style={{
              backgroundColor: 'white',
              borderRadius: '12px',
              overflow: 'hidden',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
            }}>
              <div style={{
                padding: '16px 24px',
                borderBottom: '1px solid #e2e8f0',
                backgroundColor: '#f8fafc'
              }}>
                <h2 style={{
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  color: '#1e293b',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                    <circle cx="12" cy="7" r="4"/>
                  </svg>
                  Informações Pessoais
                </h2>
              </div>
              <div style={{
                padding: '24px',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '20px'
              }}>
                <Input
                  title="Nome Principal"
                  inputType="text"
                  name="nome_1"
                  value={nome_1}
                  handleChange={this.handleChange}
                  required
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                      <circle cx="12" cy="7" r="4"/>
                    </svg>
                  }
                />
                <Input
                  title="Nome Secundário"
                  inputType="text"
                  name="nome_2"
                  value={nome_2}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                      <circle cx="12" cy="7" r="4"/>
                    </svg>
                  }
                />
                <Input
                    title="Data de Nascimento"
                    inputType="date"
                    name="data_de_nascimento"
                    value={data_de_nascimento}
                    handleChange={this.handleChange}
                    icon={
                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <rect x="3" y="4" width="18" height="16" rx="2"/>
                        <line x1="16" y1="2" x2="16" y2="6"/>
                        <line x1="8" y1="2" x2="8" y2="6"/>
                        <line x1="3" y1="10" x2="21" y2="10"/>
                      </svg>
                    }
                />
                <Input
                  title="NIF"
                  inputType="text"
                  name="nif"
                  value={nif}
                  handleChange={this.handleChange}
                  required
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <rect x="3" y="4" width="18" height="16" rx="2"/>
                      <path d="M7 8h10M7 12h10M7 16h10"/>
                    </svg>
                  }
                />
                <Input
                  title="E-Mail"
                  inputType="email"
                  name="email"
                  value={email}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                      <polyline points="22,6 12,13 2,6"/>
                    </svg>
                  }
                />
              </div>
            </div>

            {/* Contact Information Card */}
            <div style={{
              backgroundColor: 'white',
              borderRadius: '12px',
              overflow: 'hidden',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
            }}>
              <div style={{
                padding: '16px 24px',
                borderBottom: '1px solid #e2e8f0',
                backgroundColor: '#f8fafc'
              }}>
                <h2 style={{
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  color: '#1e293b',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                  </svg>
                  Contatos
                </h2>
              </div>
              <div style={{
                padding: '24px',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '20px'
              }}>
                <Input
                  title="Telefone Principal"
                  inputType="tel"
                  name="telefone"
                  value={telefone}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                    </svg>
                  }
                />
                <Input
                  title="Telefone Secundário"
                  inputType="tel"
                  name="telefone_2"
                  value={telefone_2}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                    </svg>
                  }
                />
                <Input
                  title="Telemóvel"
                  inputType="tel"
                  name="telemovel"
                  value={telemovel}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M7 2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"/>
                      <path d="M11 18h2"/>
                      <path d="M12 6a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h0a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1"/>
                    </svg>
                  }
                />
                <Input
                  title="Fax"
                  inputType="tel"
                  name="fax"
                  value={fax}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <rect x="2" y="7" width="20" height="14" rx="2" ry="2"/>
                      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"/>
                    </svg>
                  }
                />
              </div>
            </div>

            {/* Address and Financial Card */}
            <div style={{
              backgroundColor: 'white',
              borderRadius: '12px',
              overflow: 'hidden',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
            }}>
              <div style={{
                padding: '16px 24px',
                borderBottom: '1px solid #e2e8f0',
                backgroundColor: '#f8fafc'
              }}>
                <h2 style={{
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  color: '#1e293b',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                    <polyline points="9 22 9 12 15 12 15 22"/>
                  </svg>
                  Endereço e Financeiro
                </h2>
              </div>
              <div style={{
                padding: '24px',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '20px'
              }}>
                <Input
                  title="Morada"
                  inputType="text"
                  name="morada_1"
                  value={morada_1}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                      <polyline points="9 22 9 12 15 12 15 22"/>
                    </svg>
                  }
                />
                <Input
                  title="Localidade"
                  inputType="text"
                  name="morada_2"
                  value={morada_2}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                      <circle cx="12" cy="10" r="3"/>
                    </svg>
                  }
                />
                <Input
                  title="Código Postal"
                  inputType="text"
                  name="codigo_postal"
                  value={codigo_postal}
                  handleChange={this.handleChange}
                  placeholder="XXXX-XXX"
                  required
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                      <circle cx="12" cy="10" r="3"/>
                    </svg>
                  }
                />
                <Input
                  title="Crédito Concedido"
                  inputType="number"
                  step="0.01"
                  name="credito_concedido"
                  value={credito_concedido}
                  handleChange={this.handleChange}
                  icon={
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <circle cx="12" cy="12" r="10"/>
                      <path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"/>
                      <path d="M12 6v2m0 8v2"/>
                    </svg>
                  }
                />
              </div>
            </div>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            marginTop: '32px',
            padding: '24px',
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
          }}>
            <button
              type="button"
              onClick={() => window.location.href = "/clientes"}
              style={{
                padding: '10px 20px',
                borderRadius: '8px',
                border: '1px solid #e2e8f0',
                backgroundColor: 'white',
                color: '#64748b',
                fontSize: '0.875rem',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer',
                transition: 'all 0.2s'
              }}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M19 12H5M12 19l-7-7 7-7"/>
              </svg>
              Voltar
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                padding: '10px 20px',
                borderRadius: '8px',
                border: 'none',
                backgroundColor: '#3b82f6',
                color: 'white',
                fontSize: '0.875rem',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: isSubmitting ? 'not-allowed' : 'pointer',
                transition: 'all 0.2s',
                opacity: isSubmitting ? 0.7 : 1
              }}
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <circle cx="12" cy="12" r="10" strokeDasharray="32" strokeDashoffset="32"/>
                  </svg>
                  Processando...
                </>
              ) : (
                <>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M5 13l4 4L19 7"/>
                  </svg>
                  {edit ? "Guardar Alterações" : "Criar Cliente"}
                </>
              )}
            </button>
          </div>
        </form>
        <NotificationContainer />
      </>
    );
  }
}

export default withCookies(Page);