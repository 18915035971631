import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";

// Components
import Menu from "@components/Menu";
import Checkbox from "@components/CheckBox";
import Switch from "@components/Switch";

/* Import Components */
import EmpresaSelect from "@components/EmpresaSelect";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import ReactTable from "@components/ReactTable";
import Button from "@components/Button";
import HorizontalTabs from "@components/HorizontalTabs/.";
import VerticalTabs from "@components/VerticalTabs/.";
import { MDBDataTable } from "mdbreact";
import { MdAddCircleOutline } from "react-icons/md";
import Barcode from "react-barcode";
import Modal from '@components/Modal';
import { EventBus as eventBus } from "@components/EventBus";

import "react-tabs/style/react-tabs.css";

import { threeDecimal, valueWithIva, valueWithoutIva } from "@utils/format.js";
import { TimestampToDate } from "@utils/date";

import "./form.css";

import UserContext from "@components/context/userContext";

class Form extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    this.state = {
      taxes: [],
      isps: [],
      isp: {},
      tabIndex: params.tabIndex,
      redirect: params.redirect,
      loaded: false,
      newbarcode: '',
      ativo: true,
      mov_stock: true,
      limite_semanal_switch: false,
      limite_semanal: 0,
      composed: false,
      fuel: false,
      price: "",
      price_iva: "",
      costprice: "",
      costprice_iva: "",
      iva: {
        tax: "23",
        code: "23",
        reason: null,
        value: "23",
        label: "23"
      },
      idproduct: { value: params.idproduct },
      idcategory: { value: "" },
      idproduto: "",
      edit: type === "edit",
      categories: [],
      warehouses: [],
      barcodes: [],
      nome: "",
      samePrices: true,
      samePVPValue: '',
      samePCValue: '',
      sameIVAValue: '',
      sameSYNCValue: true,
      prices: {
        columns: [
          {
            label: "Posto",
            field: "nome",
          },
          {
            label: "PVP c/IVA",
            field: "price",
          },
          {
            label: "P. Custo s/IVA",
            field: "costprice",
          },
          {
            label: "Margem s/IVA",
            field: "profit",
          },
          {
            label: "Margem (%)",
            field: "profitper",
          },
          {
            label: "IVA",
            field: "iva",
          },
          {
            label: "Sincronizar",
            field: "sync",
          },
        ],
        rows: [],
      },
      stockTableStructure: {
        columns: [
          {
            label: "Posto",
            field: "nome",
          },
          {
            label: "Estado",
            field: "status",
          },
          {
            label: "Quantidade",
            field: "quantity",
          },
          {
            label: "P.C.M.",
            field: "average",
          },
          {
            label: "P. Custo",
            field: "last_order",
          },
        ],
        rows: [],
      },
      movEntradas: {
        data: [],
        pages: null,
        loading: true,
        columns: [
          {
            Header: "Data",
            id: "date_time",
            accessor: (d) => TimestampToDate(d.date_time),
          },
          {
            Header: "Posto",
            id: "posto",
            accessor: (d) => d.postoname || d.posto,
          },
          {
            Header: "Doc",
            accessor: "doc",
          },
          {
            Header: "Tipo Doc",
            id: "type",
            accessor: (d) => this.translateType(d.type),
          },
          {
            Header: "Qty",
            accessor: "quantity",
          },
          {
            Header: "Stock",
            accessor: "stock",
          },
        ],
      },
      movEntradasSaidas: {
        data: [],
        pages: null,
        loading: true,
        columns: [
          {
            Header: "Data",
            id: "date_time",
            accessor: (d) => TimestampToDate(d.date_time),
          },
          {
            Header: "Posto",
            id: "posto",
            accessor: (d) => d.postoname || d.posto,
          },
          {
            Header: "Doc",
            accessor: "doc",
          },
          {
            Header: "Tipo Doc",
            id: "type",
            accessor: (d) => this.translateType(d.type),
          },
          {
            Header: "Qty",
            accessor: "quantity",
          },
          {
            Header: "Stock",
            accessor: "stock",
          },
        ],
      },
      movSaidas: {
        data: [],
        pages: null,
        loading: true,
        columns: [
          {
            Header: "Data",
            id: "date_time",
            accessor: (d) => TimestampToDate(d.date_time),
          },
          {
            Header: "Posto",
            id: "posto",
            accessor: (d) => d.postoname || d.posto,
          },
          {
            Header: "Serie",
            accessor: "serie",
          },
          {
            Header: "Doc",
            accessor: "doc",
          },
          {
            Header: "Tipo Doc",
            id: "type",
            accessor: (d) => this.translateType(d.type),
          },
          {
            Header: "Qty",
            accessor: "quantity",
          },
          {
            Header: "Stock",
            accessor: "stock",
          },
          {
            Header: "PVP",
            accessor: "pvp",
          },
        ],
      },
      products: [],
      composition: [],
      selectedProduct: '',
      selectedquantity: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePriceSubmit = this.handlePriceSubmit.bind(this);
    this.handleSamePriceSubmit = this.handleSamePriceSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.handleTableSubmit = this.handleTableSubmit.bind(this);
    this.fetchMovEntradasData = this.fetchMovEntradasData.bind(this);
    this.fetchMovEntradasSaidasData = this.fetchMovEntradasSaidasData.bind(this);
    this.fetchMovSaidasData = this.fetchMovSaidasData.bind(this);
    this.translateType = this.translateType.bind(this);
    this.getRealIdOrder = this.getRealIdOrder.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleBarcodeSubmitSuccess = this.handleBarcodeSubmitSuccess.bind(this);
    this.handleBarcodeDeleteSuccess = this.handleBarcodeDeleteSuccess.bind(this);
    this.handleCompositionSubmitSuccess = this.handleCompositionSubmitSuccess.bind(this);
    this.handleCompositionDeleteSuccess = this.handleCompositionDeleteSuccess.bind(this);
    this.doSave = this.doSave.bind(this);
  }

  translateType(type) {
    switch (type) {
      case "sales":
        return "talão";
      case "orders":
        return "compra";
      case "transfer":
        return "transferência";
      case "returns":
        return "devolução";
      case "earnings":
        return "inventário";
      case "losses":
        return "inventário";
      default:
        return "";
    }
  }

  getRealIdOrder(entry) {
    switch (entry.type) {
      case "earnings":
      case "losses":
        return `/orders/${entry.doc}/products/${this.state.idproduct.value}`;
      case "sales":
        return `/invoices/${entry.doc}/warehouses/${entry.posto}/series/${entry.serie}/tpvs/${entry.tpv}/products/${this.state.idproduct.value}`;
      default:
        return `/orders/${entry.doc}/products/${this.state.idproduct.value}`;
    }
  }

  handleCheckboxChange(e) {
    let value = e.target.checked;
    let name = e.target.name;

    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      newState[properties[0]][properties[1]][properties[2]] = value;
      newState[properties[0]][properties[1]]["changed"] = true;

      this.setState({
        ...newState,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  fetchMovEntradasData(state, instance) {
    const profile = this.context;

    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({
      movEntradas: { ...this.state.movEntradas, loading: true },
    });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    const idposto = instance.props.warehouse;
    let url;
    if (idposto === "all") {
      url = `https://eposgestofinal.pt/api/products/transactions/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${
        this.state.idproduct.value
      }&doctypes[]=orders&doctypes[]=transfer&doctypes[]=earnings&page=${
        state.page
      }&pageSize=${state.pageSize}`;
    } else {
      url = `https://eposgestofinal.pt/api/products/transactions/warehouses/getById.php?idempresa=${profile.idEmpresaSec}&idposto=${idposto}&idproduct=${
        this.state.idproduct.value
      }&doctypes[]=orders&doctypes[]=transfer&doctypes[]=earnings&page=${
        state.page
      }&pageSize=${state.pageSize}`;
    }
    axios
      .get(url)
      .then((response) => {
        const productTransactions =
          response.data !== "NOK" ? response.data : [];

        this.setState({
          movEntradas: {
            ...this.state.movEntradas,
            data: productTransactions.entries.map((entry) => ({
              ...entry,
              doc: ["orders", "transfer", "earnings"].includes(
                entry.type
              ) ? (
                <a href={this.getRealIdOrder(entry)} target="popup">
                  {entry.doc}
                </a>
              ) : (
                entry.doc
              ),
            })),
            pages: productTransactions.totalPages,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchMovEntradasSaidasData(state, instance) {
    const profile = this.context;

    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({
      movEntradasSaidas: { ...this.state.movEntradasSaidas, loading: true },
    });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    const idposto = instance.props.warehouse;
    let url;
    if (idposto === "all") {
      url = `https://eposgestofinal.pt/api/products/transactions/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${
        this.state.idproduct.value
      }&doctypes[]=orders&doctypes[]=transfer&doctypes[]=earnings&doctypes[]=sales&doctypes[]=transfer&doctypes[]=returns&doctypes[]=losses&page=${
        state.page
      }&pageSize=${state.pageSize}`;
    } else {
      url = `https://eposgestofinal.pt/api/products/transactions/warehouses/getById.php?idempresa=${profile.idEmpresaSec}&idposto=${idposto}&idproduct=${
        this.state.idproduct.value
      }&doctypes[]=orders&doctypes[]=transfer&doctypes[]=earnings&doctypes[]=sales&doctypes[]=transfer&doctypes[]=returns&doctypes[]=losses&page=${
        state.page
      }&pageSize=${state.pageSize}`;
    }
    axios
      .get(url)
      .then((response) => {
        const productTransactions =
          response.data !== "NOK" ? response.data : [];

        this.setState({
          movEntradasSaidas: {
            ...this.state.movEntradasSaidas,
            data: productTransactions.entries.map((entry) => ({
              ...entry,
              doc: ["orders", "transfer", "earnings", "returns", "transfer", "losses", "sales"].includes(
                entry.type
              ) ? (
                <a href={this.getRealIdOrder(entry)} target="popup">
                  {entry.doc}
                </a>
              ) : (
                entry.doc
              ),
            })),
            pages: productTransactions.totalPages,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchMovSaidasData(state, instance) {
    const profile = this.context;
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ movSaidas: { ...this.state.movSaidas, loading: true } });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    const idposto = instance.props.warehouse;
    let url;
    if (idposto === "all") {
      url = `https://eposgestofinal.pt/api/products/transactions/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${
        this.state.idproduct.value
      }&doctypes[]=sales&doctypes[]=transfer&doctypes[]=returns&doctypes[]=losses&page=${
        state.page
      }&pageSize=${state.pageSize}`;
    } else {
      url = `https://eposgestofinal.pt/api/products/transactions/warehouses/getById.php?idempresa=${profile.idEmpresaSec}&idposto=${idposto}&idproduct=${
        this.state.idproduct.value
      }&doctypes[]=sales&doctypes[]=transfer&doctypes[]=returns&doctypes[]=losses&page=${
        state.page
      }&pageSize=${state.pageSize}`;
    }
    axios
      .get(url)
      .then((response) => {
        const productTransactions =
          response.data !== "NOK" ? response.data : [];

        this.setState({
          movSaidas: {
            ...this.state.movSaidas,
            data: productTransactions.entries.map((entry) => ({
              ...entry,
              doc: ["returns", "transfer", "losses", "sales"].includes(
                entry.type
              ) ? (
                <a href={this.getRealIdOrder(entry)} target="popup">
                  {entry.doc}
                </a>
              ) : (
                entry.doc
              ),
            })),
            pages: productTransactions.totalPages,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadFunction() {
    const profile = this.context;
    if (!this.state.loaded) {
      axios.get(`https://eposgestofinal.pt/api/tax/get.php`)
      .then(response => {
          const taxes = response.data !== 'NOK' ? response.data : [];

          this.setState({ taxes: taxes.map((tax) => ({ ...tax, value: tax.code, label: tax.reason ? `${tax.code < 100 ? '(não usar) - ' : ''}Isenção - ${tax.reason} - ${tax.name} - ${tax.description}` : `${tax.tax}%` })), loaded: true });
      })
      .catch(error => {
          console.error(error);
      });

      axios.get(`https://eposgestofinal.pt/api/isp/get.php?idempresa=${profile.idEmpresaSec}`)
      .then(response => {
          const isps = response.data !== 'NOK' ? response.data : [];

          this.setState({ isps: [
            {
              value: '',
              codigo: '',
              label: 'NENHUM',
            },
            ...isps.map((eachIsp) => ({ ...eachIsp, value: eachIsp.codigo, label: eachIsp.descricao_isp }))
          ], loaded: true });
      })
      .catch(error => {
          console.error(error);
      });

      if (this.props.type === "edit") {
        axios
        .get(
          `https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresaSec}`
        )
        .then((response) => {
          const products = response.data !== "NOK" ? response.data : [];

          this.setState({ products: products.map((product) => ({
            label: `[${product.idproduct}] ${product.nome}`,
            value: product.idproduct
          })) });
        })
        .catch((error) => {
          console.error(error);
        });
        Promise.all([
          axios
            .get(
              `https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresaSec}`
            )
            .then(async (response) => {
              const categories = response.data !== "NOK" ? response.data : [];

              await this.setState({
                categories: categories.map((category) => ({
                  value: category.idcategoria,
                  label: `[${category.idcategoria}] ${category.descricaocategoria}`,
                })),
                loaded: true,
              });

              axios
                .get(
                  `https://eposgestofinal.pt/api/products/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${this.state.idproduct.value}`
                )
                .then(async (response) => {
                  const product = response.data !== "NOK" ? response.data[0] : [];
                  product.name = product.nome;
                  product.price = threeDecimal(product.price);
                  product.price_iva = threeDecimal(
                    valueWithIva(product.price, product.iva)
                  );
                  product.costprice = threeDecimal(product.costprice);
                  product.costprice_iva = threeDecimal(
                    valueWithIva(product.costprice, product.iva)
                  );

                  await this.setState({
                    ...product,
                    ativo: !(product.inativo === 't'),
                    mov_stock: !(product.nao_mov_stock === 't'),
                    limite_semanal_switch: product.limite_semanal > 0,
                    limite_semanal: product.limite_semanal || 0,
                    composed: product.composed === 't',
                    fuel: product.fuel === 't',
                    idcategory: this.state.categories.find(({ value }) => value === product.idcategoria),
                    isp: this.state.isps.find(({ codigo }) => codigo === product.isp) || {
                      value: '',
                      codigo: '',
                      label: 'NENHUM',
                    },
                    idproduct: { value: product.idproduct },
                  });
                  axios
                    .get(
                      `https://eposgestofinal.pt/api/products/composition/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${this.state.idproduct.value}`
                    )
                    .then(async (response) => {
                      const composition = response.data !== "NOK" ? response.data : [];

                      await this.setState({
                        composition
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((error) => {
              console.error(error);
            }),
          axios
            .get(
              `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresaSec}`
            )
            .then(async (response) => {
              const warehouses = response.data !== "NOK" ? response.data : [];

              await this.setState({ warehouses });

              
              await Promise.all([
                ...warehouses.map((warehouse) =>
                  axios.get(
                    `https://eposgestofinal.pt/api/postos/stocks/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${this.state.idproduct.value}&idposto=${warehouse.posto}`
                  )
                ),
              ]).then(async (responses) => {
                const allStocks = responses
                  .map((response) => response.data !== "NOK" ? response.data[0] : 0)
                  .filter((response) => response !== 0)
                const stocksByPosto = allStocks.reduce((acc, stock) => ({
                  ...acc,
                  [stock.idposto]: stock,
                }), {});
                await axios
                    .get(
                      `https://eposgestofinal.pt/api/products/price/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${this.state.idproduct.value}`
                    )
                    .then(async (response) => {
                      const prices =
                        response.data !== "NOK" ? response.data : [];
                      const pricesByPosto = prices.reduce(
                        (acc, price) => ({
                          ...acc,
                          [price.idposto]: {
                            ...price,
                          },
                        }),
                        {}
                      );
                      const newWarehousesState = this.state.warehouses.map((warehouse) => {
                        const values =
                          (
                            pricesByPosto[warehouse.posto] || { values: [] }
                          ).values.find(
                            (value) => parseInt(value.tpv, 10) === 0
                          ) || {};
                        return {
                          ...warehouse,
                          quantity: stocksByPosto[warehouse.posto].stock,
                          flags: stocksByPosto[warehouse.posto].flags,
                          prices: pricesByPosto[warehouse.posto] || {},
                          price: values.price,
                          costprice: values.costprice,
                          iva: this.state.taxes.find((tax) => tax.code === values.iva) || this.state.taxes.find((tax) => tax.code == 23),
                          sync: values.sync === "t",
                        };
                      });
                      const samePrices = newWarehousesState.length <= 0 ||
                      newWarehousesState.every((item) =>
                        item.price === newWarehousesState[0].price &&
                        item.costprice === newWarehousesState[0].costprice &&
                        item.iva === newWarehousesState[0].iva &&
                        item.sync === newWarehousesState[0].sync
                      );
                      if (samePrices && newWarehousesState.length > 0) {
                        await this.setState({
                          samePVPValue: newWarehousesState[0].price,
                          samePCValue: newWarehousesState[0].costprice,
                          sameIVAValue: newWarehousesState[0].iva  || this.state.taxes.find((tax) => tax.code == 23),
                          sameSYNCValue: newWarehousesState[0].sync,
                        });
                      }
                      await this.setState({
                        samePrices,
                        warehouses: newWarehousesState,
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
              })
            })
            .catch((error) => {
              console.error(error);
            }),
        ]).then(() => {
          this.setState({ loaded: true });
        });
      } else {
        axios
          .get(
            `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresaSec}`
          )
          .then(async (response) => {
            const warehouses = response.data !== "NOK" ? response.data : [];

            await this.setState({ warehouses });
          });
        axios
          .get(
            `https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresaSec}`
          )
          .then(async (response) => {
            const categories = response.data !== "NOK" ? response.data : [];

            await this.setState({
              categories: categories.map((category) => ({
                value: category.idcategoria,
                label: `[${category.idcategoria}] ${category.descricaocategoria}`,
              })),
              loaded: true,
            });
          });
      }
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      if (properties.length === 4) {
        newState[properties[0]][properties[1]][properties[2]][properties[3]] = value;
      } else {
        newState[properties[0]][properties[1]][properties[2]] = value;
        newState[properties[0]][properties[1]]["changed"] = true;
      }


      this.setState({
        ...newState,
      });
    } else {
      if (name === "samePrices") {
        this.setState({
          [name]: !newState[name],
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    }

    if (typeof callback === 'function') {
      callback();
    }
  }

  doSave(profile, idProduct, redirect) {
    axios
        .post("https://eposgestofinal.pt/api/products/set.php", {
          price: this.state.price,
          costprice: this.state.costprice,
          iva: (this.state.iva && this.state.iva.value) || 23,
          idempresa: profile.idEmpresaSec,
          idproduct: idProduct,
          name: this.state.name,
          idcategory: this.state.idcategory.value,
          isp: this.state.isp.value,
          ativo: this.state.ativo ? 't' : 'f',
          mov_stock: this.state.mov_stock ? 't' : 'f',
          limite_semanal: this.state.limite_semanal_switch ? this.state.limite_semanal : 'NULL',
          composed: this.state.composed ? 't' : 'f',
          fuel: this.state.fuel ? 't' : 'f',
        })
        .then((response) => {
          Promise.all([
            this.handleTableSubmit(idProduct),
            this.handleCompositionSubmit(idProduct),
            this.state.samePrices ? this.handleSamePriceSubmit(idProduct) : this.handlePriceSubmit(idProduct),
          ])
          .then(() => {
            if (redirect) {
              switch (redirect) {
                case "sync":
                  window.location.href = "/products/sync";
                  break;
                default:
                  if (this.state.edit) {
                    window.location.href = "/products";
                  } else {
                    window.location.href = `/products/${idProduct}`;
                  }
                  break;
              }
            } else {
              if (this.state.edit) {
                window.location.href = "/products";
              } else {
                window.location.href = `/products/${idProduct}`;
              }
            }
          })
        })
        .catch((error) => {
          console.error(error);
        });
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { redirect } = this.state;
    e.preventDefault();
    if (this.state.idproduto || this.state.idproduct.value) {
      const idProduct = this.state.idproduto || this.state.idproduct.value;
      if (this.props.type !== "edit") {
        axios
          .get(
            `https://eposgestofinal.pt/api/products/getById.php?idempresa=${profile.idEmpresaSec}&idproduct=${idProduct}`
          )
          .then(async (response) => {
            if (response.data[0]) {
              alert("Já existe um produto com o mesmo código!")
              return;
            } else {
              this.doSave(profile, idProduct, redirect)
            }
          })
      } else {
        this.doSave(profile, idProduct, redirect)
      }
    }
  }

  handleTableSubmit(idproduct) {
    const profile = this.context;
    return axios
      .post("https://eposgestofinal.pt/api/products/stocks/set.php", {
        idempresa: profile.idEmpresaSec,
        idproduct: idproduct,
        stocks: this.state.warehouses
          .filter((warehouse) => warehouse.changed)
          .map((warehouse) => ({
            ...warehouse,
            idposto: warehouse.posto,
          })),
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handlePriceSubmit(idproduct) {
    const profile = this.context;
    return axios
      .post("https://eposgestofinal.pt/api/products/price/set.php", {
        idempresa: profile.idEmpresaSec,
        idproduct: idproduct,
        values: this.state.warehouses
          .filter((warehouse) => warehouse.changed)
          .map((warehouse) => ({
            ...warehouse,
            idposto: warehouse.posto,
            iva: warehouse.iva.code  || 23
          })),
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleCompositionSubmit(idproduct) {
    const profile = this.context;
    return axios
      .post("https://eposgestofinal.pt/api/products/composition/set.php", {
        idempresa: profile.idEmpresaSec,
        idproduct: idproduct,
        composition: this.state.composition,
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSamePriceSubmit(idproduct) {
    const profile = this.context;
    const { samePVPValue, samePCValue, sameIVAValue, sameSYNCValue } = this.state;
    return axios
      .post("https://eposgestofinal.pt/api/products/price/set.php", {
        idempresa: profile.idEmpresaSec,
        idproduct: idproduct,
        same: true,
        value: {
          idposto: -1,
          price: samePVPValue,
          costprice: samePCValue,
          iva: sameIVAValue.value  || 23,
          sync: sameSYNCValue,
        },
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleFormCancel() {
    const { history } = this.props;
    const { redirect } = this.state;

    if (redirect) {
      switch (redirect) {
        case "sync":
          window.location.href = "/products/sync";
          break;
        default:
          history.push("/products");
          break;
      }
    } else {
      history.push("/products");
    }
  }

  handleBarcodeSubmitSuccess() {
    const profile = this.context;
    if (this.state.idproduto || this.state.idproduct.value) {
      this.setState({ idproduct: { value: this.state.idproduto || this.state.idproduct.value } });
      axios
        .post("https://eposgestofinal.pt/api/products/barcodes/set.php", {
          idempresa: profile.idEmpresaSec,
          idproduct: this.state.idproduto || this.state.idproduct.value,
          barcode: this.state.newbarcode,
        })
        .then((response) => {
          eventBus.$emit("Modal-barcodes", { isOpen: false });
          this.setState({
            newbarcode: '',
            barcodes:
              this.state.barcodes.find((barcode) => barcode === this.state.newbarcode)
              ? this.state.barcodes
              : [...this.state.barcodes, {
                idempresa: profile.idEmpresaSec,
                idproduct: this.state.idproduto || this.state.idproduct.value,
                barcode: this.state.newbarcode
              }],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleBarcodeDeleteSuccess(currentBarcode) {
    const profile = this.context;
    if (this.state.idproduto || this.state.idproduct.value) {
      this.setState({ idproduct: { value: this.state.idproduto || this.state.idproduct.value } });
      axios
        .post("https://eposgestofinal.pt/api/products/barcodes/unset.php", {
          idempresa: profile.idEmpresaSec,
          idproduct: this.state.idproduto || this.state.idproduct.value,
          barcode: currentBarcode,
        })
        .then((response) => {
          this.setState({
            barcodes: this.state.barcodes.filter((barcode) => barcode !== currentBarcode),
          });
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleCompositionSubmitSuccess() {
    const profile = this.context;
    if (this.state.idproduto || this.state.idproduct.value) {
      this.setState({ idproduct: { value: this.state.idproduto || this.state.idproduct.value } });
      axios
        .post("https://eposgestofinal.pt/api/products/composition/set.php", {
          idempresa: profile.idEmpresaSec,
          idproduct: this.state.idproduto || this.state.idproduct.value,
          idproduct2: this.state.selectedProduct.value,
          quantity: this.state.selectedquantity,
        })
        .then((response) => {
          eventBus.$emit("Modal-composition", { isOpen: false });
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleCompositionDeleteSuccess(item) {
    const profile = this.context;

    axios
        .post("https://eposgestofinal.pt/api/products/composition/unset.php", {
          idempresa: profile.idEmpresaSec,
          idproduct: this.state.idproduto || this.state.idproduct.value,
          idproduct2: item.idproduct2,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
  }

  render() {
    const { location, history } = this.props;
    const {
      edit,
      loaded,
      idproduto,
      idproduct,
      newbarcode,
      name,
      ativo,
      mov_stock,
      limite_semanal,
      limite_semanal_switch,
      composed,
      fuel,
      idcategory,
      warehouses,
      categories,
      stockTableStructure,
      prices,
      movEntradas,
      movEntradasSaidas,
      movSaidas,
      barcodes,
      tabIndex,
      samePrices,
      samePVPValue,
      samePCValue,
      sameIVAValue,
      sameSYNCValue,
      taxes,
      selectedProduct,
      selectedquantity,
      products,
      composition,
      isps,
      isp,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    const sameIVA = sameIVAValue  || this.state.taxes.find((tax) => tax.code == 23);

    const priceTable = {
      ...prices,
      autoWidth: true,
      rows: samePrices
        ? [{
          nome: 'TODOS POSTOS',
          price: (
            <input
              type="number"
              key={'samePVPValue'}
              name={'samePVPValue'}
              value={ samePVPValue }
              onChange={this.handleChange}
            />
          ),
          costprice: (
            <input
              type="number"
              key={'samePCValue'}
              name={'samePCValue'}
              value={ samePCValue }
              onChange={this.handleChange}
            />
          ),
          profit: (valueWithoutIva(samePVPValue, sameIVA ? sameIVA.tax : 0) - samePCValue).toFixed(3),
          profitper: samePCValue > 0 ? ((valueWithoutIva(samePVPValue, sameIVA ? sameIVA.tax : 0) * 100 / samePCValue) - 100).toFixed(2) : 100,
          iva: (
            <AutoComplete
              name={"sameIVAValue"}
              value={sameIVA}
              handleChange={this.handleChange}
              options={taxes}
            />
          ),
          sync: (
            <Checkbox
              key={'sameSYNCValue'}
              name={'sameSYNCValue'}
              checked={sameSYNCValue}
              handleChange={this.handleCheckboxChange}
            />
          ),
        }]
        : warehouses
        .map((warehouse, index) => {
          const iva = warehouse.iva  || this.state.taxes.find((tax) => tax.code == 23)
          return {
            nome: warehouse.nome,
            price: (
              <input
                type="number"
                key={`warehouses|${index}|price`}
                name={`warehouses|${index}|price`}
                value={warehouse.price}
                onChange={this.handleChange}
              />
            ),
            costprice: (
              <input
                type="number"
                key={`warehouses|${index}|costprice`}
                name={`warehouses|${index}|costprice`}
                value={warehouse.costprice}
                onChange={this.handleChange}
              />
            ),
            profit: (valueWithoutIva(warehouse.price, iva ? iva.tax : 0) - warehouse.costprice).toFixed(3),
            profitper: warehouse.costprice > 0 ? ((valueWithoutIva(warehouse.price, iva ? iva.tax : 0) * 100 / warehouse.costprice) - 100).toFixed(2) : 100,
            iva: (
              <AutoComplete
                name={`warehouses|${index}|iva`}
                value={iva}
                handleChange={this.handleChange}
                options={taxes}
              />
            ),
            sync: (
              <Checkbox
                key={`warehouses|${index}|sync`}
                name={`warehouses|${index}|sync`}
                checked={warehouse.sync}
                handleChange={this.handleCheckboxChange}
              />
            ),
          }
        })
        .filter(({ posto }) => parseInt(posto, 10) !== 0),
    };

    const compositionTable = {
      columns: [
        {
          label: "Produto",
          field: "product2",
        },
        {
          label: "Quantidade",
          field: "quantityinput",
        },
        {
          label: "",
          field: "edit",
        },
      ],
      rows: composition.map((item, index) => {
        const product = (products.find(({value}) => value == item.idproduct2) || {});
        return {
          ...item,
          product2: product.label,
          quantityinput: (
            <input
              type="number"
              key={`composition|${index}|quantity`}
              name={`composition|${index}|quantity`}
              value={ item.quantity }
              onChange={this.handleChange}
            />
          ),
          edit: (
            <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.handleCompositionDeleteSuccess(item) } className="fas fa-trash"></i>
          ),
        }
      }),
    };

    console.log(warehouses);

    const stockTable = {
      ...stockTableStructure,
      rows: warehouses.map((warehouse, index) => ({
        nome: warehouse.nome,
        quantity: warehouse.quantity,
        status: (warehouse.flags || []).map(({key, value}) => {
          if (key === 'LOW_STOCK') {
            return value === 'WARN' ? (
              <span className="badge badge-warning">Stock baixo</span>
            ) : (
              <span className="badge badge-danger">Stock muito baixo</span>
            )
          } else {
            return null;
          }
        }),
        average: warehouse.prices && threeDecimal(warehouse.prices.average),
        last_order:
          warehouse.prices && threeDecimal(warehouse.prices.last_order),
      })),
    };

    console.log(stockTable);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/products">Produtos</a>
              </li>
              <li className="active">
                {edit ? <span>{name}</span> : <span>Novo Produto</span>}
              </li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          {loaded && (
            <HorizontalTabs
              tabIndex={tabIndex}
              tabs={{
                details: {
                  label: "Detalhes",
                  page: (
                    <div>
                      <div
                        className="fullContainer"
                        style={{ maxWidth: "100%" }}
                      >
                        <div
                          className="col-sm-12"
                          style={{ textAlign: "left" }}
                        >
                          <div className="panel panel-default">
                            <div
                              className="panel-heading"
                              style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                              }}
                            >
                              Detalhes{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                            <form
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              className="container-fluid"
                              onSubmit={this.handleFormSubmit}
                            >
                              <table class="table table-bordered table-hover table-sortable">
                                <thead>
                                  <tr>
                                    <th class="text-center">Código</th>
                                    <th class="text-center">Designação</th>
                                    <th class="text-center">Família</th>
                                    <th class="text-center">ISP</th>
                                    <th class="text-center">Combustivel</th>
                                    <th class="text-center">Produto Composto</th>
                                    <th class="text-center">Movimenta Stock</th>
                                    <th class="text-center">Limite Semanal</th>
                                    <th class="text-center">Ativo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {idproduct.value ? (
                                        idproduct.value
                                      ) : (
                                        <Input
                                          inputType={"text"}
                                          name={"idproduto"}
                                          value={idproduto}
                                          handleChange={this.handleChange}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <Input
                                          inputType={"text"}
                                          name={"name"}
                                          value={name}
                                          handleChange={this.handleChange}
                                        />
                                    </td>
                                    <td>
                                      <AutoComplete
                                        name={"idcategory"}
                                        value={idcategory}
                                        handleChange={this.handleChange}
                                        options={categories}
                                      />
                                    </td>
                                    <td>
                                      <AutoComplete
                                        name={"isp"}
                                        value={isp}
                                        handleChange={this.handleChange}
                                        options={isps}
                                      />
                                    </td>
                                    <td>
                                      <Switch
                                        name={"fuel"}
                                        checked={fuel}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <Switch
                                        name={"composed"}
                                        checked={composed}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <Switch
                                        name={"mov_stock"}
                                        checked={mov_stock}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <Switch
                                        name={"limite_semanal_switch"}
                                        checked={limite_semanal_switch}
                                        handleChange={this.handleChange}
                                      />
                                      {limite_semanal_switch && (
                                        <Input
                                          inputType={"number"}
                                          name={"limite_semanal"}
                                          value={limite_semanal}
                                          handleChange={this.handleChange}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <Switch
                                        name={"ativo"}
                                        checked={ativo}
                                        handleChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                },
                prices: edit ? {
                  label: "Preços",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Preços{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                          </div>
                          {loaded && (
                            <div class="prices-page">
                              <Checkbox
                                key={"samePrices"}
                                name={"samePrices"}
                                title={"Aplicar preços iguais em todos os postos"}
                                checked={samePrices}
                                handleChange={this.handleCheckboxChange}
                              />
                              <MDBDataTable
                                data={priceTable}
                                striped
                                bordered
                                searching={false}
                                paging={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Preços",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                },
                stocks: mov_stock ? (edit ? {
                  label: "Stocks",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Stocks{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                          </div>
                          {loaded && (
                            <div>
                              <MDBDataTable
                                data={stockTable}
                                striped
                                bordered
                                searching={false}
                                paging={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Stocks",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                }) : {
                  label: "Stocks",
                  page: (<h1>O produto não movimenta stock.</h1>)
                },
                composition: composed ? (edit ? {
                  label: "Ficha Técnica",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Ficha Técnica{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                            <div onClick={() => eventBus.$emit("Modal-composition", { header: "Adicionar produto", body: null })}>
                              <MdAddCircleOutline
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div>
                            <MDBDataTable
                              data={compositionTable}
                              striped
                              bordered
                              searching={false}
                              paging={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Ficha Técnica",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                }) : {
                  label: "Ficha Técnica",
                  page: (<h1>O produto não é composto.</h1>)
                },
                in: mov_stock ? (edit ? {
                  label: "Mov. Entradas",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Mov. Entradas{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                          </div>
                          {loaded && (
                            <div>
                              <VerticalTabs
                                tabs={[
                                  {
                                    label: "Todos Postos",
                                    component: (
                                      <div>
                                        <ReactTable
                                          warehouse="all"
                                          columns={movEntradas.columns.filter(({accessor}) => accessor !== 'stock')}
                                          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                          sortable={false}
                                          data={movEntradas.data}
                                          pages={movEntradas.pages} // Display the total number of pages
                                          loading={movEntradas.loading} // Display the loading overlay when we need it
                                          onFetchData={
                                            this.fetchMovEntradasData
                                          } // Request new data when things change
                                        />
                                      </div>
                                    ),
                                  },
                                  ...warehouses.map((warehouse) => ({
                                    label: warehouse.nome || warehouse.posto,
                                    component: (
                                      <div>
                                        <ReactTable
                                          warehouse={warehouse.posto}
                                          columns={movEntradas.columns}
                                          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                          sortable={false}
                                          data={movEntradas.data}
                                          pages={movEntradas.pages} // Display the total number of pages
                                          loading={movEntradas.loading} // Display the loading overlay when we need it
                                          onFetchData={
                                            this.fetchMovEntradasData
                                          } // Request new data when things change
                                        />
                                      </div>
                                    ),
                                  })),
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Mov. Entradas",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                }) : {
                  label: "Mov. Entradas",
                  page: (<h1>O produto não movimenta stock.</h1>)
                },
                out: mov_stock ? (edit ? {
                  label: "Mov. Saidas",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Mov. Saidas{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                          </div>
                          {loaded && (
                            <div>
                              <VerticalTabs
                                tabs={[
                                  {
                                    label: "Todos Postos",
                                    component: (
                                      <div>
                                        <ReactTable
                                          warehouse="all"
                                          columns={movSaidas.columns.filter(({accessor}) => accessor !== 'stock')}
                                          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                          sortable={false}
                                          data={movSaidas.data}
                                          pages={movSaidas.pages} // Display the total number of pages
                                          loading={movSaidas.loading} // Display the loading overlay when we need it
                                          onFetchData={this.fetchMovSaidasData} // Request new data when things change
                                        />
                                      </div>
                                    ),
                                  },
                                  ...warehouses.map((warehouse) => ({
                                    label: warehouse.nome || warehouse.posto,
                                    component: (
                                      <div>
                                        <ReactTable
                                          warehouse={warehouse.posto}
                                          columns={movSaidas.columns}
                                          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                          sortable={false}
                                          data={movSaidas.data}
                                          pages={movSaidas.pages} // Display the total number of pages
                                          loading={movSaidas.loading} // Display the loading overlay when we need it
                                          onFetchData={this.fetchMovSaidasData} // Request new data when things change
                                        />
                                      </div>
                                    ),
                                  })),
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Mov. Saidas",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                }) : {
                  label: "Mov. Saidas",
                  page: (<h1>O produto não movimenta stock.</h1>)
                },
                inout: mov_stock ? (edit ? {
                  label: "Mov. E/S",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Mov. Entradas e Saídas{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                          </div>
                          {loaded && (
                            <div>
                              <VerticalTabs
                                tabs={[
                                  {
                                    label: "Todos Postos",
                                    component: (
                                      <div>
                                        <ReactTable
                                          warehouse="all"
                                          columns={movEntradasSaidas.columns.filter(({accessor}) => accessor !== 'stock')}
                                          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                          sortable={false}
                                          data={movEntradasSaidas.data}
                                          pages={movEntradasSaidas.pages} // Display the total number of pages
                                          loading={movEntradasSaidas.loading} // Display the loading overlay when we need it
                                          onFetchData={
                                            this.fetchMovEntradasSaidasData
                                          } // Request new data when things change
                                        />
                                      </div>
                                    ),
                                  },
                                  ...warehouses.map((warehouse) => ({
                                    label: warehouse.nome || warehouse.posto,
                                    component: (
                                      <div>
                                        <ReactTable
                                          warehouse={warehouse.posto}
                                          columns={movEntradasSaidas.columns}
                                          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                          sortable={false}
                                          data={movEntradasSaidas.data}
                                          pages={movEntradasSaidas.pages} // Display the total number of pages
                                          loading={movEntradasSaidas.loading} // Display the loading overlay when we need it
                                          onFetchData={
                                            this.fetchMovEntradasSaidasData
                                          } // Request new data when things change
                                        />
                                      </div>
                                    ),
                                  })),
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Mov. Entradas",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                }) : {
                  label: "Mov. Entradas",
                  page: (<h1>O produto não movimenta stock.</h1>)
                },
                barcodes: edit ? {
                  label: "Cod. Barras",
                  page: (
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Cod. Barras{" "}
                              <h6>
                                {edit ? (
                                  <span>{name}</span>
                                ) : (
                                  <span>Novo Produto</span>
                                )}
                              </h6>
                            </div>
                            <div onClick={() => eventBus.$emit("Modal-barcodes", { header: "Novo código de barras", body: null })}>
                              <MdAddCircleOutline
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          {loaded && (
                            <div>
                              {barcodes.map(({barcode}) => (
                                <div>
                                  <Barcode value={barcode} /><i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.handleBarcodeDeleteSuccess(barcode) } className="fas fa-trash"></i>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                } : {
                  label: "Cod. Barras",
                  page: (<h1>Apenas disponivel após criação do produto</h1>)
                },
              }}
            />
          )}
          <Button
            action={this.handleFormSubmit}
            type={"success"}
            title={"Submeter"}
            style={buttonStyle}
          />
          <Button
            action={this.handleFormCancel}
            type={"secondary"}
            title={"cancelar"}
            style={buttonStyle}
          />
        </div>
        <Modal id="barcodes">
          <div>
            <Input
                inputType={"text"}
                name={"newbarcode"}
                value={newbarcode}
                handleChange={this.handleChange}
            />
            <Button
                action={this.handleBarcodeSubmitSuccess}
                type={"success"}
                title={"Submeter"}
                style={buttonStyle}
            />
          </div>
        </Modal>
        <Modal id="composition">
          <div>
            <AutoComplete
                name={`selectedProduct`}
                value={selectedProduct}
                handleChange={this.handleChange}
                options={products.filter(({inativo}) => inativo !== 't')}
            />
            <br/>
            <input
              type="number"
              key={'selectedquantity'}
              name={'selectedquantity'}
              value={ selectedquantity }
              onChange={this.handleChange}
            />
            <br/>
            <Button
                action={this.handleCompositionSubmitSuccess}
                type={"success"}
                title={"Submeter"}
                style={buttonStyle}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default withCookies(Form);
