import React from 'react';
import { styled } from '@mui/material/styles';

const StyledInputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const StyledLabel = styled('label')(({ theme, error, shrink }) => ({
  position: 'absolute',
  left: '8px',
  top: shrink ? '-6px' : '50%',
  transform: shrink ? 'translateY(0)' : 'translateY(-50%)',
  backgroundColor: 'white',
  padding: '0 4px',
  fontSize: shrink ? '0.75rem' : '0.875rem',
  color: error ? 'var(--color-danger)' : 'var(--color-gray-500)',
  transition: 'all 0.2s ease',
  pointerEvents: 'none',
}));

const StyledInput = styled('input')(({ theme, error }) => ({
  width: '100%',
  padding: '11.5px 14px',
  fontSize: '0.875rem',
  border: `1px solid ${error ? 'var(--color-danger)' : 'var(--color-gray-300)'}`,
  borderRadius: '4px',
  backgroundColor: 'transparent',
  '&:focus': {
    outline: 'none',
    borderColor: error ? 'var(--color-danger)' : 'var(--color-primary)',
    '& + label': {
      color: error ? 'var(--color-danger)' : 'var(--color-primary)',
    },
  },
  '&:hover': {
    borderColor: error ? 'var(--color-danger)' : 'var(--color-gray-500)',
  },
  '&[readonly]': {
    backgroundColor: 'var(--color-gray-50)',
    borderColor: 'var(--color-gray-300)',
    color: 'var(--color-gray-500)',
  },
}));

const TextField = ({
  label,
  value,
  onChange,
  type = 'text',
  error,
  readOnly,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);
  const shrink = focused || value !== '' || type === 'number';

  return (
    <StyledInputContainer>
      <StyledInput
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        error={error}
        readOnly={readOnly}
        {...props}
      />
      <StyledLabel shrink={shrink} error={error}>
        {label}
      </StyledLabel>
    </StyledInputContainer>
  );
};

export default TextField;