import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './submenu.css';

// Create a global state to track active submenu
let activeSubmenuId = null;
let subscribers = new Set();

const notifySubscribers = (id) => {
  subscribers.forEach(callback => callback(id));
};

const Submenu = ({ title, icon, submenus, width, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const submenuRef = useRef(null);
  const id = useRef(`submenu-${Math.random()}`).current;

  useEffect(() => {
    // Subscribe to submenu state changes
    const handleSubmenuChange = (activeId) => {
      if (activeId !== id) {
        setIsOpen(false);
      }
    };

    subscribers.add(handleSubmenuChange);

    return () => {
      subscribers.delete(handleSubmenuChange);
    };
  }, [id]);

  const handleToggle = (e) => {
    e.preventDefault();
    
    // If this submenu is already open, close it
    if (isOpen) {
      setIsOpen(false);
      activeSubmenuId = null;
      notifySubscribers(null);
      return;
    }
    
    // Otherwise, open this submenu and close others
    setIsOpen(true);
    activeSubmenuId = id;
    notifySubscribers(id);
  };

  const handleItemClick = () => {
    setIsOpen(false);
    activeSubmenuId = null;
    notifySubscribers(null);
    if (onClose) onClose();
  };

  const renderSubmenuItems = () => {
    return submenus.map((submenu, index) => {
      if (!submenu.condition) return null;

      if (submenu.disabled) {
        return (
          <div key={index} className="submenu-section">
            <div className="submenu-header">{submenu.title}</div>
          </div>
        );
      }

      const isExternal = submenu.url.startsWith('http');
      const LinkComponent = isExternal ? 'a' : Link;
      const linkProps = isExternal ? { href: submenu.url } : { to: submenu.url };

      return (
        <LinkComponent
          key={index}
          {...linkProps}
          className="submenu-item"
          onClick={handleItemClick}
        >
          {submenu.icon && (
            <i className={`fas fa-${submenu.icon} submenu-icon`}></i>
          )}
          <span>{submenu.title}</span>
        </LinkComponent>
      );
    });
  };

  return (
    <>
      {/* Desktop Submenu */}
      <div className="submenu-container" ref={submenuRef}>
        <button
          type="button"
          className={classNames('submenu-trigger', { 'is-open': isOpen })}
          onClick={handleToggle}
        >
          {icon && <i className={`fas fa-${icon} submenu-icon`}></i>}
          <span>{title}</span>
          <i className={`fas fa-chevron-down submenu-arrow ${isOpen ? 'is-open' : ''}`}></i>
        </button>

        {isOpen && (
          <div className="submenu-dropdown">
            {renderSubmenuItems()}
          </div>
        )}
      </div>

      {/* Mobile Submenu */}
      <div className="submenu-mobile">
        <button
          type="button"
          className={classNames('submenu-trigger', { 'is-open': isOpen })}
          onClick={handleToggle}
        >
          {icon && <i className={`fas fa-${icon} submenu-icon`}></i>}
          <span>{title}</span>
          <i className={`fas fa-chevron-down submenu-arrow ${isOpen ? 'is-open' : ''}`}></i>
        </button>

        <div className={classNames('submenu-mobile-content', { 'is-open': isOpen })}>
          <div>
            {renderSubmenuItems()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Submenu;