import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import axios from 'axios';

import 'react-confirm-alert/src/react-confirm-alert.css';

// Components
import Menu from '@components/Menu'
import ReactTable from '@components/ReactTable';

class Page extends Component {
    constructor(props) {
        super(props);
        
        const { match: { params } } = props;

        this.state = {
            products: [],
            empresas: [],
            warehouses: [],
            tanks: [],
            idempresa: '',
            servers: [],
            idposto: '',
            idpsa: params.idpsa,
            macaddress: '',
            loaded: false,
            adminProfile: false,
            table: {
                columns: [
                    {
                        Header: 'Data',
                        accessor: 'at',
                        show: this.props.profile.adminProfile,
                    },
                    {
                        Header: 'Mensagem',
                        accessor: 'message',
                        show: this.props.profile.adminProfile,
                    },
                ],
                rows: [],
                loading: true,
            },
        };
        this.loadFunction = this.loadFunction.bind(this);
        this.getRaspberry = this.getRaspberry.bind(this);
    }
  
    getRaspberry({ idEmpresa, adminProfile }) {
        const { idpsa } = this.state;

        return axios.get(`https://eposgestofinal.pt/api/psa/logs.php?idpsa=${idpsa}`)
            .then(response => {
                const logs = response.data !== 'NOK' ? response.data : [];

                const enrichedLogs = logs.map((log) => {
                    const colored = log.message.includes('###');
                    let color = 'black'
                    if (colored) {
                        const matches = log.message.match(/###(.*?)###/);
                        if (matches) {
                            color = matches[1];
                            log.message = log.message.replace(new RegExp(`###${color}###`, 'g'), '');
                        }
                    }

                    return {
                        ...log,
                        at: log.at.split('.')[0],
                        message: (
                            <span style={{ color }}>
                                {log.message}
                            </span>
                        ),
                    };
                });

                this.setState({ adminProfile, table: { ...this.state.table , rows: enrichedLogs, loading: false }, loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
    }

    loadFunction(profile) {
        if (!this.state.loaded) {
            axios.get('https://eposgestofinal.pt/api/empresas/get.php?combustivel=true')
            .then(response => {
                const empresas = response.data !== 'NOK' ? response.data : [];

                this.setState({ empresas: empresas.map((empresa) => ({ ...empresa, value: empresa.idempresa, label: empresa.nomeempresa })).filter((empresa) => empresa.combustivel), loaded: true });

                axios.get(`https://eposgestofinal.pt/api/psa/getById.php?idpsa=${this.state.idpsa}`)
                    .then(response => {
                        const raspberry = response.data !== 'NOK' ? response.data : {};

                        let currentEmpresa = '';
                        if (raspberry.idempresa) {
                            //https://eposgestofinal.pt/api/stations/servers/get.php?idempresa=${idEmpresa}&idstation=${idstation}
                            currentEmpresa = this.state.empresas.find((empresa) => raspberry.idempresa === empresa.idempresa)
                        }

                        this.setState({ ...raspberry, idempresa: currentEmpresa, loaded: true });

                        this.getRaspberry(profile)
                            .then(() => {
                                window.autoRefresh = setInterval(() => {
                                    this.getRaspberry(profile);
                                }, 5000);
                            })
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
            });
            
        }
    }
    
    render () {
        const { table, idempresa, posto_nome } = this.state;
        const { location, history } = this.props;

        return (
            <div>
                <Menu loadFunction={ this.loadFunction } location={ location } history={ history } />
                <div className="fullContainer" style={{ maxWidth: '100%' }}>
                    <div className="col-xs-12">
                        <ol
                            style={{
                                textAlign: 'left',
                                'marginTop': '75px',
                            }}
                            className="breadcrumb"
                        >
                            <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                            <li><a href={`/psa`}>Postos Combustivel</a></li>
                            <li>{ idempresa['nomeempresa'] }</li>
                            <li>{ posto_nome }</li>
                            <li className="active">
                                <span>Logs</span>
                            </li>
                        </ol>
                    </div>
                </div>
                <div
                    className="fullContainer" style={{ maxWidth: '100%' }}
                >
                    <div
                        className="col-sm-12"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '15pt',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                    }}
                                >
                                    Logs
                                </div>
                            </div>
                            <ReactTable
                                columns={ table.columns }
                                sortable={ true }
                                loading={ table.loading }
                                data={ table.rows }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Page);