import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import clientesService from "@services/clientes";

import { MdVisibility } from "react-icons/md";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import ReactTable from "@components/ReactTable";

import Print from './print'

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import DateRangePicker from "@components/DateRangePicker";

import { TimestampToDate, dateToTimestamp } from "@utils/date";
import { threeDecimal } from "@utils/format";

import UserContext from "@components/context/userContext";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      table: {
        columns: [
            {
                Header: "Cliente",
                accessor: "cliente",
            },
            {
                Header: "Talão",
                id: "talao",
                accessor: (d) => `${d.posto}${d.tpv}${d.serie}/${d.talao}`,
              },
              {
                Header: "Data",
                accessor: "data",
              },
              {
                Header: "C.Produto",
                accessor: "produto",
              },
              {
                Header: "Produto",
                accessor: "designacao",
              },
              {
                Header: "Qtd",
                accessor: "qnt",
              },
              {
                Header: "Valor",
                accessor: "total",
              },
              {
                Header: "Matricula",
                accessor: "matricula",
              },
              {
                Header: "Km Ant.",
                accessor: "kilometros",
              },
              {
                Header: "Km Atuais",
                accessor: "kilometros_actuais",
              },
              {
                Header: "Média",
                id: "media",
                accessor: (d) => d.kilometros_actuais > 0 ? (d.kilometros_actuais - d.kilometros)/d.qnt : '',
              },
        ],
        rows: [],
        loading: false,
      },
      posto: {
        value: "",
        label: "TODOS",
      },
      cliente: {
        value: "",
        label: "TODOS",
      },
      pagamento: {
        value: "",
        label: "TODOS",
      },
      pagamentos: [{
            value: "",
            label: "TODOS",
        },
        {
            value: "1",
            label: "Dinheiro"
        },
        {
            value: "2",
            label: "Crédito"
        },
        {
            value: "3",
            label: "Cheque"
        },
        {
            value: "4",
            label: "TPA"
        },
        {
            value: "8",
            label: "Frota"
        },
        {
            value: "14",
            label: "Serviços Internos"
        },
        {
            value: "101",
            label: "Dinheiro"
        },
        {
            value: "102",
            label: "TPA"
        },
        {
            value: "103",
            label: "Cheque"
        },
        {
            value: "104",
            label: "Transferência"
        },
        {
            value: "105",
            label: "Letra"
    }],
      date: [(new Date()).setHours(0 , 0 , 0) , (new Date()).setHours (23 , 59 , 59)],
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.localDateChange = this.localDateChange.bind(this);
  }

  async loadFunction() {
    if (!this.state.loaded) {
        const profile = this.context;

        const { history } = this.props;
        const { date } = this.state;

        axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresaSec}`)
        .then(async response => {
            const postos = response.data !== 'NOK' ? response.data : [];

            const enrichedPostos = postos.map((posto) => ({ value: posto.posto, label: posto.nome }));

            this.setState({ postos: [{ value: '', label: 'TODOS' },...enrichedPostos] });
            
            const clientes = await clientesService.get(profile.idEmpresaSec);

            const enrichedClientes = clientes.map((cliente) => ({ value: cliente.codigo, label: cliente.nome_1 }));

            this.setState({ clientes: [{ value: '', label: 'TODOS' },...enrichedClientes], loaded: true });
        })
        .catch(error => {
            console.error(error);
        });
    
    }
  }

  getConsumption() {
    const profile = this.context;

    const { date } = this.state;

    axios
    .get(
        `https://eposgestofinal.pt/api/clientes/consumption/getById.php?idempresa=${profile.idEmpresaSec}&cliente=${this.state.cliente.value}&posto=${this.state.posto.value}&startdate=${(date[0]/1000).toString().split('.')[0]}&enddate=${(date[1]/1000).toString().split('.')[0]}${this.state.pagamento.value != '' ? '&pagamento=' + this.state.pagamento.value : ''}`
    )
    .then((response) => {
        const consumption = response.data !== "NOK" ? response.data.lines : [];
        
        this.setState({
            table: {
              ...this.state.table,
              rows: consumption,
              loading: false,
            },
            loaded: true,
        });
    })
    .catch((error) => {
        console.error(error);
    });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    }, () => {
        switch (name) {
            case "posto":
            case "cliente":
            case "pagamento":
                this.setState({
                    table: {
                        ...this.state.table,
                        loading: true,
                    }
                    })
                this.getConsumption()
                break;
            default:
                break;
            }
    });
  }

  localDateChange(date) {
    this.setState({ date: [
        date[0].setHours(0 , 0 , 0),
        date[1].setHours(23 , 59 , 59),
      ]
    }, () => {
            this.setState({
              table: {
                ...this.state.table,
                loading: true,
              }
            })
            this.getConsumption()
    });
  }

  render() {
    const { table, posto, postos, cliente, clientes, date, pagamento, pagamentos } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Consumos</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default" style={{ minHeight: '700px' }}>
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Consumos
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Data</th>
                    <th className="text-center">Cliente</th>
                    <th className="text-center">Posto</th>
                    <th className="text-center">Pagamento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>
                      <DateRangePicker maxDate={new Date()} value={date} onChange={this.localDateChange} />
                    </td>
                    <td>
                      <AutoComplete
                        name={"cliente"}
                        value={cliente}
                        handleChange={this.handleChange}
                        options={clientes}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"posto"}
                        value={posto}
                        handleChange={this.handleChange}
                        options={postos}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                        <AutoComplete
                        name={"pagamento"}
                        value={pagamento}
                        handleChange={this.handleChange}
                        options={pagamentos}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                  <tbody>
                      <tr>
                          <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                            <Print
                                table={ table }
                                date={ date }
                                posto={ posto }
                                cliente={ cliente }
                                pagamento={ pagamento }
                            />
                          </td>
                      </tr>
                  </tbody>
              </table>
              <table style={{ marginBottom: 0 }} className="table">
                  <tbody>
                      <tr>
                          <td  style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                          Total
                          <h4>{table.rows.length > 0 ? threeDecimal(table.rows.reduce((acc, row) => acc + parseFloat(row.qnt), 0)) : 0}</h4>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={table.rows}
                excelDownload={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
