import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import AutoComplete from "@components/AutoComplete";
import Button from "@components/Button";

import UserContext from '@components/context/userContext';

class Form extends Component {
    static contextType = UserContext;
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params }, type } = this.props;

    this.state = {
        loaded: false,
        postos: {},
        name: '',
        idproduct: type === 'edit' && params.idproduct,
        edit: type === 'edit',
        products: [],
    };
    this.handlePostoChange = this.handlePostoChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
        if (this.props.type === 'edit') {
            axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`)
            .then(response => {
                const postos = response.data !== 'NOK' ? response.data : [];

                const postosValues = {};
                postos.forEach((posto) => {
                    postosValues[posto.posto] = { name: posto.nome };
                })

                this.setState({ loaded: true });

                axios.get(`https://eposgestofinal.pt/api/estimates/get.php?idempresa=${profile.idEmpresa}&produto=${this.state.idproduct}`)
                .then(response => {
                    const product = response.data !== 'NOK' ? response.data : [];
                    product.forEach((productItem) => {
                        postosValues[productItem.posto].value = productItem.qtd;
                    })
                
                    this.setState({ name: `[${product[0].produto}] ${product[0].nome}`, postos: postosValues, loaded: true });
                })
                .catch(error => {
                    console.error(error);
                });
            })
            .catch(error => {
                console.error(error);
            });
        } else {
            axios.get(`https://eposgestofinal.pt/api/produtos/get.php?idempresa=${profile.idEmpresa}&descricaocategoria=COMBUST`)
            .then(response => {
                const products = response.data !== 'NOK' ? response.data : [];

                this.setState({ products: products.map((product) => ({ value: product.produto, label: `[${product.produto}] ${product.nome}` })), loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
            axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`)
            .then(response => {
                const postos = response.data !== 'NOK' ? response.data : [];

                const postosValues = {};
                postos.forEach((posto) => {
                    postosValues[posto.posto] = { value: 0, name: posto.nome };
                })

                this.setState({ postos: postosValues, loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
  }

  handlePostoChange(value, index) {
    const { postos } = this.state;

    postos[index] = {
        ...postos[index],
        value,
    }

    this.setState({
        postos,
    });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    });
    
    if (callback) {
        callback();
    }
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    e.preventDefault();
    let qtds = this.state.postos;
    Object.keys(this.state.postos).map((posto) => qtds[posto] = qtds[posto].value)

    axios.post('https://eposgestofinal.pt/api/estimates/set.php', {
        qtds,
        idempresa: profile.idEmpresa,
        produto: this.state.idproduct.value || this.state.idproduct,
    })
    .then(response => {
        history.push('/estimates');
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push('/estimates');
  }

  render() {
    const { location, history } = this.props;
    const {
        edit,
        loaded,
        idproduct,
        name,
        products,
        postos,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/estimates">Sondagens</a></li>
                        <li className="active">
                            {edit ? (<span>{name}</span>) : (<span>Nova Sondagem</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && (
            <div
                className="fullContainer" style={{ maxWidth: '100%' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >
                                Detalhes
                            </div>
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                                width: '100%',
                            }}
                            className="container-fluid"
                            onSubmit={this.handleFormSubmit}
                        >
                            {name ? (<h4>{name}</h4>) :
                            (<AutoComplete
                                title={"Produto"}
                                name={"idproduct"}
                                value={idproduct}
                                handleChange={this.handleChange}
                                options={products}
                            />)}
                            <div className="form-group">
                                <label className="form-label">
                                Quantidade actual
                                </label>
                                <table className="table table-bordered table-hover table-sortable">
                                    <thead>
                                        <tr >
                                            <th className="text-center">Posto</th>
                                            <th className="text-center">Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(postos).length > 0 && Object.keys(postos).map((posto) => postos[posto].value ? (
                                            <tr key={posto}>
                                                <td className="subLabel">{postos[posto].name || posto}</td>
                                                <td>
                                                    <input
                                                        inputType={"number"}
                                                        name={"sondagem"}
                                                        value={postos[posto].value}
                                                        onChange={(e) => this.handlePostoChange(e.target.value, posto)}
                                                    />
                                                </td>
                                            </tr>
                                        ): null)}
                                    </tbody>
                                </table>
                            </div>
                            <Button
                            action={this.handleFormSubmit}
                            type={"success"}
                            title={"Submeter"}
                            style={buttonStyle}
                            />
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                            />
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
