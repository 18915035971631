import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import Button from "@components/Button";
import Input from "@components/Input";
import Checkbox from "@components/CheckBox";
import VerticalTabs from "@components/VerticalTabs/.";

import { threeDecimal } from "@utils/format.js";
import { currentDateTime, transformDateTime } from "@utils/date";

import UserContext from "@components/context/userContext";

const defaultDate = currentDateTime();

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      warehouses: [],
      date: defaultDate,
      values: {
        columns: [
          {
            label: "Data",
            field: "date",
            width: 200,
          },
          {
            label: "Produto",
            field: "product",
            width: 300,
          },
          {
            label: "Price",
            field: "price",
            width: 200,
          },
        ],
        rows: [],
      },
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const profile = this.context;

      axios
        .get(
          `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresaSec}`
        )
        .then((response) => {
          const warehouses =
            response.data !== "NOK"
              ? response.data.reduce(
                  (accum, warehouse) => ({
                    ...accum,
                    [warehouse.posto]: warehouse,
                  }),
                  {}
                )
              : {};

          axios
            .get(
              `https://eposgestofinal.pt/api/apollo/sync/get.php?idempresa=${profile.idEmpresaSec}`
            )
            .then((response) => {
              const warehousesSync =
                response.data !== "NOK" ? response.data.warehouses : {};

              if (warehousesSync && Object.keys(warehousesSync).length > 0) {
                axios
                .get(
                  `https://eposgestofinal.pt/api/products/posto/get.php?idempresa=${profile.idEmpresaSec}&combustivel=true&where=pv.sync='TRUE'`
                )
                .then((response) => {
                  const postosWarehouses =
                    response.data !== "NOK" ? response.data : {};

                  this.setState({
                    warehouses: Object.keys(warehouses).map((warehouse) => ({
                      date: defaultDate,
                      sameDate: true,
                      ...(warehousesSync[warehouse]
                        ? warehousesSync[warehouse]
                        : warehouses[warehouse]),
                      idposto: warehouse,
                      total: postosWarehouses[warehouse].total,
                      products: _.orderBy(
                        postosWarehouses[warehouse].items.map((product) => ({
                          ...product,
                          new: Object.keys(
                            warehousesSync[warehouse]
                              ? warehousesSync[warehouse].products
                              : {}
                          ).includes(product.idproduct),
                        })),
                        ["new"],
                        ["desc"]
                      ),
                    })),
                    loaded: true,
                  });
                });
              }
            });
        });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      if (properties[4]) {
        if (properties[4] === "sameDate") {
          newState[properties[0]][properties[1]][properties[2]][properties[3]][
            properties[4]
          ] =
            !newState[properties[0]][properties[1]][properties[2]][
              properties[3]
            ][properties[4]];
        } else {
          newState[properties[0]][properties[1]][properties[2]][properties[3]][
            properties[4]
          ] = value;
        }
      } else if (properties[2]) {
        if (properties[2] === "sameDate") {
          newState[properties[0]][properties[1]][properties[2]] =
            !newState[properties[0]][properties[1]][properties[2]];
        } else {
          newState[properties[0]][properties[1]][properties[2]] = value;
        }
      }

      this.setState({
        ...newState,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push("/products");
  }

  handleFormSubmit() {
    confirmAlert({
      title: "Confirmação",
      message: (
        <>
          <p>Todos os preços do Apollo serão substituidos após confirmação.</p>
          <p>
            <b>Tem a certeza que deseja substituir os preços no Apollo?</b>
          </p>
        </>
      ),
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            const profile = this.context;
            const { warehouses, date } = this.state;
            const dateTime = transformDateTime(date);

            axios
              .post("https://eposgestofinal.pt/api/apollo/sync/perform.php", {
                idempresa: profile.idEmpresaSec,
                warehouses: warehouses.reduce((accum, warehouse) => {
                  return {
                    ...accum,
                    [warehouse.posto]: warehouse.sameDate
                      ? dateTime
                      : transformDateTime(warehouse["date"]),
                  };
                }, {}),
              })
              .then((response) => {
                window.location.href = "/products";
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    const { date, warehouses } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/products">Produtos</a>
              </li>
              <li className="active">Sincronizar com Apollo</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '15px'
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Sincronizar com Apollo
                </div>
              </div>
              {warehouses.length > 0 ? (
                <>
                  <table className="table table-bordered table-hover table-sortable">
                    <thead>
                      <tr>
                        <th className="text-center">Data para sincronização</th>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <Input
                            inputType={"datetime-local"}
                            name={"date"}
                            value={date}
                            handleChange={this.handleChange}
                            className=""
                            style={{ width: "200px" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                          />
                          <Button
                            action={this.handleFormSubmit}
                            type={"success"}
                            title={"Sincronizar"}
                          />
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div>
                    <VerticalTabs
                      tabs={[
                        ...warehouses.map((warehouse, index) => ({
                          label: `[${warehouse.posto}] ${warehouse.nome}`,
                          component: (
                            <div style={{ textAlign: "left" }}>
                              <Checkbox
                                key={`warehouses|${index}|sameDate`}
                                name={`warehouses|${index}|sameDate`}
                                title={"Mesma data"}
                                checked={warehouse.sameDate}
                                handleChange={this.handleChange}
                              />
                              {!warehouse.sameDate ? (
                                <Input
                                  className=""
                                  inputType={"datetime-local"}
                                  key={`warehouses|${index}|date`}
                                  name={`warehouses|${index}|date`}
                                  value={warehouse.date}
                                  handleChange={this.handleChange}
                                  style={{ width: "210px" }}
                                />
                              ) : null}
                              <table
                                className="table table-bordered table-hover table-sortable"
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                <thead>
                                  <th style={{ width: "50%" }}>Produto</th>
                                  <th style={{ width: "10%" }}>Preço</th>
                                </thead>
                                <tbody>
                                  {warehouse.products.map((product) => (
                                    <tr
                                      onClick={() =>
                                        (window.location.href =
                                          "/products/" +
                                          product.idproduct +
                                          "/prices/sync")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: product.new
                                          ? "yellow"
                                          : "white",
                                      }}
                                    >
                                      <td>
                                        <span style={{ textDecoration: 'underline' }}>{`[${product.idproduct}] ${product.name}`}</span>
                                      </td>
                                      <td>{threeDecimal(product.price)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ),
                        })),
                      ]}
                    />
                  </div>
                </>
              ) : (<h4 style={{ padding: "50px" }}>De momento não existem dados para sincronizar!</h4>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
