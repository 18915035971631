import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Submenu from './submenu';
import UserContext from '../context/userContext';
import { EventBus as eventBus } from "../EventBus";
import './Menu.css';

const Menu = ({ loadFunction, ePOSOptions = { menu: true } }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const context = React.useContext(UserContext);

  useEffect(() => {
    eventBus.$emit("footer", { company: { name: "Walk In DEV", url: "https://www.walkindev.com/" } });

    // Add/remove body class when menu is open
    if (isMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if(loadFunction){
        loadFunction();
    }
  }, [loadFunction])

  const exitDemo = () => {
    if (window.location.hostname !== "localhost") {
      window.location.href = `https://react.eposgestofinal.pt${window.location.pathname}`;
    } else {
      window.location.reload();
    }
  };

  if (!ePOSOptions.menu) {
    return null;
  }

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const submenucontabilidade = [
    {
      condition: context['memberType'] > 0,
      title: 'Estatísticas',
      url: 'https://eposgestofinal.pt/index.php?page=statistics',
      icon: 'chart-line',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Consumos por matrícula',
      url: '/consumption/vehicles',
      icon: 'car',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Declaração de Caixa',
      url: 'https://eposgestofinal.pt/index.php?page=turno',
      icon: 'cash-register',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Existências em stock',
      url: '/stocks',
      icon: 'warehouse',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Sondagens',
      url: '/estimates',
      icon: 'chart-bar',
    },
    {
      condition: context['memberType'] > 0,
      title: 'XML ctb',
      url: 'https://eposgestofinal.pt/index.php?page=saft',
      icon: 'file-code',
    },
  ];

  const submenuToolsLojista = [
    {
      condition: context['memberType'] > 0,
      title: 'Agenda',
      url: '/agenda',
      icon: 'calendar-alt',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Calendário',
      url: '/calendar',
      icon: 'calendar-day',
    },
  ];

  const submenuTools = [
    {
      condition: context['memberType'] > 0,
      title: 'Gestão',
      disabled: true,
    },
    {
      condition: context['memberType'] > 0,
      title: context.permissions?.includes("site.newclientes") ? 'Clientes (beta)' : 'Clientes',
      url: context.permissions?.includes("site.newclientes") ? '/clientes' : '/customers',
      icon: 'users',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Fornecedores',
      url: '/providers',
      icon: 'truck',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Parceiros',
      url: '/partners',
      icon: 'handshake',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Produtos',
      url: '/products',
      icon: 'box',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Utilitários',
      disabled: true,
    },
    {
      condition: context['memberType'] > 0,
      title: 'Agenda',
      url: '/agenda',
      icon: 'calendar-alt',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Calendário',
      url: '/calendar',
      icon: 'calendar-day',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Downloads',
      url: 'https://eposgestofinal.pt/index.php?page=downloads',
      icon: 'download',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Serviços',
      url: '/customers',
      icon: 'cogs',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Torniquete',
      url: '/tourniquet',
      icon: 'door-open',
    },
  ];

  const submenuDocuments = [
    {
      condition: context['memberType'] > 0,
      title: 'Compras',
      url: '/orders',
      icon: 'shopping-cart',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Guias de Transporte',
      url: '/guias',
      icon: 'file-alt',
    },
    {
      condition: context['memberType'] > 0,
      title: [1, 82, 107, 207].includes(context['idEmpresa']) ? 'Faturação' : 'Faturação (Brevemente)',
      url: [1, 82, 107, 207].includes(context['idEmpresa']) ? '/sales' : '#',
      icon: 'file-invoice-dollar',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Senhas',
      url: '/tickets',
      icon: 'ticket-alt',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Documentos Pendentes',
      url: 'https://eposgestofinal.pt/index.php?page=cc',
      icon: 'clock',
    },
  ];

  const adminTools = [
    {
      condition: context['memberType'] > 9,
      title: 'Utilizadores',
      url: 'https://eposgestofinal.pt/index.php?page=users_management',
      icon: 'users-cog',
    },
    {
      condition: context['memberType'] > 9,
      title: 'FTP',
      url: 'https://eposgestofinal.pt/index.php?page=ftp_admin',
      icon: 'server',
    },
    {
      condition: context['memberType'] > 9,
      title: 'POS',
      url: 'https://eposgestofinal.pt/index.php?page=pos_admin',
      icon: 'cash-register',
    },
    {
      condition: context['memberType'] > 9,
      title: 'Site',
      url: 'https://eposgestofinal.pt/index.php?page=site_admin',
      icon: 'globe',
    },
  ];

  const configTools = [
    {
      condition: context['memberType'] > 0,
      title: 'Etiquetas',
      url: '/pricetags',
      icon: 'tags',
    },
    {
      condition: context['memberType'] > 0 && context['combustivel'],
      title: 'Impostos',
      url: '/taxes',
      icon: 'percentage',
    },
    {
      condition: context['memberType'] > 0 && context['combustivel'],
      title: 'Preços Combustiveis',
      url: '/prices/combustivel',
      icon: 'gas-pump',
    },
    {
      condition: context['memberType'] === 10,
      title: 'Pump Server Apollo',
      url: '/psa',
      icon: 'server',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Séries',
      url: '/series',
      icon: 'list-ol',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Veeder-Root',
      url: '/veeder-root',
      icon: 'tachometer-alt',
    },
    {
      condition: [1, 10].includes(context['memberType']),
      title: 'Gestão de utilizadores',
      url: '/employees',
      icon: 'users-cog',
    },
    {
      condition: context['memberType'] > 0,
      title: 'Gestão de Categorias',
      url: 'https://eposgestofinal.pt/index.php?page=category_management',
      icon: 'list-ul',
    },
  ];

  return (
    <div className="menu-wrapper">
      {context['demo'] === 'true' || window.location.hostname === 'demo-react.eposgestofinal.pt' ? (
        <div className="demo-banner">
          <span>DEMO MODE</span>
          <button type="button" className="demo-exit-button" onClick={exitDemo}>
            Sair
          </button>
        </div>
      ) : null}
      
      <nav className="main-nav">
        <div className="nav-container">
          <div className="nav-brand">
            <button 
              type="button" 
              className={classNames('nav-toggle', { 'is-open': isMenuOpen })}
              onClick={handleMenuToggle}
              aria-label="Toggle navigation"
            >
              <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
            <Link to="/" className="brand-link" onClick={handleLinkClick}>
            <img src="https://eposgestofinal.pt/site/images/epos.png" alt="Logo" style={{ width: '2rem' }} />
              <span>ePOS</span>
            </Link>
          </div>

          <div className={classNames('nav-collapse', { 'show': isMenuOpen })}>
            <ul className="nav-menu">
              {context['memberType'] > 0 && (
                <>
                  {[1,3,4,10].includes(context['memberType']) && (
                    <li className="nav-item">
                      <Link to="/pos" className="nav-link" target="_blank" onClick={handleLinkClick}>
                        <i className="fas fa-cash-register"></i>
                        <span>POS</span>
                      </Link>
                    </li>
                  )}

                  {[4].includes(context['memberType']) && (
                    <Submenu
                      title="Ferramentas"
                      icon="tools"
                      width="110px"
                      submenus={submenuToolsLojista}
                      onClose={handleLinkClick}
                    />
                  )}

                  {[1,2,10].includes(context['memberType']) && (
                    <Submenu
                      title="Contabilidade"
                      icon="calculator"
                      width="110px"
                      submenus={submenucontabilidade}
                      onClose={handleLinkClick}
                    />
                  )}

                  {[1,3,10].includes(context['memberType']) && (
                    <>
                      <Submenu
                        title="Ferramentas"
                        icon="tools"
                        width="110px"
                        submenus={submenuTools}
                        onClose={handleLinkClick}
                      />
                      <Submenu
                        title="Documentos"
                        icon="file-alt"
                        width="110px"
                        submenus={submenuDocuments}
                        onClose={handleLinkClick}
                      />
                      <li className={classNames('nav-item', { 'active': context['page'] === 'assistencias' })}>
                        <a href="https://eposgestofinal.pt/index.php?page=assistencias" className="nav-link" onClick={handleLinkClick}>
                          <i className="fas fa-ticket-alt"></i>
                          <span>Tickets</span>
                        </a>
                      </li>
                    </>
                  )}
                </>
              )}

              {[1, 10].includes(context['memberType']) && (
                <Submenu
                  title="Configuração"
                  icon="cog"
                  width="115px"
                  submenus={configTools}
                  onClose={handleLinkClick}
                />
              )}

              {[10].includes(context['memberType']) && (
                <Submenu
                  title="Administração"
                  icon="user-shield"
                  width="120px"
                  submenus={adminTools}
                  onClose={handleLinkClick}
                />
              )}
            </ul>

            <div className="nav-right">
              <ul className="nav-menu">
                <li className="nav-item">
                  <Link to="/counters" className="nav-link" onClick={handleLinkClick}>
                    <i className="fas fa-tachometer-alt"></i>
                    <span>Contadores</span>
                  </Link>
                </li>

                {context['prizes'] === 't' && [1, 3, 10].includes(context['memberType']) && (
                  <li className={classNames('nav-item', { 'active': context['page'] === 'premios' })}>
                    <a href="https://eposgestofinal.pt/index.php?page=premios" className="nav-link" onClick={handleLinkClick}>
                      <i className="fas fa-award"></i>
                      <span>Prémios</span>
                    </a>
                  </li>
                )}

                <li className="nav-item user-profile">
                  {context['pontos'] > 0 && [2].includes(context['memberType']) && (
                    <div className="points-badge">
                      <span className="points-label">Pontos</span>
                      <span className="points-value" id="pontosempresa">{context['pontos']}</span>
                    </div>
                  )}
                  
                  <div className="profile-menu">
                    <img
                      src={'https://eposgestofinal.pt/' + (context['avatar'] || (context['idemployee'] ? 'site/images/profile.jpg' : 'site/images/suitcase.png'))}
                      alt={context['name']}
                      className="profile-avatar"
                      onClick={() => window.location.href = 'https://eposgestofinal.pt/index.php?page=profile'}
                    />
                    <div className="profile-info">
                      <span className="profile-name">{context['name']}</span>
                      <a href="https://eposgestofinal.pt/index.php?logout=1" className="logout-link" onClick={handleLinkClick}>
                        <i className="fas fa-sign-out-alt"></i>
                        <span>Terminar Sessão</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Menu;